import axios from "axios";
// import { toast } from "react-toastify";

import {
  FETCH_WALLET_TRANSACTIONS_FAILURE,
  FETCH_WALLET_TRANSACTIONS_REQUEST,
  FETCH_WALLET_TRANSACTIONS_SUCCESS,
  WALLET_REQUEST_APPROVE_DECLINE_REQUEST,
  WALLET_REQUEST_APPROVE_DECLINE_SUCCESS,
  WALLET_REQUEST_APPROVE_DECLINE_FAILURE,
  WALLET_OPERATION_FAILURE,
  WALLET_OPERATION_REQUEST,
  WALLET_OPERATION_SUCCESS,
  FETCH_PENDING_USER_REQUESTS_FAILURE,
  FETCH_PENDING_USER_REQUESTS_REQUEST,
  FETCH_PENDING_USER_REQUESTS_SUCCESS,
  FETCH_PENDING_GROUP_WALLET_REQUESTS_FAILURE,
  FETCH_PENDING_GROUP_WALLET_REQUESTS_REQUEST,
  FETCH_PENDING_GROUP_WALLET_REQUESTS_SUCCESS,
  FETCH_WALLET_REQUEST_COMPLETED_FAILURE,
  FETCH_WALLET_REQUEST_COMPLETED_REQUEST,
  FETCH_WALLET_REQUEST_COMPLETED_SUCCESS,
  FETCH_SINGLE_WALLET_REQUEST_FAILURE,
  FETCH_SINGLE_WALLET_REQUEST_REQUEST,
  FETCH_SINGLE_WALLET_REQUEST_SUCCESS,
  CREATE_WALLET_REQUEST_FAILURE,
  CREATE_WALLET_REQUEST_REQUEST,
  CREATE_WALLET_REQUEST_SUCCESS,
} from "../constants/walletRequestConstant";
import { toast } from "react-toastify";

export const fetchWalletTransactions =
  (page = 1, limit = 10) =>
  async (dispatch, getState) => {
    dispatch({ type: FETCH_WALLET_TRANSACTIONS_REQUEST });

    try {
      const { token } = getState();

      const data = await axios.get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_BACKEND_URL}/wallet-log/admin`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: { page, limit },
        }
      );

      dispatch({
        type: FETCH_WALLET_TRANSACTIONS_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";
      // toast.error(errorMessage);
      dispatch({
        type: FETCH_WALLET_TRANSACTIONS_FAILURE,
        payload: errorMessage,
      });
    }
  };

export const approveOrDeclineWalletRequest =
  (walletRequestId, action) => async (dispatch, getState) => {
    console.log(action);
    try {
      dispatch({ type: WALLET_REQUEST_APPROVE_DECLINE_REQUEST });

      const { token } = getState(); // Assuming token is in auth state

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      // Action should be in request body
      const requestBody = {
        action: action, // "approved" or "declined"
      };

      const response = await axios.post(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_BACKEND_URL}/wallet-request/complete/${walletRequestId}`,
        requestBody,
        config
      );

      dispatch({
        type: WALLET_REQUEST_APPROVE_DECLINE_SUCCESS,
        payload: response.data,
      });

      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";

      dispatch({
        type: WALLET_REQUEST_APPROVE_DECLINE_FAILURE,
        payload: errorMessage,
      });

      throw error;
    }
  };

export const performWalletOperation =
  (operationData) => async (dispatch, getState) => {
    try {
      dispatch({ type: WALLET_OPERATION_REQUEST });
      const { token } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_BACKEND_URL}/wallet/admin/operations`,
        operationData,
        config
      );
      toast.success("Successful");

      dispatch({
        type: WALLET_OPERATION_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: WALLET_OPERATION_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const fetchPendingUsersWalletRequests =
  () => async (dispatch, getState) => {
    dispatch({ type: FETCH_PENDING_USER_REQUESTS_REQUEST });

    try {
      const { token } = getState();
      const response = await axios.get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_BACKEND_URL}/wallet-request/pending/user`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        // Pass the entire data structure to the reducer
        dispatch({
          type: FETCH_PENDING_USER_REQUESTS_SUCCESS,
          payload: response.data.data.data,
        });
      }

      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An error occurred while fetching pending user wallet requests";

      dispatch({
        type: FETCH_PENDING_USER_REQUESTS_FAILURE,
        payload: errorMessage,
      });

      toast.error(errorMessage);
      throw error;
    }
  };

export const fetchPendingGroupWalletRequests =
  () => async (dispatch, getState) => {
    dispatch({ type: FETCH_PENDING_GROUP_WALLET_REQUESTS_REQUEST });
    try {
      const { token } = getState();
      const response = await axios.get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_BACKEND_URL}/wallet-request/pending/group`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        dispatch({
          type: FETCH_PENDING_GROUP_WALLET_REQUESTS_SUCCESS,
          payload: response.data.data.data,
        });
      }
    } catch (error) {
      if (Array.isArray(error.response.data.errors)) {
        error?.response?.data.errors.forEach((err) =>
          toast.error(err || "something went wrong")
        );
      }
      dispatch({
        type: FETCH_PENDING_GROUP_WALLET_REQUESTS_FAILURE,
        payload: error.message || "Something went wrong",
      });
    }
  };

export const fetchCompletedWalletRequests =
  () => async (dispatch, getState) => {
    dispatch({ type: FETCH_WALLET_REQUEST_COMPLETED_REQUEST });

    try {
      const { token } = getState(); // Assuming auth state contains token
      const response = await axios.get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_BACKEND_URL}/wallet-request/completed/all`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        dispatch({
          type: FETCH_WALLET_REQUEST_COMPLETED_SUCCESS,
          payload: response.data.data.data,
        });
      }
      // toast.success("Completed wallet request fetched successfully");
      return response.data.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An error occurred while fetching completed wallet request";

      dispatch({
        type: FETCH_WALLET_REQUEST_COMPLETED_FAILURE,
        payload: errorMessage,
      });

      toast.error(errorMessage);
      throw error;
    }
  };

export const fetchSingleWalletRequest =
  (walletRequestId) => async (dispatch, getState) => {
    dispatch({ type: FETCH_SINGLE_WALLET_REQUEST_REQUEST });

    try {
      // Get the auth token from the state properly
      const { token } = getState(); // Ensure this matches your auth state structure

      // Add some debugging
      console.log("Fetching wallet request:", walletRequestId);
      console.log("Token available:", !!token);

      const response = await axios.get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_BACKEND_URL}/wallet-request/view/${walletRequestId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // More debugging
      console.log("Request response:", response);

      // Always dispatch success if we get a response
      dispatch({
        type: FETCH_SINGLE_WALLET_REQUEST_SUCCESS,
        payload: response.data,
      });

      return response.data;
    } catch (error) {
      console.error("Fetch wallet request error:", error);

      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An error occurred while fetching wallet request";

      dispatch({
        type: FETCH_SINGLE_WALLET_REQUEST_FAILURE,
        payload: errorMessage,
      });

      throw error;
    }
  };
export const createWalletRequest =
  (requestData) => async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_WALLET_REQUEST_REQUEST });

      const { token } = getState(); // Assuming auth state has token

      const response = await axios.post(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_BACKEND_URL}/wallet-request`,
        {
          requestType: requestData.requestType,
          description: requestData.description,
          amount: requestData.amount,
          isGroupRequest: requestData.isGroupRequest,
          targetGroupId: requestData.targetGroupId,
          targetGroupName: requestData.targetGroupName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({
        type: CREATE_WALLET_REQUEST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_WALLET_REQUEST_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message || error.response?.data?.error?.[0],
      });
    }
  };
