import PropTypes from "prop-types";

const AuthLayout = (props) => {
  const { src, alt, children } = props;
  return (
    <div className="oja-auth-layout-container">
      <div className="login-left d-none d-lg-block">
        <img src={src} alt={alt} />
      </div>

      <div className="login-right">
        <div className="oja-auth-bg">
          <div className="card">
            <div className="card-body">
              <div className="text-center mb-5">
                <img src="/svgs/logo.svg" alt="logo" />
              </div>
              <div className="oja-auth-content">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AuthLayout.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default AuthLayout;
