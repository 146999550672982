import {
  FETCH_BILLER_CATEGORIES_FAILURE,
  FETCH_BILLER_CATEGORIES_REQUEST,
  FETCH_BILLER_CATEGORIES_SUCCESS,
  FETCH_SINGLE_BILLER_CATEGORY_FAILURE,
  FETCH_SINGLE_BILLER_CATEGORY_REQUEST,
  FETCH_SINGLE_BILLER_CATEGORY_SUCCESS,
  GET_ALL_BILLERS_FAILURE,
  GET_ALL_BILLERS_REQUEST,
  GET_ALL_BILLERS_SUCCESS,
  GET_SINGLE_BILLER_FAILURE,
  GET_SINGLE_BILLER_REQUEST,
  GET_SINGLE_BILLER_SUCCESS,
} from "../constants/billerConstants";

import { toast } from "react-toastify";
import axios from "axios";

export const fetchBillerCategories =
  (currentPage, itemsPerPage, order = "desc", searchQuery = "") =>
  async (dispatch, getState) => {
    dispatch({ type: FETCH_BILLER_CATEGORIES_REQUEST });

    // Get token from Redux store
    const { token } = getState();
    // Use token from Redux state or fall back to localStorage
    const tokenToUse = token || JSON.parse(localStorage.getItem("token"));

    try {
      const response = await axios.get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_BACKEND_URL}/biller/category`,
        {
          params: {
            page: currentPage,
            limit: itemsPerPage,
            order: order,
            search_query: searchQuery,
          },
          headers: {
            Authorization: `Bearer ${tokenToUse}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data.data.data);
      if (response.data.status) {
        dispatch({
          type: FETCH_BILLER_CATEGORIES_SUCCESS,
          payload: {
            categories: response.data.data.data || [],
            totalItems: response.data.data.meta?.totalItems || 0,
          },
        });
      } else {
        dispatch({
          type: FETCH_BILLER_CATEGORIES_FAILURE,
          payload: "Failed to fetch biller categories",
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";
      toast.error(errorMessage);
      dispatch({
        type: FETCH_BILLER_CATEGORIES_FAILURE,
        payload: errorMessage,
      });
    }
  };

export const fetchSingleBillerCategory =
  (categoryId) => async (dispatch, getState) => {
    dispatch({ type: FETCH_SINGLE_BILLER_CATEGORY_REQUEST });

    // Get token from Redux store
    const { token } = getState();
    // Use token from Redux state or fall back to localStorage
    const tokenToUse = token || JSON.parse(localStorage.getItem("token"));

    if (!tokenToUse) {
      const errorMessage = "Authentication token not found";
      toast.error(errorMessage);
      dispatch({
        type: FETCH_SINGLE_BILLER_CATEGORY_FAILURE,
        payload: errorMessage,
      });
      return;
    }

    try {
      const response = await axios.get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_BACKEND_URL}/biller/category/${categoryId}`,
        {
          headers: {
            Authorization: `Bearer ${tokenToUse}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status) {
        dispatch({
          type: FETCH_SINGLE_BILLER_CATEGORY_SUCCESS,
          payload: response.data.data,
        });
      } else {
        const errorMessage =
          response.data.message || "Failed to fetch biller category";
        toast.error(errorMessage);
        dispatch({
          type: FETCH_SINGLE_BILLER_CATEGORY_FAILURE,
          payload: errorMessage,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";
      toast.error(errorMessage);
      dispatch({
        type: FETCH_SINGLE_BILLER_CATEGORY_FAILURE,
        payload: errorMessage,
      });
    }
  };

export const getAllBillers =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: GET_ALL_BILLERS_REQUEST });

    const { token } = getState();

    // Default parameters
    const {
      page = 1,
      limit = 20,
      order = "asc",
      search_query = "",
      biller_category_id = "",
    } = params;

    try {
      // Build query parameters
      const queryParams = new URLSearchParams();
      queryParams.append("page", page);
      queryParams.append("limit", limit);

      if (order) queryParams.append("order", order);
      if (search_query) queryParams.append("search_query", search_query);
      if (biller_category_id)
        queryParams.append("biller_category_id", biller_category_id);

      const response = await axios.get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_BACKEND_URL}/biller?${queryParams.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status) {
        const { data } = response.data;

        dispatch({
          type: GET_ALL_BILLERS_SUCCESS,
          payload: {
            billers: data.data || [],
            totalItems: data.meta.totalItems,
            currentPage: data.meta.currentPage,
            totalPages: data.meta.totalPages,
          },
        });
      } else {
        dispatch({
          type: GET_ALL_BILLERS_FAILURE,
          payload: response.data.message || "Failed to fetch billers",
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";

      dispatch({
        type: GET_ALL_BILLERS_FAILURE,
        payload: errorMessage,
      });
    }
  };

export const getSingleBiller = (billerId) => async (dispatch, getState) => {
  dispatch({ type: GET_SINGLE_BILLER_REQUEST });

  const { token } = getState();

  try {
    const response = await axios.get(
      `{{OJANOW_WALLET_URL}}/biller/${billerId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.status) {
      dispatch({
        type: GET_SINGLE_BILLER_SUCCESS,
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: GET_SINGLE_BILLER_FAILURE,
        payload: response.data.message || "Failed to fetch biller details",
      });
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "An error occurred. Please try again.";

    dispatch({
      type: GET_SINGLE_BILLER_FAILURE,
      payload: errorMessage,
    });
  }
};
