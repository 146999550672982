import React from "react";
import PropTypes from "prop-types";

const ActionModal = ({
  title,
  img,
  onClose,
  onSubmit,
  buttonText,
  subTitle,
  allowCloseModal = true, // if onSubmit another would be opened, pass false as the argument
}) => {
  const handleSubmit = () => {
    if (onSubmit) onSubmit();
    allowCloseModal && onClose();
  };

  return (
    <div className="cancel-modal-container" onClick={onClose}>
      <div
        className="funds-modal group-input action-modal"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="group-input-header">
          {title && (
            <h2 className={img && title ? "img-header" : ""}>{title}</h2>
          )}
          {img && (
            <img
              src={
                typeof img === "boolean" ? "../../../../svgs/success.svg" : img
              }
              alt="Modal Visual"
            />
          )}
          {subTitle && (
            <p
              className={`action-modal-subtitle ${
                (img && !buttonText) || !img
                  ? "action-modal-subtitle1"
                  : "action-modal-subtitle2"
              }`}
            >
              {subTitle}
            </p>
          )}
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>

        {buttonText && (
          <button
            className={`funds-button ${img ? "image" : "no-image"}`}
            onClick={handleSubmit}
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

ActionModal.propTypes = {
  title: PropTypes.string,
  img: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  buttonText: PropTypes.string,
  subTitle: PropTypes.string,
  allowCloseModal: PropTypes.bool,
};

export default ActionModal;
