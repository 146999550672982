import React, { useState } from "react";
import Topbar from "./components/report/Topbar";
import Filter from "./components/Filter";
import TotalCard from "./components/report/TotalCard";
import RevenueCategory from "./components/report/RevenueCategory";
import Orders from "./components/report/Orders";
import { useLocation } from "react-router-dom";

const Report = () => {
  const location = useLocation();
  const reportType = location.pathname.split("/").pop();

  const [filter, setFilter] = useState({
    startDate: "2024-01-01", // Replace with default or dynamic values
    endDate: "2024-12-31",
  });

  const handleDateChange = (newFilter) => {
    setFilter(newFilter);
  };

  return (
    <div>
      <Topbar />
      <Filter onDateChange={handleDateChange} defaultDateRange="Last 30 Days" />
      <div className="stats-container">
        <div className="left-side">
          <TotalCard
            startDate={filter.startDate}
            endDate={filter.endDate}
            categoryId={reportType} // Assuming the report type matches categoryId
          />
        </div>
        <div className="right-side">
          <h2>Revenue by category</h2>
          <RevenueCategory
            startDate={filter.startDate}
            endDate={filter.endDate}
            transactionId={reportType}
          />
        </div>
      </div>
      <Orders />
    </div>
  );
};

export default Report;
