import React, { Suspense, useContext } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";

import { Spinner, Error } from "./components";

import { AuthContext } from "./context/AuthContext";

// Pages
// Auth
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import LinkSent from "./pages/auth/LinkSent";
import PasswordChanged from "./pages/auth/PasswordChanged";
import ResetPassword from "./pages/auth/ResetPassword";

import NotFound from "./pages/NotFound";
import Report from "./pages/dashboard/admin/pay/PayReport";
import Reports from "./pages/dashboard/admin/pay/PayReports";
import PayTransactions from "./pages/dashboard/admin/pay/PayTransactions";
import PayTransactionsDetails from "./pages/dashboard/admin/pay/PayTransactionsDetails";
import { ModalProvider } from "./context/ModalContext";
import GlobalModalHandler from "./components/GlobalModal";
import AppLogout from "./components/AppLogout";

// Admin Dashboard
import Dashboard from "./layouts/Dashboard";
import PayBiller from "./pages/dashboard/admin/pay/PayBiller";
const Home = React.lazy(() => import("./pages/dashboard/admin/Home"));
// Customer Dashboard
const Customer = React.lazy(() =>
  import("./pages/dashboard/admin/customer/Customer")
);
const AddCustomer = React.lazy(() =>
  import("./pages/dashboard/admin/customer/components/AddCustomer")
);
const EditCustomer = React.lazy(() =>
  import("./pages/dashboard/admin/customer/components/EditCustomer")
);
const ViewCustomer = React.lazy(() =>
  import("./pages/dashboard/admin/customer/components/ViewCustomer")
);
// Rider Dashboard
const Rider = React.lazy(() => import("./pages/dashboard/admin/rider/Rider"));
const AddRider = React.lazy(() =>
  import("./pages/dashboard/admin/rider/components/AddRider")
);
const EditRider = React.lazy(() =>
  import("./pages/dashboard/admin/rider/components/EditRider")
);
const RiderDetails = React.lazy(() =>
  import("./pages/dashboard/admin/rider/components/RiderDetails")
);
// Customer Reps
const Staff = React.lazy(() =>
  import("./pages/dashboard/admin/customerReps/CustomerRep")
);
const AddStaff = React.lazy(() =>
  import("./pages/dashboard/admin/customerReps/components/AddCustomerRep")
);
// Order
const Order = React.lazy(() => import("./pages/dashboard/admin/order/Order"));
const OrderDetals = React.lazy(() =>
  import("./pages/dashboard/admin/order/components/OrderDetails")
);
//Re Ordered List
const ReOrderedList = React.lazy(() =>
  import("./pages/dashboard/admin/reOrderList/ReOrderList")
);

// Category
const Category = React.lazy(() =>
  import("./pages/dashboard/admin/categories/Category")
);
const SubCategories = React.lazy(() =>
  import("./pages/dashboard/admin/categories/SubCategories")
);
const EditCategory = React.lazy(() =>
  import("./pages/dashboard/admin/categories/components/EditCategory")
);
// Product
const Product = React.lazy(() =>
  import("./pages/dashboard/admin/product/Product")
);
const ViewProduct = React.lazy(() =>
  import("./pages/dashboard/admin/product/components/ViewProduct")
);
const EditProduct = React.lazy(() =>
  import("./pages/dashboard/admin/product/components/EditProduct")
);

// Settings
const Settings = React.lazy(() =>
  import("./pages/dashboard/admin/settings/Settings")
);
// Settings
const PaySettings = React.lazy(() =>
  import("./pages/dashboard/admin/pay/PaySettings")
);
// Settings
const PayBillerSettings = React.lazy(() =>
  import("./pages/dashboard/admin/pay/PayBillerSettings")
);
// Data Analytics
const DataAnalytics = React.lazy(() =>
  import("./pages/dashboard/admin/data/DataAnalytics")
);
// Banners
const Banners = React.lazy(() =>
  import("./pages/dashboard/admin/banners/Banners")
);
const ViewBanner = React.lazy(() =>
  import("./pages/dashboard/admin/banners/components/BannerDetails")
);
const UpdateBannerImage = React.lazy(() =>
  import("./pages/dashboard/admin/banners/components/UpdateImage")
);
// Shiping
const Shipping = React.lazy(() =>
  import("./pages/dashboard/admin/shipping/Shipping")
);
// Reviews
const Review = React.lazy(() =>
  import("./pages/dashboard/admin/rating/Rating")
);
// Testimonials
const Testimonial = React.lazy(() =>
  import("./pages/dashboard/admin/testimonial/Testimonial")
);
const AddTestimonial = React.lazy(() =>
  import("./pages/dashboard/admin/testimonial/components/AddTestimonial")
);
const EditTestimonial = React.lazy(() =>
  import("./pages/dashboard/admin/testimonial/components/EditTestimonial")
);

const Coupon = React.lazy(() =>
  import("./pages/dashboard/admin/coupon/Coupon")
);
const ViewCoupon = React.lazy(() =>
  import("./pages/dashboard/admin/coupon/components/ViewCoupon")
);

const Collectibles = React.lazy(() =>
  import("./pages/dashboard/admin/collectibles/Collectibles")
);

const Events = React.lazy(() =>
  import("./pages/dashboard/admin/pass/events/Events")
);
const EventItem = React.lazy(() =>
  import("./pages/dashboard/admin/pass/events/EventItem")
);
const CreateLiveEvents = React.lazy(() =>
  import("./pages/dashboard/admin/pass/events/CreateEvent")
);
const CreateEventPage = React.lazy(() =>
  import(
    "./pages/dashboard/admin/pass/events/components/createEvent/CreateEvent"
  )
);

const AddTicket = React.lazy(() =>
  import("./pages/dashboard/admin/pass/events/AddTicket")
);

const AddProduct = React.lazy(() =>
  import("./pages/dashboard/admin/pass/events/AddProducts")
);

const PublishEvent = React.lazy(() =>
  import("./pages/dashboard/admin/pass/events/PublishYourEvent")
);

const ReportsPage = React.lazy(() =>
  import("./pages/dashboard/admin/pass/orders/Orders")
);
const OrderDetails = React.lazy(() =>
  import("./pages/dashboard/admin/pass/orders/OrderDetails")
);
const Categories = React.lazy(() =>
  import("./pages/dashboard/admin/pass/category/Categories")
);
const CreateCategories = React.lazy(() =>
  import("./pages/dashboard/admin/pass/category/CreateCategory")
);

const EventReport = React.lazy(() =>
  import("./pages/dashboard/admin/pass/reports/EventReport")
);
const EventReportsItem = React.lazy(() =>
  import("./pages/dashboard/admin/pass/reports/EventSalesReportItem")
);
const EventReportHistory = React.lazy(() =>
  import("./pages/dashboard/admin/pass/reports/EmailReportHistory")
);

//wallet
const Transactions = React.lazy(() =>
  import("./pages/dashboard/admin/wallet/transactions/Transactions")
);
const TransactionsDetails = React.lazy(() =>
  import("./pages/dashboard/admin/wallet/transactions/TransactionDetails")
);
const Users = React.lazy(() =>
  import("./pages/dashboard/admin/wallet/users/Users")
);
const UserDetails = React.lazy(() =>
  import("./pages/dashboard/admin/wallet/users/UserDetails")
);
const PayCategories = React.lazy(() =>
  import("./pages/dashboard/admin/pay/PayCategories")
);

const Groups = React.lazy(() =>
  import("./pages/dashboard/admin/wallet/groups/Groups")
);
const GroupDetails = React.lazy(() =>
  import("./pages/dashboard/admin/wallet/groups/GroupDetails")
);
const AddGroupUsers = React.lazy(() =>
  import("./pages/dashboard/admin/wallet/groups/AddGroupUsers")
);
const Requests = React.lazy(() =>
  import("./pages/dashboard/admin/wallet/requests/Requests")
);
const RequestDetails = React.lazy(() =>
  import("./pages/dashboard/admin/wallet/requests/RequestDetails")
);
const WalletReports = React.lazy(() =>
  import("./pages/dashboard/admin/wallet/reports/Reports")
);
const WalletSettings = React.lazy(() =>
  import("./pages/dashboard/admin/wallet/settings/Settings")
);
const WalletReportHistory = React.lazy(() =>
  import("./pages/dashboard/admin/pass/reports/EmailReportHistory")
);
const PayCommissions = React.lazy(() =>
  import("./pages/dashboard/admin/pay/PayCommissions")
);
const PayCommission = React.lazy(() =>
  import("./pages/dashboard/admin/pay/PayCommission")
);
const AdminWallet = React.lazy(() =>
  import("./pages/dashboard/admin/wallet/admin-wallet/AdminWallet")
);
const AdminWalletDetails = React.lazy(() =>
  import("./pages/dashboard/admin/wallet/admin-wallet/AdminWalletDetails")
);

const App = () => {
  const { currentUser } = useContext(AuthContext);

  // eslint-disable-next-line react/prop-types
  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/" />;
  };

  return (
    <>
      <ErrorBoundary fallback={<Error />}>
        <ToastContainer autoClose={5000} />
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path="/">
              <Route index element={<Login />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="link-sent" element={<LinkSent />} />
              <Route path="password-changed" element={<PasswordChanged />} />

              {/* Admin dashboard links */}
              <Route
                path="dashboard"
                element={
                  <AppLogout>
                    <RequireAuth>
                      <Dashboard />
                    </RequireAuth>
                  </AppLogout>
                }
              >
                <Route path="home" element={<Home />} />

                {/* Default route */}

                {/* Pay routes */}
                <Route path="pay">
                  <Route path="reports" element={<Reports />} />
                  <Route
                    path="categories"
                    element={
                      <ModalProvider>
                        <PayCategories />
                        <GlobalModalHandler />
                      </ModalProvider>
                    }
                  />
                  <Route
                    path="settings"
                    element={
                      <ModalProvider>
                        <PaySettings />
                        <GlobalModalHandler />
                      </ModalProvider>
                    }
                  />
                  <Route
                    path="settings/:billerCategoryId"
                    element={
                      <ModalProvider>
                        <PayBillerSettings />
                        <GlobalModalHandler />
                      </ModalProvider>
                    }
                  />
                  <Route
                    path="commissions"
                    element={
                      <ModalProvider>
                        <PayCommissions />
                        <GlobalModalHandler />
                      </ModalProvider>
                    }
                  />
                  <Route
                    path="commissions/:billerCategoryName/:billerCategoryId"
                    element={
                      <ModalProvider>
                        <PayCommission />
                        <GlobalModalHandler />
                      </ModalProvider>
                    }
                  />
                  <Route
                    path="categories/:billerCategoryId"
                    element={
                      <ModalProvider>
                        <PayBiller />
                        <GlobalModalHandler />
                      </ModalProvider>
                    }
                  />
                  <Route path="reports/:name" element={<Report />} />{" "}
                  <Route path="transactions" element={<PayTransactions />} />{" "}
                  <Route
                    path="transactions/:transactionId"
                    element={<PayTransactionsDetails />}
                  />{" "}
                </Route>
                {/* Pass routes */}
                <Route path="pass">
                  <Route path="events" element={<Events />} />
                  <Route path="events/:name" element={<EventItem />} />
                  <Route
                    path="events/create-event/"
                    element={<CreateLiveEvents />}
                  >
                    <Route path=":eventType" element={<CreateEventPage />} />{" "}
                    <Route
                      path=":eventType/add-ticket"
                      element={<AddTicket />}
                    />{" "}
                    <Route
                      path=":eventType/add-products"
                      element={<AddProduct />}
                    />{" "}
                    <Route
                      path=":eventType/publish"
                      element={<PublishEvent />}
                    />{" "}
                  </Route>
                  <Route path="orders" element={<ReportsPage />} />
                  <Route path="reports" element={<EventReport />} />
                  <Route
                    path="reports/:reportItemId"
                    element={<EventReportsItem />}
                  />
                  <Route
                    path="reports/history"
                    element={<EventReportHistory />}
                  />
                  <Route
                    path="orders/:transactionId"
                    element={<OrderDetails />}
                  />
                  <Route path="event-categories" element={<Categories />} />
                  <Route
                    path="events-categories/create"
                    element={<CreateCategories />}
                  />
                </Route>
                <Route path="wallet">
                  <Route path="transactions" element={<Transactions />} />
                  <Route
                    path="transactions/:id"
                    element={<TransactionsDetails />}
                  />
                  <Route
                    path="users"
                    element={
                      <ModalProvider>
                        <Users />
                        <GlobalModalHandler />
                      </ModalProvider>
                    }
                  />
                  <Route
                    path="admin-wallet"
                    element={
                      <ModalProvider>
                        <AdminWallet />
                        <GlobalModalHandler />
                      </ModalProvider>
                    }
                  />
                  <Route
                    path="admin-wallet/:id"
                    element={<AdminWalletDetails />}
                  />{" "}
                  <Route
                    path="users/:walletId/:userId"
                    element={
                      <ModalProvider>
                        <UserDetails />
                        <GlobalModalHandler />
                      </ModalProvider>
                    }
                  />
                  <Route
                    path="groups"
                    element={
                      <ModalProvider>
                        <Groups />
                        <GlobalModalHandler />
                      </ModalProvider>
                    }
                  />
                  <Route
                    path="groups/:groupId/:groupName"
                    element={
                      <ModalProvider>
                        <GroupDetails />
                        <GlobalModalHandler />
                      </ModalProvider>
                    }
                  />
                  <Route
                    path="groups/:groupId/:groupName/add-users"
                    element={
                      <ModalProvider>
                        <AddGroupUsers />
                        <GlobalModalHandler />
                      </ModalProvider>
                    }
                  />
                  <Route
                    path="requests"
                    element={
                      <ModalProvider>
                        <Requests />
                        <GlobalModalHandler />
                      </ModalProvider>
                    }
                  />
                  <Route
                    path="requests/:walletRequestId"
                    element={
                      <ModalProvider>
                        <RequestDetails />
                        <GlobalModalHandler />
                      </ModalProvider>
                    }
                  />
                  <Route path="reports" element={<WalletReports />} />
                  <Route path="settings" element={<WalletSettings />} />
                  <Route
                    path="reports/history"
                    element={<WalletReportHistory />}
                  />
                </Route>
                <Route path="reports/:name" element={<Report />} />
                <Route path="password-reset" element={<ResetPassword />} />
                {/* customers */}
                <Route path="customers" element={<Customer />} />
                <Route
                  path="customers/view-customer/:customerId"
                  element={<ViewCustomer />}
                />
                <Route
                  path="customers/add-customer"
                  element={<AddCustomer />}
                />
                <Route
                  path="customers/edit-customer/:customerId"
                  element={<EditCustomer />}
                />
                {/* customer reps */}
                <Route path="users-permissions" element={<Staff />} />
                <Route
                  path="users-permissions/add-user"
                  element={<AddStaff />}
                />
                {/* riders */}
                <Route path="riders" element={<Rider />} />
                <Route path="riders/add-rider" element={<AddRider />} />
                <Route
                  path="riders/edit-rider/:riderId"
                  element={<EditRider />}
                />
                <Route
                  path="riders/view-rider/:riderId"
                  element={<RiderDetails />}
                />
                {/* order */}
                <Route
                  path="orders"
                  element={
                    <ModalProvider>
                      <Order /> <GlobalModalHandler />
                    </ModalProvider>
                  }
                />
                <Route path="reorder-lists" element={<ReOrderedList />} />

                <Route
                  path="orders/order-details/:orderId"
                  element={<OrderDetals />}
                />
                {/* category */}
                <Route path="categories" element={<Category />} />
                <Route
                  path="categories/:categoryId"
                  element={<SubCategories />}
                />
                <Route
                  path="categories/edit-category/:categoryId"
                  element={<EditCategory />}
                />
                {/* product */}
                <Route path="products" element={<Product />} />
                <Route
                  path="products/product-details/:productId"
                  element={<ViewProduct />}
                />
                <Route
                  path="products/edit-product/:productId"
                  element={<EditProduct />}
                />
                {/* banners */}
                <Route path="banners" element={<Banners />} />
                <Route
                  path="banners/banner-details/:bannerId"
                  element={<ViewBanner />}
                />
                <Route
                  path="banners/update-image/:imageId"
                  element={<UpdateBannerImage />}
                />
                {/* shipping */}
                <Route path="shipping" element={<Shipping />} />
                {/* review */}
                <Route path="reviews" element={<Review />} />
                {/* testimonial */}
                <Route path="testimonials" element={<Testimonial />} />
                <Route
                  path="testimonials/add-testimonial"
                  element={<AddTestimonial />}
                />
                <Route
                  path="testimonials/edit/:testimonialId"
                  element={<EditTestimonial />}
                />
                {/* coupon */}
                <Route path="coupons" element={<Coupon />} />
                <Route path="coupons/:couponId" element={<ViewCoupon />} />
                {/* transactions */}
                <Route path="transactions" element={<Transactions />} />
                {/* data */}
                <Route path="data-analytics" element={<DataAnalytics />} />
                {/* settings */}
                <Route path="settings" element={<Settings />} />
                {/* settings */}
                {/* collectibles */}
                <Route path="collectibles" element={<Collectibles />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
        <Outlet />
      </ErrorBoundary>
    </>
  );
};

export default Sentry.withProfiler(App);
