import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { creditWallet } from "../../../../../../redux/actions/adminWalletAction";

const CheckoutHandler = ({ onClose, refreshData }) => {
  const [checkoutUrl, setCheckoutUrl] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("pending");
  const iframeRef = useRef(null);
  const initialDomain = useRef("");

  const dispatch = useDispatch();

  // Get credit wallet state from Redux
  const { loading, success, error } = useSelector(
    (state) => state.creditWallet
  );

  // Store the initial domain when URL is set
  useEffect(() => {
    if (checkoutUrl) {
      try {
        const url = new URL(checkoutUrl);
        initialDomain.current = url.hostname;
        console.log("Initial domain:", initialDomain.current);
      } catch (e) {
        console.error("Invalid URL:", checkoutUrl);
      }
    }
  }, [checkoutUrl]);

  // Function to handle payment completion
  const handlePaymentCompletion = (status) => {
    // Update payment status
    setPaymentStatus(status);

    // If payment was successful
    if (status === "PENDING" || status === "SUCCESSFUL") {
      // Hide iframe after 3 seconds to give user time to see success message
      setTimeout(() => {
        setShowIframe(false);
        // Refresh wallet data
        refreshData();
        // Close the modal completely
        onClose();
      }, 3000);
    }
  };

  // Check for domain changes in the iframe
  const checkDomainChange = () => {
    try {
      if (iframeRef.current) {
        // This won't work directly due to same-origin policy, but we'll handle via message events
        console.log("Checking iframe location...");
      }
    } catch (e) {
      // Will likely hit security error, which we handle with message events instead
    }
  };

  // Listen for postMessage events from the payment iframe
  useEffect(() => {
    const handleMessage = (event) => {
      console.log("Received message from:", event.origin, "Data:", event.data);

      // Handle messages from paystack domain
      if (event.origin.includes("paystack.com")) {
        // Check if this is a string message indicating a checkout event
        if (typeof event.data === "string") {
          // Handle non-JSON string messages
          if (
            event.data.includes("loaded:checkout") ||
            event.data.includes("complete")
          ) {
            console.log("Checkout event detected:", event.data);
          }

          // Detect successful payment or completion in text message
          if (
            event.data.includes("success") ||
            event.data.includes("complete") ||
            event.data.includes("done") ||
            event.data.includes("redirect")
          ) {
            console.log("Success/completion detected in message:", event.data);
            setTimeout(() => {
              setShowIframe(false);
              refreshData();
              onClose();
            }, 2000);
          }

          // Try to parse as JSON, but don't error if it fails
          try {
            const data = JSON.parse(event.data);

            // Handle payment status updates
            if (data.status && data.reference) {
              handlePaymentCompletion(data.status, data.reference);
            }

            // Handle domain changes (if paystack sends a redirect notification)
            if (data.action === "redirect" || data.type === "redirect") {
              console.log("Redirect detected:", data);
              setTimeout(() => {
                setShowIframe(false);
                refreshData();
                onClose();
              }, 1000);
            }
          } catch (e) {
            // Not valid JSON, already handled above
          }
        }
        // Handle object data
        else if (typeof event.data === "object" && event.data !== null) {
          const data = event.data;

          // Handle payment status updates
          if (data.status && data.reference) {
            handlePaymentCompletion(data.status, data.reference);
          }

          // Handle domain changes (if paystack sends a redirect notification)
          if (data.action === "redirect" || data.type === "redirect") {
            console.log("Redirect detected:", data);
            setTimeout(() => {
              setShowIframe(false);
              refreshData();
              onClose();
            }, 1000);
          }
        }
      }

      // If we get a message from a different domain than paystack, likely means a redirect happened
      if (
        !event.origin.includes("paystack.com") &&
        initialDomain.current &&
        initialDomain.current.includes("paystack.com")
      ) {
        console.log("Domain changed from paystack to:", event.origin);
        setTimeout(() => {
          setShowIframe(false);
          refreshData();
          onClose();
        }, 1000);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [onClose, refreshData]);

  // Effect to monitor checkout URL changes
  useEffect(() => {
    if (success && checkoutUrl) {
      setShowIframe(true);
    }
  }, [success, checkoutUrl]);

  // Set up interval to check iframe location periodically
  useEffect(() => {
    if (showIframe) {
      const intervalId = setInterval(checkDomainChange, 1000);
      return () => clearInterval(intervalId);
    }
  }, [showIframe]);

  // Process the checkout
  const processCheckout = (checkoutData) => {
    const { amount, description, topUpBy } = checkoutData;

    // Dispatch credit wallet action
    dispatch(creditWallet(amount, description, topUpBy))
      .then((response) => {
        if (response && response.data && response.data.url) {
          setCheckoutUrl(response.data.url);
        }
      })
      .catch((error) => {
        console.error("Checkout error:", error);
      });
  };

  // Handle iframe load event
  const handleIframeLoad = () => {
    console.log("Iframe loaded successfully");

    // Try to detect redirects on load
    try {
      if (iframeRef.current) {
        const iframeLocation = iframeRef.current.contentWindow.location.href;
        const iframeDomain = new URL(iframeLocation).hostname;

        if (initialDomain.current && iframeDomain !== initialDomain.current) {
          console.log(
            `Domain changed from ${initialDomain.current} to ${iframeDomain}`
          );
          // Close modal and refresh on domain change
          setTimeout(() => {
            setShowIframe(false);
            refreshData();
            onClose();
          }, 1000);
        }
      }
    } catch (e) {
      // Security error expected due to same-origin policy
      // We rely on the message event approach instead
    }
  };

  // Handle iframe error
  const handleIframeError = () => {
    console.error("Failed to load payment page");
    setPaymentStatus("error");
  };

  return {
    processCheckout,
    iframeComponent: showIframe && (
      <div className="checkout-iframe-container">
        <div className="checkout-iframe-overlay">
          <div className="checkout-iframe-wrapper">
            <div className="checkout-iframe-header">
              <h3>Complete Your Payment</h3>
              <button
                className="checkout-iframe-close"
                onClick={() => {
                  setShowIframe(false);
                  onClose();
                }}
              >
                &times;
              </button>
            </div>
            <iframe
              ref={iframeRef}
              src={checkoutUrl}
              title="Payment Checkout"
              className="checkout-iframe"
              onLoad={handleIframeLoad}
              onError={handleIframeError}
              allow="payment"
              sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation"
            />
            {paymentStatus === "error" && (
              <div className="checkout-error-message">
                Failed to load payment page. Please try again.
              </div>
            )}
          </div>
        </div>
      </div>
    ),
    isProcessing: loading,
    isSuccess: success,
    error: error,
    closeIframe: () => setShowIframe(false),
  };
};

CheckoutHandler.propTypes = {
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
};

export default CheckoutHandler;
