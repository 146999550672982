import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

const Filter = ({ onDateChange, defaultDateRange }) => {
  // Date range options
  const dateRanges = ["Last 30 Days", "Last 7 Days", "Last 24 Hours"];

  // Calculate today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split("T")[0];

  // Set initial state based on default range or fallback to "Last 30 Days"
  const [selectedDateRange, setSelectedDateRange] = useState(
    defaultDateRange || "Last 30 Days"
  );

  // Calculate initial start date based on the default range
  const initialStartDate = calculateDateFromRange(
    defaultDateRange || "Last 30 Days"
  );

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(today);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Calculate date based on range selection
  function calculateDateFromRange(range) {
    const resultDate = new Date();
    switch (range) {
      case "Last 24 Hours":
        resultDate.setDate(resultDate.getDate() - 1);
        break;
      case "Last 7 Days":
        resultDate.setDate(resultDate.getDate() - 7);
        break;
      case "Last 30 Days":
        resultDate.setDate(resultDate.getDate() - 30);
        break;
      default:
        return today;
    }
    return resultDate.toISOString().split("T")[0];
  }

  // Initialize filter on component mount
  useEffect(() => {
    if (onDateChange) {
      onDateChange({
        startDate: startDate,
        endDate: endDate,
        dateRange: selectedDateRange,
      });
    }
  }, []);

  // Handle predefined date range selection
  const handleDateRangeClick = (range) => {
    const start = calculateDateFromRange(range);
    setSelectedDateRange(range);
    setStartDate(start);
    setEndDate(today);

    if (onDateChange) {
      onDateChange({
        startDate: start,
        endDate: today,
        dateRange: range,
      });
    }

    // Close the dropdown if it's open
    setIsDropdownOpen(false);
  };

  // Toggle custom date dropdown
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  // Handle manual date input changes
  const handleDateChange = (type, value) => {
    if (type === "start") {
      setStartDate(value);
      // If start date is after end date, update end date too
      if (new Date(value) > new Date(endDate)) {
        setEndDate(value);
      }
    } else {
      setEndDate(value);
    }

    // Switch to custom date range
    setSelectedDateRange("Custom");

    // Notify the parent component when the dates change
    if (onDateChange) {
      onDateChange({
        startDate: type === "start" ? value : startDate,
        endDate: type === "end" ? value : endDate,
        dateRange: "Custom", // Use "Custom" for custom date ranges
      });
    }
  };

  // Create ref for dropdown to handle click outside
  const dropdownRef = useRef(null);

  // Handle clicking outside to close dropdown
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  // Add and remove event listener
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="filter-container" ref={dropdownRef}>
      <div className="date-range">
        {dateRanges.map((range, index) => (
          <button
            key={index}
            className={`range-btn ${
              selectedDateRange === range ? "active" : ""
            }`}
            onClick={() => handleDateRangeClick(range)}
          >
            {range}
          </button>
        ))}
      </div>

      <div className="custom-date-bar">
        <div className="filter-icon">
          {/* Calendar or filter icon would go here */}
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3334 2.66669H2.66671C1.93033 2.66669 1.33337 3.26364 1.33337 4.00002V13.3334C1.33337 14.0697 1.93033 14.6667 2.66671 14.6667H13.3334C14.0698 14.6667 14.6667 14.0697 14.6667 13.3334V4.00002C14.6667 3.26364 14.0698 2.66669 13.3334 2.66669Z"
              stroke="currentColor"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.33337 6.66669H14.6667"
              stroke="currentColor"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4 1.33331V3.99998"
              stroke="currentColor"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 1.33331V3.99998"
              stroke="currentColor"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="date-range-display" onClick={toggleDropdown}>
          <span>{startDate}</span> --- <span>{endDate}</span>
        </div>

        {isDropdownOpen && (
          <div className="custom-dropdown">
            <div className="dropdown-section">
              <span className="dropdown-label">Start Date</span>
              <input
                type="date"
                id="start-date"
                value={startDate}
                max={endDate} // Prevent selecting start date after end date
                onChange={(e) => handleDateChange("start", e.target.value)}
              />
            </div>

            <div className="dropdown-section">
              <span className="dropdown-label">End Date</span>
              <input
                type="date"
                id="end-date"
                value={endDate}
                min={startDate} // Prevent selecting end date before start date
                max={today} // Prevent selecting future dates
                onChange={(e) => handleDateChange("end", e.target.value)}
              />
            </div>

            <div className="dropdown-section dropdown-buttons">
              <button
                className="apply-btn"
                onClick={() => {
                  // Apply custom date range
                  onDateChange({
                    startDate: startDate,
                    endDate: endDate,
                    dateRange: "Custom",
                  });
                  setIsDropdownOpen(false);
                }}
              >
                Apply
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Filter.propTypes = {
  onDateChange: PropTypes.func,
  defaultDateRange: PropTypes.string,
};

export default Filter;
