import PropTypes from "prop-types";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const EllipsisSelector = ({ name, id, actions }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen((prevState) => !prevState);

  const navigate = useNavigate();

  const handleActionClick = (action) => {
    setIsOpen(false);
    if (typeof action === "function") {
      action();
    } else if (typeof action === "string") {
      navigate(action);
    }
  };

  return (
    <>
      <img
        src="../../../../svgs/vertical-dots.svg"
        alt="ellipsis"
        onClick={toggleMenu}
        className="simple-ellipsis-icon"
      />
      {isOpen && (
        <>
          <ul
            className="simple-ellipsis-option-list"
            onClick={(e) => e.stopPropagation()}
          >
            {actions.map((action, index) => (
              <li
                key={index}
                onClick={() => handleActionClick(action.onClick(name, id))}
              >
                {action.label}
              </li>
            ))}
          </ul>
          <div className="simple-ellipsis-backdrop" onClick={toggleMenu} />
        </>
      )}
    </>
  );
};

EllipsisSelector.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default EllipsisSelector;
