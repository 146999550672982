import {
  GET_ALL_CATEGORIES_REQUEST,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_ALL_CATEGORIES_FAILURE,
  CATEGORY_ADD_REQUEST,
  CATEGORY_ADD_SUCCESS,
  CATEGORY_ADD_FAIL,
  FETCH_UNPAGINATED_CATEGORY_FAILURE,
  FETCH_UNPAGINATED_CATEGORY_REQUEST,
  FETCH_UNPAGINATED_CATEGORY_SUCCESS,
  FETCH_CATEGORY_CHILDREN_FAILURE,
  FETCH_CATEGORY_CHILDREN_REQUEST,
  FETCH_CATEGORY_CHILDREN_SUCCESS,
  REARRANGE_CATEGORIES_FAILURE,
  REARRANGE_CATEGORIES_REQUEST,
  REARRANGE_CATEGORIES_SUCCESS,
  EDIT_CATEGORY_FAILURE,
  EDIT_CATEGORY_REQUEST,
  EDIT_CATEGORY_SUCCESS,
} from "../constants/categoryConstants";

const initialState = {
  categoriesLoading: false,
  categories: [],
  totalItems: 0,
  error: null,
  success: true,
  categoryChildren: {},
  currentParentId: null,
  currentCategory: {},
};

export const getAllCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORIES_REQUEST:
      return {
        ...state,
        categoriesLoading: true,
        error: null,
      };
    case GET_ALL_CATEGORIES_SUCCESS:
      console.log("Reducer received payload:", action.payload);
      return {
        ...state,
        categoriesLoading: false,
        categories: action.payload.categories,
        totalItems: action.payload.totalItems,
      };
    case GET_ALL_CATEGORIES_FAILURE:
      return {
        ...state,
        categoriesLoading: false,
        error: action.payload,
        categories: [],
      };
    default:
      return state;
  }
};

export const addCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_ADD_REQUEST:
      return { loading: true };
    case CATEGORY_ADD_SUCCESS:
      return { loading: false, success: true, category: action.payload };
    case CATEGORY_ADD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const fetchUnpaginatedCategoryReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_UNPAGINATED_CATEGORY_REQUEST:
      return {
        ...state,
        categoriesLoading: true,
        error: null,
      };
    case FETCH_UNPAGINATED_CATEGORY_SUCCESS:
      return {
        ...state,
        categoriesLoading: false,
        categories: action.payload.data,
      };
    case FETCH_UNPAGINATED_CATEGORY_FAILURE:
      return {
        ...state,
        categoriesLoading: false,
        error: action.payload,
        categories: [],
      };
    default:
      return state;
  }
};

export const fetchCategoryChildrenReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORY_CHILDREN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_CATEGORY_CHILDREN_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryChildren: action.payload,
      };

    case FETCH_CATEGORY_CHILDREN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const rearrangeCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case REARRANGE_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
        message: null,
      };

    case REARRANGE_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: action.payload.message || "Categories reordered successfully",
        error: null,
      };

    case REARRANGE_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        message: null,
      };

    default:
      return state;
  }
};

export const editCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case EDIT_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        currentCategory: action.payload,
        error: null,
      };

    case EDIT_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
