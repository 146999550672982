import React, { useState } from "react";
import Filter from "./components/Filter";
import TopCardsContainer from "./components/TopCard";
import SalesCards from "./components/SalesCards";
// import { GET_ALL_BILLERS_CATEGORIES } from "../../../../graphql/queries/payQueries";

const Reports = () => {
  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
    dateRange: "",
  });

  const handleDateChange = (newFilter) => {
    setFilter(newFilter);
    console.log("Filter updated:", newFilter);
  };
  // const { data, loading } = useQuery(GET_ALL_BILLERS_CATEGORIES);
  return (
    <div>
      <Filter onDateChange={handleDateChange} defaultDateRange="Last 7 Days" />
      <TopCardsContainer filter={filter} />
      <div className="sales-main-container">
        <h5>Sales Report by Category</h5>
        <SalesCards />
      </div>
    </div>
  );
};

export default Reports;
