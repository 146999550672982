import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { singleWalletActions } from "../../../../../../redux/actions/userWalletActions";
import { performWalletOperation } from "../../../../../../redux/actions/walletRequestActions";

const FundsModal = ({ type, initialAmount, initialReason, onClose }) => {
  const { userId } = useParams();
  const { walletId } = useParams();
  const [amount, setAmount] = useState(initialAmount || "");
  const [reason, setReason] = useState(initialReason || "");
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const dispatch = useDispatch();
  const { loading, success, error } = useSelector(
    (state) => state.createWalletOperation
  );

  // Reset success state when modal opens
  useEffect(() => {
    // This will help prevent immediate execution of useEffect below
    return () => {
      // Reset hasSubmitted when component unmounts
      setHasSubmitted(false);
    };
  }, []);

  // Show success message when operation is successful
  useEffect(() => {
    // Only run this effect if the user has submitted the form
    if (!hasSubmitted) return;

    if (success) {
      toast.success(`${type} funds operation successful!`);
      onClose();
      // Refresh wallet data
      dispatch(singleWalletActions(userId));
    }
    if (error) {
      toast.error(`Error: ${error}`);
      setHasSubmitted(false); // Reset so user can try again
    }
  }, [success, error, type, onClose, dispatch, userId, hasSubmitted]);

  const handleSubmit = () => {
    if (!amount || !reason) {
      toast.error("Please enter both amount and reason");
      return;
    }

    const operationType = type === "Add" ? "credit" : "debit";

    // Prepare data based on the API request format
    const operationData = {
      operation: operationType,
      amount: parseFloat(amount),
      description: reason,
      walletIds: [parseInt(walletId)],
    };

    setHasSubmitted(true); // Mark as submitted
    dispatch(performWalletOperation(operationData));
  };

  return (
    <div className="cancel-modal-container">
      <div className="funds-modal">
        <div className="funds-header">
          <h2>{type} Funds</h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="funds-body">
          <input
            type="number"
            placeholder="Enter Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="input-field number-input"
          />
          <textarea
            placeholder="Reason for the transaction"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className="input-field"
          ></textarea>
          <button
            className={`funds-button ${type === "Add" ? "add" : "debit"}`}
            onClick={handleSubmit}
            disabled={!amount || !reason || loading}
          >
            {!loading ? `${type} Funds` : "Loading..."}
          </button>
        </div>
      </div>
    </div>
  );
};

FundsModal.propTypes = {
  type: PropTypes.oneOf(["Add", "Debit"]).isRequired,
  initialAmount: PropTypes.string,
  initialReason: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default FundsModal;
