import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import {
  CREATE_BILLER_CATEGORY,
  UPDATE_BILLER_CATEGORY,
} from "../../../../../../graphql/mutations/payMutations";
import { GET_ALL_BILLERS_CATEGORIES } from "../../../../../../graphql/queries/payQueries";

const AddBillerCategory = ({ onClose, modalData }) => {
  const isEdit = Boolean(modalData?.id);
  const [image, setImage] = useState(null);
  const [name, setName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [description, setDescription] = useState("");
  const fileRef = useRef(null);

  const categoryOptions = [
    { value: "1.0", label: "Mobile Top Up" },
    { value: "2.0", label: "Mobile PIN" },
    { value: "3.0", label: "Bill Payment" },
    { value: "4.0", label: "Mobile Data" },
    { value: "4.1", label: "Mobile Data > Daily Bundles" },
    { value: "4.2", label: "Mobile Data > 7-14 Day Bundles" },
    { value: "4.3", label: "Mobile Data > Monthly Bundles" },
    { value: "4.4", label: "Mobile Data > Extended Validity Bundles" },
    { value: "4.5", label: "Mobile Data > Special Offers" },
    { value: "4.6", label: "Mobile Data > SME Data Share Bundles" },
    { value: "5.0", label: "Voice, Data & Combo Bundles" },
    { value: "6.0", label: "Internet Services" },
    { value: "7.0", label: "Cable TV" },
    { value: "8.0", label: "Utilities" },
    { value: "8.1", label: "Utilities > Electricity" },
    { value: "8.2", label: "Utilities > Water" },
    { value: "8.3", label: "Utilities > Transport & Toll Payment" },
    { value: "9.0", label: "Betting" },
    { value: "10.0", label: "Taxes" },
    { value: "10.1", label: "Taxes > Federal" },
    { value: "10.2", label: "Taxes > State" },
    { value: "11.0", label: "Lifestyle" },
    { value: "11.1", label: "Lifestyle > Education" },
    { value: "11.2", label: "Lifestyle > Healthcare" },
    { value: "11.3", label: "Lifestyle > Insurance" },
    { value: "12.0", label: "Retail Gift Cards" },
    { value: "13.0", label: "eVouchers" },
  ];
  useEffect(() => {
    if (isEdit && modalData) {
      setName(modalData.name || "");
      setCategoryId(modalData.categoryId || "");
      setDescription(modalData.description || "");
      setImage(modalData.logoUrl || null); // Use logoUrl for the initial image
    }
  }, [isEdit, modalData]);

  const [createBillerCategory, { loading: createLoading }] = useMutation(
    CREATE_BILLER_CATEGORY,
    {
      onCompleted: () => {
        toast.success("Biller category added successfully!");
        // onSave();
        onClose();
      },
      refetchQueries: [{ query: GET_ALL_BILLERS_CATEGORIES }],
      onError: (error) => {
        toast.error(error.message || "An error occurred!");
      },
    }
  );
  const [updateBillerCategory, { loading: updateLoading }] = useMutation(
    UPDATE_BILLER_CATEGORY,
    {
      onCompleted: () => {
        toast.success("Biller category updated successfully!");
        onClose();
      },
      refetchQueries: [{ query: GET_ALL_BILLERS_CATEGORIES }],
      onError: (error) => {
        toast.error(error.message || "An error occurred!");
      },
    }
  );
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file)); // Preview uploaded image
      fileRef.current = file;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation for file upload in edit mode
    // if (isEdit && !fileRef.current) {
    //   toast.error("Please upload a file to update the category.");
    //   return;
    // }
    const variables = {
      name,
      description,
      categoryId,
      file: fileRef.current, // Optional for update
    };

    if (isEdit) {
      updateBillerCategory({
        variables: {
          id: modalData.id, // ID of the category being edited
          ...variables,
        },
      });
    } else {
      createBillerCategory({ variables });
    }
  };

  return (
    <div className="cancel-modal-container">
      <div className="add-biller-container">
        <div className="add-biller-header">
          <h1 className="add-biller-title">
            {isEdit ? "Edit Biller Category" : "Add Biller Category"}
          </h1>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <form onSubmit={handleSubmit} className="add-biller-form">
          {/* Upload Image */}
          <div className="add-biller-upload-container">
            <label htmlFor="upload-image" className="add-biller-upload-label">
              {image ? (
                <img
                  src={image}
                  alt="Uploaded"
                  className="add-biller-uploaded-image"
                />
              ) : (
                <>
                  <span className="add-biller-upload-icon">
                    <img src="../../../../../svgs/camera.svg" alt="" />
                  </span>
                  <p>Upload Image</p>
                </>
              )}
            </label>
            <input
              type="file"
              id="upload-image"
              accept="image/*"
              onChange={handleImageUpload}
              className="add-biller-upload-input"
            />
          </div>
          {/* Category Name */}
          <input
            type="text"
            placeholder="Category Name"
            className="add-biller-input-field"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          {/* Category ID */}
          <select
            className="add-biller-input-field"
            value={categoryId}
            onChange={(e) => setCategoryId(e.target.value)}
            required
          >
            <option value="">Select Category</option>
            {categoryOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {/* Description */}
          <textarea
            placeholder="Description"
            className="add-biller-textarea-field"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          {/* Add Button */}
          <div className="add-biller-save-button-container">
            <button
              type="submit"
              className="add-biller-save-button"
              disabled={createLoading || updateLoading}
            >
              {createLoading || updateLoading
                ? isEdit
                  ? "Saving Changes..."
                  : "Saving..."
                : isEdit
                ? "Save Changes"
                : "Add"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

AddBillerCategory.propTypes = {
  onClose: PropTypes.func.isRequired,
  modalData: PropTypes.object,
};

export default AddBillerCategory;
