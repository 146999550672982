import PropTypes from "prop-types";
import React from "react";

const HighDemandModal = ({ onClose, onSubmit, isHighDemand }) => {
  const handleSubmit = () => {
    if (onSubmit) onSubmit();
    onClose();
  };

  return (
    <div
      className="cancel-modal-container high-demand-modal-container"
      onClick={onClose}
    >
      <div
        className="funds-modal group-input action-modal"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="group-input-header">
          <h2 className="img-header">High Demand</h2>

          <p>Turn {isHighDemand ? "off" : "on"} high demand notifications?</p>

          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>

        <div className="high-btn-container">
          <button
            className={`funds-button high-btn-yes`}
            onClick={handleSubmit}
          >
            YES
          </button>
          <button className={`funds-button `} onClick={onClose}>
            NO
          </button>
        </div>
      </div>
    </div>
  );
};

HighDemandModal.propTypes = {
  onClose: PropTypes.func.isRequired, // Callback function for closing the modal
  onSubmit: PropTypes.func.isRequired, // Optional callback function for submit action
  isHighDemand: PropTypes.bool, // Boolean to indicate high-demand status
};

export default HighDemandModal;
