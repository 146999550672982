import {
  GET_CUSTOMER_TRANSACTIONS_REQUEST,
  GET_CUSTOMER_TRANSACTIONS_SUCCESS,
  GET_CUSTOMER_TRANSACTIONS_FAILURE,
  GET_ALL_TRANSACTIONS_REQUEST,
  GET_ALL_TRANSACTIONS_SUCCESS,
  GET_ALL_TRANSACTIONS_FAILURE,
  FETCH_OJANOWPAY_ANALYTICS_REQUEST,
  FETCH_OJANOWPAY_ANALYTICS_SUCCESS,
  FETCH_OJANOWPAY_ANALYTICS_FAILURE,
  FETCH_BILLER_CATEGORY_ANALYTICS_REQUEST,
  FETCH_BILLER_CATEGORY_ANALYTICS_SUCCESS,
  FETCH_BILLER_CATEGORY_ANALYTICS_FAILURE,
  FETCH_TRANSACTION_REQUEST,
  FETCH_TRANSACTION_SUCCESS,
  FETCH_TRANSACTION_FAILURE,
} from "../constants/transactionConstants";

const initialState = {
  loading: false,
  transactions: [],
  totalItems: 0,
  currentPage: 1,
  totalPages: 0,
  error: null,
  errorMessage: null,
  analytics: {
    totalTransactions: 0,
    totalRevenue: 0,
    failedTransactions: 0,
  },
};

// Reducer
export const getCustomerTransactionsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        errorMessage: null,
      };
    case GET_CUSTOMER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: action.payload.transactions,
        totalItems: action.payload.totalItems,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        errorMessage:
          action.payload.transactions.length === 0
            ? "No transactions found"
            : null,
      };
    case GET_CUSTOMER_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        transactions: [],
      };
    default:
      return state;
  }
};

export const getAllTransactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        errorMessage: null,
      };
    case GET_ALL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: action.payload.transactions,
        totalItems: action.payload.totalItems,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
        errorMessage:
          action.payload.transactions.length === 0
            ? "There are no transaction(s)"
            : null,
      };
    case GET_ALL_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        transactions: [],
      };
    default:
      return state;
  }
};

export const ojanowpayAnalyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OJANOWPAY_ANALYTICS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        errorMessage: null,
      };
    case FETCH_OJANOWPAY_ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        analytics: {
          totalTransactions: action.payload.totalTransactions,
          totalRevenue: action.payload.totalRevenue,
        },
        error: null,
        errorMessage: null,
      };
    case FETCH_OJANOWPAY_ANALYTICS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export const billerCategoryAnalyticsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_BILLER_CATEGORY_ANALYTICS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        errorMessage: null,
      };
    case FETCH_BILLER_CATEGORY_ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        analytics: {
          totalTransactions: action.payload.totalTransactions,
          totalRevenue: action.payload.totalRevenue,
          failedTransactions: action.payload.failedTransactions,
        },
        error: null,
        errorMessage: null,
      };
    case FETCH_BILLER_CATEGORY_ANALYTICS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export const getSingleTransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        transaction: action.payload,
        error: null,
      };
    case FETCH_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
