/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
// import { useMutation } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// import { AuthContext } from "../../context/AuthContext";
// import { LOGOUT_ADMIN } from "../../graphql/queries/authQueries";
// import { ADMINS } from "../../graphql/queries/customerRepQueries";
import { dashboardLinksArray } from "../../utils/static";
import SidebarItem from "./SidebarItem";
import axios from "axios";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const navigate = useNavigate();
  // const { dispatch } = useContext(AuthContext);
  // const [logoutAdmin] = useMutation(LOGOUT_ADMIN);
  const [userType, setUserType] = useState(null);
  // const { data } = useQuery(ADMINS);
  const [loading, setLoading] = useState(true);
  // const roles = data?.activeAdministrator?.user.roles.map((val) => val.code);
  // const userType = JSON.parse(localStorage.getItem("user"));
  console.log(userType);
  const logout = () => {
    // logoutAdmin();
    // dispatch({ type: "LOGOUT" });
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
    toast.success("Logout successful");
  };

  const Authed = useSelector((state) => state.token);
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        // const token = localStorage.getItem("token");
        const storedUserType = JSON.parse(localStorage.getItem("user"));

        const endpoint =
          storedUserType === "STAFF"
            ? `${process.env.REACT_APP_BACKEND_URL}/staff/profile`
            : `${process.env.REACT_APP_BACKEND_URL}/admin`;

        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${Authed}`,
          },
        });

        if (response.data.status && response.data.code === 200) {
          const profileUserType =
            storedUserType === "STAFF"
              ? response.data.data.user.userType
              : response.data.data.profile.userType;

          setUserType(profileUserType);
        } else {
          toast.error("Failed to fetch user profile");
        }
      } catch (error) {
        console.error("Profile fetch error:", error);
        toast.error("Error fetching user profile");

        if (error.response?.status === 401) {
          // dispatch({ type: "LOGOUT" });
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [navigate]);
  if (loading) {
    return (
      <aside className="oja-sidebar">
        <div className="oja-sidebar-container">
          {/* Add your loading spinner or skeleton here */}
          <div>Loading...</div>
        </div>
      </aside>
    );
  }
  return (
    <aside className="oja-sidebar">
      <div className="oja-sidebar-container">
        {dashboardLinksArray.map((item) =>
          userType === "ADMIN" ||
          !item.role ||
          (item.role.includes("admin_only") ? userType === "ADMIN" : true) ? (
            <SidebarItem key={item.id} item={item} userType={userType} />
          ) : null
        )}

        <Link
          onClick={() => logout()}
          to="#"
          className="sidebar-item plain logout"
        >
          <img src="/svgs/logout.svg" alt="" />
          <span>Logout</span>
        </Link>
      </div>
    </aside>
  );
};

export default Sidebar;
