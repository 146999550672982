import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AiFillPlusCircle } from "react-icons/ai";
import { getAllBillers } from "../../../../../../redux/actions/billerActions";
import { useModal } from "../../../../../../context/ModalContext";
import EllipsisSelector from "../../../wallet/components/EllipsisSelector";
import { toast } from "react-toastify";
import { ButtonSpinner } from "../../../../../../components";

const BillerCards = () => {
  const { billerCategoryId } = useParams();
  const dispatch = useDispatch();
  const { billers, loading, error } = useSelector((state) => state.billers);
  const { openModal } = useModal();

  // Parameters for fetching billers
  const params = {
    page: 1,
    limit: 20,
    order: "asc",
    search_query: "",
    biller_category_id: billerCategoryId,
  };

  // Fetch billers when component mounts or billerCategoryId changes
  const fetchBillers = useCallback(() => {
    dispatch(getAllBillers(params));
  }, [dispatch, billerCategoryId]);

  useEffect(() => {
    fetchBillers();
  }, [fetchBillers]);

  // Handler for deleting a biller
  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this biller?")) {
      // Here you would dispatch a delete action
      toast.success("Delete functionality will be implemented");
      console.log(id);
      // After deletion is successful, refetch the billers
      fetchBillers();
    }
  };

  // Actions available for each biller
  const userActions = [
    {
      label: "Update",
      onClick: (biller) =>
        openModal("AddBiller", {
          id: biller.id,
          name: biller.name,
          description: biller.description,
          billerCategoryId: biller.billerCategoryId,
          logoUrl: biller.logoUrl,
          operatorId: biller.operatorId,
          categoryId: biller.categoryId,
        }),
    },
    {
      label: "Delete",
      onClick: (biller) => handleDelete(biller.id),
    },
  ];

  // Loading state
  if (loading) {
    return (
      <div className="text-center my-5">
        <ButtonSpinner />
        <p className="mt-3">Loading billers...</p>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="alert alert-danger my-4">
        <p>Error: {error}</p>
        <button className="btn btn-outline-danger mt-2" onClick={fetchBillers}>
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="sales-cards-container">
      {billers && billers.length > 0 ? (
        billers.map((biller) => (
          <div key={biller.id} className="sales-card">
            <div className="sales-card-top">
              <span className="sales-card-title">{biller.name}</span>
              <div className="sales-card-vertical-dots">
                <div className="vertical-dots">
                  <EllipsisSelector
                    name={biller.name}
                    actions={userActions.map((action) => ({
                      ...action,
                      onClick: () => action.onClick(biller),
                    }))}
                  />
                </div>
              </div>
            </div>

            {biller.logoUrl && (
              <img
                src={biller.logoUrl}
                alt={biller.name}
                style={{ width: "10%", marginTop: "auto", objectFit: "cover" }}
                className="mt-auto"
              />
            )}
          </div>
        ))
      ) : (
        <p className="text-center my-4">
          No billers available for this category.
        </p>
      )}
      <button
        onClick={() => openModal("AddBiller", { billerCategoryId })}
        className="add-new-categories"
      >
        <AiFillPlusCircle color="#6918B4" size={40} />
        <p>Add Biller</p>
      </button>
    </div>
  );
};

export default BillerCards;
