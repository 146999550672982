import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import React from "react";
import { GET_BILLER_CATEGORY_ANALYTICS } from "../../../../../../graphql/queries/payQueries";

const TotalCard = ({ startDate, endDate, categoryId }) => {
  const { data, loading, error } = useQuery(GET_BILLER_CATEGORY_ANALYTICS, {
    variables: {
      input: { startDate, endDate },
      id: categoryId,
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const { totalTransactions, totalRevenue, failedTransactions } =
    data.getTransactionBillerCategoryAnalytics;
  return (
    <div className="total-card pay-reports-card">
      <div className="card">
        <img src="../../../svgs/views-trend.svg" alt="" />
        <div>
          <p className="card-title">Total Revenue</p>
          <h2>{`₦${totalRevenue.toLocaleString()}`}</h2>
        </div>
      </div>
      <div className="card">
        <img src="../../../svgs/views-trend.svg" alt="" />
        <div>
          <p className="card-title">Total Transactions</p>
          <h2>{totalTransactions}</h2>
        </div>
      </div>
      <div className="card">
        <img src="../../../svgs/views-down-trend.svg" alt="" />
        <div>
          <p className="card-title">Failed Transactions</p>
          <h2>{failedTransactions}</h2>
        </div>
      </div>
    </div>
  );
};
TotalCard.propTypes = {
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  categoryId: PropTypes.any,
};
export default TotalCard;
