import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import {
  CREATE_BILLER,
  UPDATE_BILLER,
} from "../../../../../../graphql/mutations/payMutations";
// import { useParams } from "react-router-dom";
import {
  GET_ALL_BILLERS,
  GET_SOPHITEL_OPERATORS,
} from "../../../../../../graphql/queries/payQueries";
import Select from "react-select";
import { useParams } from "react-router-dom";

const AddBiller = ({ onClose, refetchVariables, modalData }) => {
  const isEditMode = Boolean(modalData?.id);
  // const { billerCategoryId } = useParams();
  const [image, setImage] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [operatorId, setOperatorId] = useState("");
  const [description, setDescription] = useState("");
  const fileRef = useRef(null);
  const { categoryId } = useParams();

  useEffect(() => {
    if (modalData) {
      setCategoryName(modalData.name || "");
      setOperatorId(modalData.operatorId || "");
      setDescription(modalData.description || "");
      setImage(modalData.logoUrl || null);
    }
  }, [modalData]);
  const { data: operatorData, loading: operatorLoading } = useQuery(
    GET_SOPHITEL_OPERATORS,
    {
      variables: {
        input: {
          categoryId,
        },
      },
    }
  );
  const operatorOptions = operatorData?.getSochitelOperators.data.map((op) => ({
    value: op.operatorId,
    label: op.name,
  }));

  console.log(operatorData);

  const [createBiller, { loading: creating }] = useMutation(CREATE_BILLER, {
    onCompleted: () => {
      toast.success("Biller added successfully!");
      onClose();
    },
    refetchQueries: [
      {
        query: GET_ALL_BILLERS,
        variables: refetchVariables, // Dynamic variables passed from modalData
      },
    ],
    onError: (error) => {
      toast.error(error.message || "An error occurred!");
    },
  });
  const [updateBiller, { loading: updating }] = useMutation(UPDATE_BILLER, {
    onCompleted: () => {
      toast.success("Biller updated successfully!");
      onClose();
    },
    refetchQueries: [
      {
        query: GET_ALL_BILLERS,
        variables: refetchVariables,
      },
    ],
    onError: (error) => {
      toast.error(error.message || "An error occurred!");
    },
  });
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file)); // Preview uploaded image
      fileRef.current = file;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const variables = {
      name: categoryName,
      description,
      operatorId,
      billerCategoryId:
        modalData?.billerCategoryId || refetchVariables.billerCategoryId,
      file: fileRef.current,
    };

    if (isEditMode) {
      updateBiller({
        variables: {
          ...variables,
          id: modalData.id,
        },
      });
    } else {
      createBiller({ variables });
    }
  };

  return (
    <div className="cancel-modal-container">
      <div className="add-biller-container">
        <div className="add-biller-header">
          <h1 className="add-biller-title">
            {isEditMode ? "Edit Biller" : "Add Biller"}
          </h1>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <form onSubmit={handleSubmit} className="add-biller-form">
          {/* Upload Image */}
          <div className="add-biller-upload-container">
            <label htmlFor="upload-image" className="add-biller-upload-label">
              {image ? (
                <img
                  src={image}
                  alt="Uploaded"
                  className="add-biller-uploaded-image"
                />
              ) : (
                <>
                  <span className="add-biller-upload-icon">
                    <img src="../../../../../svgs/camera.svg" alt="" />
                  </span>
                  <p>Upload Image</p>
                </>
              )}
            </label>
            <input
              type="file"
              id="upload-image"
              accept="image/*"
              onChange={handleImageUpload}
              className="add-biller-upload-input"
            />
          </div>
          {/* Name of Category */}
          <input
            type="text"
            placeholder="Name of Category"
            className="add-biller-input-field"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            required
          />
          {/* Operator ID */}
          {/* <input
            type="text"
            placeholder="Operator ID"
            className="add-biller-input-field"
            value={operatorId}
            onChange={(e) => setOperatorId(e.target.value)}
            required
          /> */}
          <Select
            options={operatorOptions}
            value={operatorOptions?.find(
              (option) => option.value === operatorId
            )}
            onChange={(selectedOption) => setOperatorId(selectedOption.value)}
            placeholder="Select Operator"
            isLoading={operatorLoading}
            className="add-biller-select"
          />
          {/* Description */}
          <textarea
            placeholder="Description"
            className="add-biller-textarea-field"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          {/* Save Button */}
          <div className="add-biller-save-button-container">
            <button
              type="submit"
              className="add-biller-save-button"
              disabled={creating || updating}
            >
              {creating || updating ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

AddBiller.propTypes = {
  onClose: PropTypes.func.isRequired,
  refetchVariables: PropTypes.object.isRequired,
  modalData: PropTypes.object,
};

export default AddBiller;
