import {
  BANNER_UPLOAD_FAILURE,
  BANNER_UPLOAD_REQUEST,
  BANNER_UPLOAD_SUCCESS,
  FETCH_BANNERS_FAILURE,
  FETCH_BANNERS_REQUEST,
  FETCH_BANNERS_SUCCESS,
  FETCH_BANNER_REQUEST,
  FETCH_BANNER_SUCCESS,
  FETCH_BANNER_FAILURE,
} from "../constants/bannerConstants";

const initialState = {
  loading: false,
  banner: null,
  image: null,
  error: null,
  banners: [],

  meta: {
    totalItems: 0,
    totalPages: 0,
  },
};

export const createBannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case BANNER_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case BANNER_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        banner: action.payload.banner,
        image: action.payload.image,
        error: null,
      };

    case BANNER_UPLOAD_FAILURE:
      return {
        ...state,
        loading: false,
        banner: null,
        image: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

// Reducer
export const fetchBannersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BANNERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_BANNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        banners: action.payload.banners,
        meta: {
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
        },
      };

    case FETCH_BANNERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const fetchBannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BANNER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_BANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        banner: action.payload,
      };
    case FETCH_BANNER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
