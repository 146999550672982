import {
  GET_ALL_PRODUCTS_FAILURE,
  GET_ALL_PRODUCTS_REQUEST,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_SINGLE_PRODUCT_FAILURE,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_REQUEST,
  PRODUCT_ADD_FAIL,
  PRODUCT_ADD_REQUEST,
  PRODUCT_ADD_SUCCESS,
  UPDATE_PRODUCT_QUANTITY_FAILURE,
  UPDATE_PRODUCT_QUANTITY_REQUEST,
  UPDATE_PRODUCT_QUANTITY_SUCCESS,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_AVAILABILITY_FAILURE,
  UPDATE_PRODUCT_AVAILABILITY_REQUEST,
  UPDATE_PRODUCT_AVAILABILITY_SUCCESS,
} from "../constants/productConstants";

const initialState = {
  loading: false,
  singleProductLoading: false,
  singleProduct: null,
  products: [],
  totalItems: 0,
  error: null,
  errorMessage: null,
};

export const getAllProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        errorMessage: null,
      };
    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload.products,
        totalItems: action.payload.totalItems,
        errorMessage:
          action.payload.products.length === 0
            ? "There are no product(s)"
            : null,
      };
    case GET_ALL_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        products: [],
      };

    default:
      return state;
  }
};

export const getSingleProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_PRODUCT_REQUEST:
      return {
        ...state,
        singleProductLoading: true,
        error: null,
        errorMessage: null,
      };
    case GET_SINGLE_PRODUCT_SUCCESS:
      return {
        ...state,
        singleProductLoading: false,
        singleProduct: action.payload,
        errorMessage: !action.payload ? "Product not found" : null,
      };
    case GET_SINGLE_PRODUCT_FAILURE:
      return {
        ...state,
        singleProductLoading: false,
        error: action.payload,
        singleProduct: null,
      };
    default:
      return state;
  }
};

export const addProductReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_ADD_REQUEST:
      return { loading: true };
    case PRODUCT_ADD_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case PRODUCT_ADD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const updateProductQuantityReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PRODUCT_QUANTITY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };

    case UPDATE_PRODUCT_QUANTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,

        products: state.products.map((product) =>
          product.id === action.payload.id
            ? { ...product, stock: action.payload.stock }
            : product
        ),

        product:
          state.product && state.product.id === action.payload.id
            ? { ...state.product, stock: action.payload.stock }
            : state.product,
      };

    case UPDATE_PRODUCT_QUANTITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };

    default:
      return state;
  }
};

export const updateProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PRODUCT_REQUEST:
      return { ...state, loading: true, error: null };

    case UPDATE_PRODUCT_SUCCESS:
      return { ...state, loading: false, product: action.payload, error: null };

    case UPDATE_PRODUCT_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const updateProductAvailabilityReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UPDATE_PRODUCT_AVAILABILITY_REQUEST:
      return { ...state, loading: true, error: null };
    case UPDATE_PRODUCT_AVAILABILITY_SUCCESS:
      return { ...state, loading: false, product: action.payload, error: null };
    case UPDATE_PRODUCT_AVAILABILITY_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
