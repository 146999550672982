import {
  ADD_FEES_FAILURE,
  ADD_FEES_REQUEST,
  ADD_FEES_SUCCESS,
  UPDATE_FEES_FAILURE,
  UPDATE_FEES_REQUEST,
  UPDATE_FEES_SUCCESS,
} from "../constants/feesConstant";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const addFeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FEES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ADD_FEES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    case ADD_FEES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const updateFeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FEES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case UPDATE_FEES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    case UPDATE_FEES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
