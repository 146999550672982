import {
  GET_ALL_STAFF_REQUEST,
  GET_ALL_STAFF_SUCCESS,
  GET_ALL_STAFF_FAILURE,
  GET_ALL_DRIVERS_FAILURE,
  GET_ALL_DRIVERS_REQUEST,
  GET_ALL_DRIVERS_SUCCESS,
  STAFF_INVITE_REQUEST,
  STAFF_INVITE_FAILURE,
  STAFF_INVITE_SUCCESS,
  DRIVER_INVITE_REQUEST,
  DRIVER_INVITE_SUCCESS,
  DRIVER_INVITE_FAILURE,
  PERMISSION_LIST_FAIL,
  PERMISSION_LIST_REQUEST,
  PERMISSION_LIST_SUCCESS,
  UPDATE_DRIVER_PROFILE_REQUEST,
  UPDATE_DRIVER_PROFILE_SUCCESS,
  UPDATE_DRIVER_PROFILE_FAILURE,
  FETCH_CONFIG_REQUEST,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_FAILURE,
} from "../constants/staffConstants";

const initialState = {
  loading: false,
  staffList: [],
  drivers: [],
  totalItems: 0,
  error: null,
  errorMessage: null,
  user: null,
  driver: null,
  config: [],
};

export const getAllStaffReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STAFF_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        errorMessage: null,
      };
    case GET_ALL_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        staffList: action.payload.staffList,
        totalItems: action.payload.totalItems,
        errorMessage:
          action.payload.staffList.length === 0
            ? "There are no staff member"
            : null,
      };
    case GET_ALL_STAFF_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        staffList: [],
      };
    default:
      return state;
  }
};

export const getAllDriversReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DRIVERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        errorMessage: null,
      };
    case GET_ALL_DRIVERS_SUCCESS:
      return {
        ...state,
        loading: false,
        drivers: action.payload.data,
        totalItems: action.payload.data.length,
        errorMessage:
          action.payload.data.length === 0 ? "There are no driver(s)" : null,
      };
    case GET_ALL_DRIVERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        drivers: [],
      };
    default:
      return state;
  }
};

export const invitestaffReducer = (state = initialState, action) => {
  switch (action.type) {
    case STAFF_INVITE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case STAFF_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
      };

    case STAFF_INVITE_FAILURE:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const inviteDriverReducer = (state = initialState, action) => {
  switch (action.type) {
    case DRIVER_INVITE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case DRIVER_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        driver: action.payload.driver,
        error: null,
      };

    case DRIVER_INVITE_FAILURE:
      return {
        ...state,
        loading: false,
        user: null,
        driver: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getPermissionListReducer = (
  state = { permissions: [], loading: false, error: null },
  action
) => {
  switch (action.type) {
    case PERMISSION_LIST_REQUEST:
      return { ...state, loading: true };
    case PERMISSION_LIST_SUCCESS:
      return {
        loading: false,
        permissions: action.payload,
        error: null,
      };
    case PERMISSION_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateDriverProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DRIVER_PROFILE_REQUEST:
      return { ...state, loading: true };

    case UPDATE_DRIVER_PROFILE_SUCCESS:
      return { loading: false, driver: action.payload, error: null };

    case UPDATE_DRIVER_PROFILE_FAILURE:
      return { loading: false, driver: null, error: action.payload };

    default:
      return state;
  }
};

export const fetchConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONFIG_REQUEST:
      return { ...state, loading: true, success: false, error: null };

    case FETCH_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        config: action.payload,
        success: true,
      };

    case FETCH_CONFIG_FAILURE:
      return {
        loading: false,
        error: action.payload,
        success: false,
      };

    default:
      return state;
  }
};
