import {
  CREATE_GROUP_FAILURE,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_SUCCESS,
  FETCH_WALLET_GROUPS_FAILURE,
  FETCH_WALLET_GROUPS_REQUEST,
  FETCH_WALLET_GROUPS_SUCCESS,
  FETCH_WALLETS_IN_GROUP_FAILURE,
  FETCH_WALLETS_IN_GROUP_REQUEST,
  FETCH_WALLETS_IN_GROUP_SUCCESS,
  ADD_MEMBERS_TO_GROUP_FAILURE,
  ADD_MEMBERS_TO_GROUP_REQUEST,
  ADD_MEMBERS_TO_GROUP_SUCCESS,
  RESET_ADD_MEMBERS_TO_GROUP_STATUS,
  FETCH_WALLETS_NOT_IN_GROUP_REQUEST,
  FETCH_WALLETS_NOT_IN_GROUP_SUCCESS,
  FETCH_WALLETS_NOT_IN_GROUP_FAILURE,
  REMOVE_WALLET_FROM_GROUP_FAILURE,
  REMOVE_WALLET_FROM_GROUP_REQUEST,
  REMOVE_WALLET_FROM_GROUP_SUCCESS,
} from "../constants/groupConstants";

const initialState = {
  loading: false,
  groups: [],
  success: false,
  currentGroup: null,
  error: null,
  pagination: {
    page: 1,
    limit: 10,
  },
  wallets: [],
  walletsInGroups: {},
};

export const createGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        groups: [...state.groups, action.payload],
        currentGroup: action.payload,
        error: null,
      };

    case CREATE_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const fetchWalletGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WALLET_GROUPS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        pagination: {
          page: action.payload.page,
          limit: action.payload.limit,
        },
      };

    case FETCH_WALLET_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        groups: action.payload,
        error: null,
      };

    case FETCH_WALLET_GROUPS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const walletsInGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WALLETS_IN_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_WALLETS_IN_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        walletsInGroups: {
          ...state.walletsInGroups,
          [action.payload.walletGroup.id]: action.payload.wallet,
        },
        meta: action.payload.meta, // Add meta to the state
        error: null,
      };

    case FETCH_WALLETS_IN_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const createMemberInGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MEMBERS_TO_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };

    case ADD_MEMBERS_TO_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        error: null,
      };

    case ADD_MEMBERS_TO_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case RESET_ADD_MEMBERS_TO_GROUP_STATUS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export const fetchWalletsNotInGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WALLETS_NOT_IN_GROUP_REQUEST:
      return { ...state, loading: true, success: false, error: null };

    case FETCH_WALLETS_NOT_IN_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        wallets: action.payload,
        error: null,
        success: true,
      };

    case FETCH_WALLETS_NOT_IN_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };

    default:
      return state;
  }
};

export const removeWalletFromGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_WALLET_FROM_GROUP_REQUEST:
      return { ...state, loading: true, error: null, success: false };

    case REMOVE_WALLET_FROM_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
      };

    case REMOVE_WALLET_FROM_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
