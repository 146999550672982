import React from "react";
import { DashboardHeader } from "../../../../components";
import BillerCards from "./components/biller/BillerCards";

const PayBiller = () => {
  return (
    <div>
      <div className="pay-categories-header">
        <DashboardHeader title={"OjaNow Pay Billers "} />
      </div>
      <div className="pay-categories-body">
        <BillerCards />
      </div>
    </div>
  );
};

export default PayBiller;
