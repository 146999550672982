import { ApolloClient, split, InMemoryCache } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { ApolloLink, from } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";

// Use REACT_APP_BACKEND_URL instead of REACT_BACKEND_URL
const httpLink = createUploadLink({
  // eslint-disable-next-line no-undef
  uri: process.env.REACT_APP_BACKEND_URL,
});

// Setup the header for the request
const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("token");

  const authorizationHeader = token ? `Bearer ${token}` : null;
  operation.setContext({
    headers: {
      authorization: authorizationHeader,
    },
  });
  return forward(operation);
});

// After the backend responds, we take the refreshToken from headers if it exists, and save it in the cookie/localstorage.
const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const refreshToken = headers.get("Vendure-Auth-Token");
      if (refreshToken) {
        localStorage.setItem("token", refreshToken);
      }
    }

    return response;
  });
});

// setting configuration for websocket connect for subscription
const wsLink = new GraphQLWsLink(
  createClient({
    url:
      window.location.hostname === "admin.ojanowapp.com"
        ? "https://api.ojanowapp.com/admin-api"
        : "https://staging-api.ojanowapp.com/admin-api",
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink, // web socket connection for subscriptions
  httpLink // http connection for query and mutation
);

const client = new ApolloClient({
  link: from([middlewareAuthLink, afterwareLink, splitLink]),
  cache: new InMemoryCache(),
});

export default client;
