export const GET_ALL_PRODUCTS_REQUEST = "GET_ALL_PRODUCTS_REQUEST";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_FAILURE = "GET_ALL_PRODUCTS_FAILURE";
export const GET_SINGLE_PRODUCT_REQUEST = "GET_SINGLE_PRODUCT_REQUEST";
export const GET_SINGLE_PRODUCT_SUCCESS = "GET_SINGLE_PRODUCT_SUCCESS";
export const GET_SINGLE_PRODUCT_FAILURE = "GET_SINGLE_PRODUCT_FAILURE";
export const PRODUCT_ADD_REQUEST = "PRODUCT_ADD_REQUEST";
export const PRODUCT_ADD_SUCCESS = "PRODUCT_ADD_SUCCESS";
export const PRODUCT_ADD_FAIL = "PRODUCT_ADD_FAILURE";
export const UPDATE_PRODUCT_QUANTITY_REQUEST =
  "UPDATE_PRODUCT_QUANTITY_REQUEST";
export const UPDATE_PRODUCT_QUANTITY_SUCCESS =
  "UPDATE_PRODUCT_QUANTITY_SUCCESS";
export const UPDATE_PRODUCT_QUANTITY_FAILURE =
  "UPDATE_PRODUCT_QUANTITY_FAILURE";
export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";
export const UPDATE_PRODUCT_AVAILABILITY_REQUEST =
  "UPDATE_PRODUCT_AVAILABILITY_REQUEST";
export const UPDATE_PRODUCT_AVAILABILITY_SUCCESS =
  "UPDATE_PRODUCT_AVAILABILITY_SUCCESS";
export const UPDATE_PRODUCT_AVAILABILITY_FAILURE =
  "UPDATE_PRODUCT_AVAILABILITY_FAILURE";
