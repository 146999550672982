import { createStore, applyMiddleware, compose, combineReducers } from "redux";

// import thunk from "redux-thunk";
import { loginAdminReducer } from "./reducers/authReducers";
import { thunk } from "redux-thunk";
import {
  getAllProductsReducer,
  getSingleProductReducer,
  addProductReducer,
  updateProductReducer,
  updateProductQuantityReducer,
} from "./reducers/productReducers";

import {
  addCouponReducer,
  deleteCouponReducer,
  getAllCouponCodesReducer,
  getSingleCouponCodeReducer,
} from "./reducers/couponReducers";
// import {
//   getAllCategoriesReducer,
//   getBillerCategoriesReducer,
// } from "./reducers/categoryReducers";
import {
  getAllCategoriesReducer,
  addCategoryReducer,
  fetchUnpaginatedCategoryReducer,
  fetchCategoryChildrenReducer,
  rearrangeCategoryReducer,
  editCategoryReducer,
} from "./reducers/categoryReducers";
import {
  getAllStaffReducer,
  getAllDriversReducer,
  invitestaffReducer,
  inviteDriverReducer,
  getPermissionListReducer,
  updateDriverProfileReducer,
  fetchConfigReducer,
} from "./reducers/staffReducers";
import {
  getBillersCategoriesReducer,
  getAllBillersReducer,
  getBillerCategoriesReducer,
  getSingleBillerReducer,
} from "./reducers/billerReducers";

// import {
//   getCustomerTransactions,
//   getAllTransactions,
//   ojanowpayAnalyticsReducer,
// } from "./actions/transactionActions";
import {
  ojanowpayAnalyticsReducer,
  getAllTransactionsReducer,
  billerCategoryAnalyticsReducer,
  getSingleTransactionReducer,
} from "./reducers/transactionReducers";
// import { getAllTransactionsReducer } from "./reducers/transactionReducers";
import {
  orderListReducer,
  orderDetailsReducer,
  fetchAvailableDriverReducer,
  assignDriverToOrderReducer,
  cancelOrderReducer,
} from "./reducers/orderReducers";
import {
  createBannerReducer,
  fetchBannerReducer,
  fetchBannersReducer,
} from "./reducers/bannerReducers";

import {
  addAddressReducer,
  updateAddressReducer,
  fetchAddressReducer,
} from "./reducers/addressReducer";
import { addFeesReducer, updateFeesReducer } from "./reducers/feesReducer";

import {
  fetchTransactionCommissionReducer,
  fetchBillerCategoryCommissionReducer,
  fetchSingleBillerCommissionReducer,
  updateSingleBillerCommissionReducer,
  createBillerCategoryCommissionReducer,
  deleteBillerCategoryCommissionReducer,
  calculatedCommissionReducer,
  getBillerCategoryCommissionReducer,
} from "./reducers/commissionReducer";

import {
  createGroupReducer,
  fetchWalletGroupsReducer,
  walletsInGroupReducer,
  createMemberInGroupReducer,
  fetchWalletsNotInGroupReducer,
  removeWalletFromGroupReducer,
} from "./reducers/groupReducers";

import {
  fetchWalletTransactionsReducer,
  walletApproveOrDeclineRequestReducer,
  createWalletOperationReducer,
  pendingUserRequestsReducer,
  fetchPendingGroupWalletRequestsReducer,
  fetchCompletedWalletRequestReducer,
  fetchSingleWalletRequestReducer,
  createWalletRequestReducer,
} from "./reducers/walletRequestReducer";
import {
  userSingleWalletReducer,
  walletReducer,
} from "./reducers/userWalletReducers";

import {
  fetchCustomerReducer,
  singleCustomerProfileReducer,
} from "./reducers/customerReducer";

import { fetchWalletReportReducer } from "./reducers/reportReducers";
import {
  creditWalletReducer,
  fetchAdminWalletReducer,
  fetchAdminWalletTransactionsReducer,
  fetchAdminWalletTransactionReducer,
} from "./reducers/adminWalletReducer";

const initialstate = {
  userInformation: {
    info: localStorage.getItem("userdetails")
      ? JSON.parse(localStorage.getItem("userdetails"))
      : null,
  },
  token: localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null,
};

const reducer = combineReducers({
  //   freelancerReg: freelancerRegister,
  userInformation: loginAdminReducer,
  token: loginAdminReducer,
  products: getAllProductsReducer,
  product: getSingleProductReducer,
  coupons: getAllCouponCodesReducer,
  coupon: getSingleCouponCodeReducer,

  staff: getAllStaffReducer,
  drivers: getAllDriversReducer,
  billersCategories: getBillersCategoriesReducer,
  billerCategories: getBillerCategoriesReducer,
  billers: getAllBillersReducer,
  biller: getSingleBillerReducer,
  // customerTransactions: getCustomerTransactions,
  transactions: getAllTransactionsReducer,
  ojanowpayAnalyticsReducer: ojanowpayAnalyticsReducer,
  billerCategoryAnalytics: billerCategoryAnalyticsReducer,
  getSingleTransaction: getSingleTransactionReducer,
  addProduct: addProductReducer,
  addCategory: addCategoryReducer,
  orderList: orderListReducer,
  updateProduct: updateProductReducer,
  updateProductQty: updateProductQuantityReducer,
  addCoupon: addCouponReducer,
  deleteCoupon: deleteCouponReducer,
  inviteStaff: invitestaffReducer,
  inviteDriver: inviteDriverReducer,
  createBanner: createBannerReducer,
  fetchBanner: fetchBannerReducer,
  fetchBanners: fetchBannersReducer,
  addAddress: addAddressReducer,
  addFees: addFeesReducer,
  updateAddress: updateAddressReducer,
  updateFees: updateFeesReducer,
  getOrderDetails: orderDetailsReducer,
  getPermissionList: getPermissionListReducer,
  fetchAvailableDriver: fetchAvailableDriverReducer,
  fetchUnpaginatedCategory: fetchUnpaginatedCategoryReducer,
  assignDriverToOrder: assignDriverToOrderReducer,
  fetchTransactionCommission: fetchTransactionCommissionReducer,
  fetchBillerCategoryCommission: fetchBillerCategoryCommissionReducer,
  fetchSingleBillerCommission: fetchSingleBillerCommissionReducer,
  updateSingleBillerCommission: updateSingleBillerCommissionReducer,
  createBillerCategoryCommission: createBillerCategoryCommissionReducer,
  deleteBillerCategoryCommission: deleteBillerCategoryCommissionReducer,
  calculatedCommission: calculatedCommissionReducer,
  getBillerCategoryCommission: getBillerCategoryCommissionReducer,
  createGroup: createGroupReducer,
  fetchWalletGroups: fetchWalletGroupsReducer,
  walletsInGroup: walletsInGroupReducer,
  fetchWalletTransactions: fetchWalletTransactionsReducer,
  cancelOrder: cancelOrderReducer,
  fetchCategoryChildren: fetchCategoryChildrenReducer,
  rearrangeCategory: rearrangeCategoryReducer,
  editCategory: editCategoryReducer,
  createMemberInGroup: createMemberInGroupReducer,
  userWallet: walletReducer,
  userSingleWallet: userSingleWalletReducer,
  fetchCustomer: fetchCustomerReducer,
  fetchSingleCustomer: singleCustomerProfileReducer,
  walletApproveOrDeclineRequest: walletApproveOrDeclineRequestReducer,
  updateDriverProfile: updateDriverProfileReducer,
  createWalletOperation: createWalletOperationReducer,
  fetchWalletsNotInGroup: fetchWalletsNotInGroupReducer,
  pendingUserRequests: pendingUserRequestsReducer,
  removeWalletFromGroup: removeWalletFromGroupReducer,
  fetchConfig: fetchConfigReducer,
  fetchAddress: fetchAddressReducer,
  fetchWalletReport: fetchWalletReportReducer,
  fetchPendingGroupWalletRequests: fetchPendingGroupWalletRequestsReducer,
  fetchCompletedWalletRequest: fetchCompletedWalletRequestReducer,
  fetchSingleWalletRequest: fetchSingleWalletRequestReducer,
  createWalletRequest: createWalletRequestReducer,
  creditWallet: creditWalletReducer,
  adminWallet: fetchAdminWalletReducer,
  fetchAdminWallet: fetchAdminWalletReducer,
  fetchAdminWalletTransaction: fetchAdminWalletTransactionReducer,
  fetchAdminWalletTransactions: fetchAdminWalletTransactionsReducer,
  // transactions: getAllTransactionsReducer,

  allCategories: getAllCategoriesReducer,
});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [thunk];

const store = createStore(
  reducer,
  initialstate,
  composeEnhancer(applyMiddleware(...middleware))
);
export default store;
