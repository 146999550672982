// Action Types for Staff
export const GET_ALL_STAFF_REQUEST = "GET_ALL_STAFF_REQUEST";
export const GET_ALL_STAFF_SUCCESS = "GET_ALL_STAFF_SUCCESS";
export const GET_ALL_STAFF_FAILURE = "GET_ALL_STAFF_FAILURE";

export const GET_ALL_DRIVERS_SUCCESS = "GET_ALL_DRIVERS_SUCCESS";
export const GET_ALL_DRIVERS_REQUEST = "GET_ALL_DRIVERS_REQUEST";
export const GET_ALL_DRIVERS_FAILURE = "GET_ALL_DRIVERS_FAILURE";

export const STAFF_INVITE_REQUEST = "STAFF_INVITE_REQUEST";
export const STAFF_INVITE_SUCCESS = "STAFF_INVITE_SUCCESS";
export const STAFF_INVITE_FAILURE = "STAFF_INVITE_FAILURE";

export const DRIVER_INVITE_REQUEST = "DRIVER_INVITE_REQUEST";
export const DRIVER_INVITE_SUCCESS = "DRIVER_INVITE_SUCCESS";
export const DRIVER_INVITE_FAILURE = "DRIVER_INVITE_FAILURE";

export const PERMISSION_LIST_REQUEST = "PERMISSION_LIST_REQUEST";
export const PERMISSION_LIST_SUCCESS = "PERMISSION_LIST_SUCCESS";
export const PERMISSION_LIST_FAIL = "PERMISSION_LIST_FAIL";

export const UPDATE_STAFF_PROFILE_REQUEST = "UPDATE_STAFF_PROFILE_REQUEST";
export const UPDATE_STAFF_PROFILE_SUCCESS = "UPDATE_STAFF_PROFILE_SUCCESS";
export const UPDATE_STAFF_PROFILE_FAILURE = "UPDATE_STAFF_PROFILE_FAILURE";

export const UPDATE_DRIVER_PROFILE_REQUEST = "UPDATE_DRIVER_PROFILE_REQUEST";
export const UPDATE_DRIVER_PROFILE_SUCCESS = "UPDATE_DRIVER_PROFILE_SUCCESS";
export const UPDATE_DRIVER_PROFILE_FAILURE = "UPDATE_DRIVER_PROFILE_FAILURE";

export const FETCH_CONFIG_REQUEST = "FETCH_CONFIG_REQUEST";
export const FETCH_CONFIG_SUCCESS = "FETCH_CONFIG_SUCCESS";
export const FETCH_CONFIG_FAILURE = "FETCH_CONFIG_FAILURE";
