// Action Types
import {
  FETCH_BILLER_CATEGORIES_REQUEST,
  FETCH_BILLER_CATEGORIES_SUCCESS,
  FETCH_BILLER_CATEGORIES_FAILURE,
  FETCH_SINGLE_BILLER_CATEGORY_REQUEST,
  FETCH_SINGLE_BILLER_CATEGORY_SUCCESS,
  FETCH_SINGLE_BILLER_CATEGORY_FAILURE,
  GET_ALL_BILLERS_REQUEST,
  GET_ALL_BILLERS_SUCCESS,
  GET_ALL_BILLERS_FAILURE,
  GET_SINGLE_BILLER_REQUEST,
  GET_SINGLE_BILLER_SUCCESS,
  GET_SINGLE_BILLER_FAILURE,
} from "../constants/billerConstants";

const initialState = {
  loading: false,
  categories: [],
  currentPage: 1,
  totalPages: 1,
  billers: [],
  errorMessage: null,
  totalItems: 0,
  error: null,
  singleCategory: null,
  loadingSingleCategory: false,
  singleCategoryError: null,
  singleBillerLoading: false,
  singleBiller: null,
};

export const getBillersCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BILLER_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_BILLER_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload.categories,
        totalItems: action.payload.totalItems,
        error: null,
      };
    case FETCH_BILLER_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        categories: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getBillerCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    // Fetch all categories cases
    // case FETCH_BILLER_CATEGORIES_REQUEST:
    //   return {
    //     ...state,
    //     loadingCategories: true,
    //     error: null,
    //   };
    // case FETCH_BILLER_CATEGORIES_SUCCESS:
    //   return {
    //     ...state,
    //     loadingCategories: false,
    //     categories: action.payload.categories,
    //     totalItems: action.payload.totalItems,
    //     error: null,
    //   };
    // case FETCH_BILLER_CATEGORIES_FAILURE:
    //   return {
    //     ...state,
    //     loadingCategories: false,
    //     error: action.payload,
    //   };

    // Fetch single category cases
    case FETCH_SINGLE_BILLER_CATEGORY_REQUEST:
      return {
        ...state,
        loadingSingleCategory: true,
        singleCategoryError: null,
      };
    case FETCH_SINGLE_BILLER_CATEGORY_SUCCESS:
      return {
        ...state,
        loadingSingleCategory: false,
        singleCategory: action.payload,
        singleCategoryError: null,
      };
    case FETCH_SINGLE_BILLER_CATEGORY_FAILURE:
      return {
        ...state,
        loadingSingleCategory: false,
        singleCategoryError: action.payload,
      };

    default:
      return state;
  }
};

export const getAllBillersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BILLERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        errorMessage: null,
      };
    case GET_ALL_BILLERS_SUCCESS:
      return {
        ...state,
        loading: false,
        billers: action.payload.billers,
        totalItems: action.payload.totalItems,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        errorMessage:
          action.payload.billers.length === 0 ? "There are no biller(s)" : null,
      };
    case GET_ALL_BILLERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        billers: [],
      };
    default:
      return state;
  }
};

export const getSingleBillerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_BILLER_REQUEST:
      return {
        ...state,
        singleBillerLoading: true,
        error: null,
        errorMessage: null,
      };
    case GET_SINGLE_BILLER_SUCCESS:
      return {
        ...state,
        singleBillerLoading: false,
        singleBiller: action.payload,
        errorMessage: !action.payload ? "Biller not found" : null,
      };
    case GET_SINGLE_BILLER_FAILURE:
      return {
        ...state,
        singleBillerLoading: false,
        error: action.payload,
        singleBiller: null,
      };
    default:
      return state;
  }
};
