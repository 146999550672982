import axios from "axios";
import { toast } from "react-toastify";
import {
  SIGNIN_FAILURE,
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
} from "../constants/authConstants";

export const loginAdmin = (values) => async (dispatch) => {
  dispatch({
    type: SIGNIN_REQUEST,
    payload: { email: values.email, password: values.password },
  });
  try {
    const response = await axios.post(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_BACKEND_URL}/auth/login/native`,
      {
        email: values.email,
        password: values.password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({
      type: SIGNIN_SUCCESS,
      payload: response.data.data.user.userType,
    });
    if (response.data.status && response.data.code === 201) {
      localStorage.setItem(
        "user",
        JSON.stringify(response.data.data.user.userType)
      );
      localStorage.setItem(
        "token",
        JSON.stringify(response.data.data.token.access.token)
      );
      window.location.href = "/dashboard/home";
    }
  } catch (error) {
    // toast.error(error.message);
    const errorMessage =
      error.response?.data?.message || "An error occurred. Please try again.";
    toast.error(errorMessage);
    dispatch({
      type: SIGNIN_FAILURE,
      payload: errorMessage,
    });
  }
};
