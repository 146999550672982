import React, { createContext, useContext, useState } from "react";

// Create the context
const ModalContext = createContext();

// Custom hook for easy access to context
export const useModal = () => useContext(ModalContext);

// Provider component
// eslint-disable-next-line react/prop-types
export const ModalProvider = ({ children }) => {
  const [modalType, setModalType] = useState(null);
  const [modalData, setModalData] = useState(null);

  // Open a specific modal
  const openModal = (type, data = {}) => {
    setModalType(type);
    setModalData(data);
  };

  // Close the modal
  const closeModal = () => {
    setModalType(null);
    setModalData(null);
  };

  return (
    <ModalContext.Provider
      value={{ modalType, modalData, openModal, closeModal }}
    >
      {children}
    </ModalContext.Provider>
  );
};
