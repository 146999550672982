import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { singleWalletActions } from "../../../../../../redux/actions/userWalletActions";
import { performWalletOperation } from "../../../../../../redux/actions/walletRequestActions";

const WalletStatusModal = ({ onClose, isBlocked = false }) => {
  const { userId } = useParams();
  const { walletId } = useParams();
  const dispatch = useDispatch();
  const [hasInitiatedAction, setHasInitiatedAction] = useState(false);

  // Determine if this is a block or unblock operation
  const operation = isBlocked ? "open" : "block";
  const actionText = isBlocked ? "Unblock" : "Block";

  const { loading, success, error } = useSelector(
    (state) => state.createWalletOperation
  );

  // Reset state when component unmounts
  useEffect(() => {
    return () => {
      setHasInitiatedAction(false);
    };
  }, []);

  // Show success message when operation is successful
  useEffect(() => {
    // Only process if action has been initiated
    if (!hasInitiatedAction) return;

    if (success) {
      toast.success(
        `Wallet successfully ${isBlocked ? "unblocked" : "blocked"}!`
      );
      onClose();
      // Refresh wallet data
      dispatch(singleWalletActions(userId));
    }
    if (error) {
      toast.error(`Error: ${error}`);
      setHasInitiatedAction(false); // Reset so user can try again
    }
  }, [
    success,
    error,
    dispatch,
    userId,
    onClose,
    hasInitiatedAction,
    isBlocked,
  ]);

  const handleWalletStatusChange = () => {
    // Prepare data based on the API request format
    const operationData = {
      operation: operation,
      walletIds: [parseInt(walletId)],
    };

    setHasInitiatedAction(true);
    dispatch(performWalletOperation(operationData));
  };

  return (
    <div className="cancel-modal-container">
      <div className="block-wallet-modal">
        <div className="block-header">
          <h2>{actionText} User Wallet?</h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="block-body">
          {isBlocked ? (
            <p>
              User will be able to resume transactions with Wallet feature once
              unblocked. Proceed?
            </p>
          ) : (
            <p>
              User will not be able to perform transactions with Wallet feature
              once blocked. Proceed?
            </p>
          )}
          <button
            className={isBlocked ? "unblock-button" : "block-button"}
            onClick={handleWalletStatusChange}
            disabled={loading}
          >
            {!loading ? `${actionText} Wallet` : "Loading..."}
          </button>
        </div>
      </div>
    </div>
  );
};

WalletStatusModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isBlocked: PropTypes.bool,
};

export default WalletStatusModal;
