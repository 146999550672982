/* eslint-disable react/prop-types */
import { useQuery } from "@apollo/client";
import React from "react";
import { GET_TRANSACTIONS_ANALYTICS } from "../../../../../graphql/queries/payQueries";

const TopCard = ({ title, value }) => {
  return (
    <div className="top-card">
      <div className="top-card-content">
        <span className="top-card-title">{title}</span>
        <span className="top-card-value">{value}</span>
      </div>
    </div>
  );
};

const TopCardsContainer = ({ filter }) => {
  const { startDate, endDate } = filter;

  const { data, loading, error } = useQuery(GET_TRANSACTIONS_ANALYTICS, {
    variables: {
      startDate: startDate || "2024-01-01", // Provide defaults if filter is empty
      endDate: endDate || "2024-12-31",
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const { totalRevenue, totalTransactions } =
    data.getTransactionsOjanowPayAnalytics;

  const cardsData = [
    {
      title: "Total Revenue",
      value: `₦${totalRevenue.toLocaleString()}`,
      // Replace with dynamic calculation if needed
    },
    {
      title: "Total Transactions",
      value: totalTransactions.toLocaleString(),
      // Replace with dynamic calculation if needed
    },
  ];

  return (
    <div className="top-cards-container">
      {cardsData.map((card, index) => (
        <TopCard key={index} title={card.title} value={card.value} />
      ))}
    </div>
  );
};

export default TopCardsContainer;
