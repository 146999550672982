import {
  CREDIT_WALLET_REQUEST,
  CREDIT_WALLET_SUCCESS,
  CREDIT_WALLET_FAILURE,
  FETCH_ADMIN_WALLET_FAILURE,
  FETCH_ADMIN_WALLET_REQUEST,
  FETCH_ADMIN_WALLET_SUCCESS,
  FETCH_SINGLE_ADMIN_WALLET_TRANSACTION_FAILURE,
  FETCH_SINGLE_ADMIN_WALLET_TRANSACTION_REQUEST,
  FETCH_SINGLE_ADMIN_WALLET_TRANSACTION_SUCCESS,
  FETCH_ADMIN_WALLET_TRANSACTIONS_FAILURE,
  FETCH_ADMIN_WALLET_TRANSACTIONS_REQUEST,
  FETCH_ADMIN_WALLET_TRANSACTIONS_SUCCESS,
} from "../constants/adminWalletConstant";
import axios from "axios";

export const creditWallet =
  (amount, description, topUpBy, paymentMethod = "transfer") =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: CREDIT_WALLET_REQUEST });

      const { token } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      // Data for crediting wallet
      const data = {
        amount,
        description,
        topUpBy,
        paymentMethod,
      };

      // Make API call to initiate wallet funding
      const response = await axios.post(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_BACKEND_URL}/admin-wallet/fund`,
        data,
        config
      );

      // Check for success response and checkout URL
      if (response.data.status === true) {
        dispatch({
          type: CREDIT_WALLET_SUCCESS,
          payload: response.data,
        });

        // Return the response for further processing in the component
        return response.data;
      } else {
        throw new Error(response.data.message || "Failed to process payment");
      }
    } catch (error) {
      dispatch({
        type: CREDIT_WALLET_FAILURE,
        payload: error.response?.data?.message || "Failed to process payment",
      });
      throw error;
    }
  };

// Fixed function to return a function instead of being a function
export const fetchAdminWalletBalance = () => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_ADMIN_WALLET_REQUEST });

    const { token } = getState();

    const response = await axios.get(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_BACKEND_URL}/admin-wallet`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.status === true) {
      dispatch({
        type: FETCH_ADMIN_WALLET_SUCCESS,
        payload: response.data.data,
      });
    } else {
      throw new Error(
        response.data?.message || "Failed to fetch wallet balance"
      );
    }
  } catch (error) {
    dispatch({
      type: FETCH_ADMIN_WALLET_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchAdminWalletTransaction =
  (transactionId) => async (dispatch, getState) => {
    dispatch({ type: FETCH_SINGLE_ADMIN_WALLET_TRANSACTION_REQUEST });
    try {
      const { token } = getState();
      const response = await axios.get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_BACKEND_URL}/admin-wallet/logs/${transactionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === true) {
        dispatch({
          type: FETCH_SINGLE_ADMIN_WALLET_TRANSACTION_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_SINGLE_ADMIN_WALLET_TRANSACTION_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message || error.response?.data?.error?.[0],
      });
    }
  };

export const fetchWalletTransactions =
  (page = 1, limit = 10) =>
  async (dispatch, getState) => {
    dispatch({ type: FETCH_ADMIN_WALLET_TRANSACTIONS_REQUEST });
    try {
      const { token } = getState();
      const response = await axios.get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_BACKEND_URL}/admin-wallet/logs?page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === true) {
        dispatch({
          type: FETCH_ADMIN_WALLET_TRANSACTIONS_SUCCESS,
          payload: response.data.data,
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_ADMIN_WALLET_TRANSACTIONS_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message || error.response?.data?.error?.[0],
      });
    }
  };
