import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const GroupInputsModal = ({
  title,
  inputConfigs,
  onClose,
  onSubmit,
  buttonText,
  walletUsers,
  allowCloseModal = true, // if onSubmit another would be opened, pass false as the argument
}) => {
  // Initialize the state with each input's initial value or an empty string
  const [inputs, setInputs] = useState(
    inputConfigs.reduce((acc, config) => {
      acc[config.name] = config.initialValue || "";
      return acc;
    }, {})
  );

  // Add a state to track the total amount (amount * walletUsers)
  const [totalAmount, setTotalAmount] = useState(0);

  // Track if the form has been submitted
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Get loading states from redux
  const { loading: operationLoading } = useSelector(
    (state) => state.createWalletOperation || {}
  );
  const { loading: requestLoading } = useSelector(
    (state) => state.createWalletRequest || {}
  );

  // Combined loading state
  const isLoading = operationLoading || requestLoading;

  // Update the state for each input field
  const handleInputChange = (name, value) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));

    // If this is an amount field, calculate the total
    if (name === "amount") {
      const amount = parseFloat(value) || 0;
      setTotalAmount(amount * (walletUsers || 1));
    }
  };

  // Check if any input field is empty
  const isFormComplete = Object.values(inputs).every(
    (value) => value.trim() !== ""
  );

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    // Include the calculated total amount in the submission
    onSubmit({
      ...inputs,
      totalCalculatedAmount: totalAmount,
    });

    // Only auto-close if allowCloseModal is true
    if (allowCloseModal) {
      onClose();
    }
  };

  // Close the modal if submitted and not loading anymore
  useEffect(() => {
    if (isSubmitted && !isLoading && !allowCloseModal) {
      // This will be triggered when loading finishes after submission
      onClose();
    }
  }, [isSubmitted, isLoading, allowCloseModal, onClose]);

  // Calculate initial total if there's an amount field with initial value
  useEffect(() => {
    const amountConfig = inputConfigs.find(
      (config) => config.name === "amount"
    );
    if (amountConfig && amountConfig.initialValue) {
      const initialAmount = parseFloat(amountConfig.initialValue) || 0;
      setTotalAmount(initialAmount * (walletUsers || 1));
    }
  }, [inputConfigs, walletUsers]);

  return (
    <div className="cancel-modal-container" onClick={onClose}>
      <div
        className="funds-modal group-input"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="group-input-header">
          <h2>{title}</h2>
          <button
            className="close-button"
            onClick={onClose}
            disabled={isSubmitted && isLoading} // Disable close button when submitting
          >
            &times;
          </button>
        </div>
        <form onSubmit={handleSubmit} className="">
          <div
            className={
              "funds-body " +
              (inputConfigs.length == 2
                ? "group-text-area"
                : "group-no-text-area")
            }
          >
            {inputConfigs.map((config) => (
              <React.Fragment key={config.name}>
                {config.type === "textarea" ? (
                  <textarea
                    placeholder={config.placeholder}
                    value={inputs[config.name]}
                    onChange={(e) =>
                      handleInputChange(config.name, e.target.value)
                    }
                    className="input-field"
                    required
                    disabled={isSubmitted && isLoading}
                  ></textarea>
                ) : (
                  <div>
                    <input
                      type={config.type}
                      placeholder={config.placeholder}
                      value={inputs[config.name]}
                      onChange={(e) =>
                        handleInputChange(config.name, e.target.value)
                      }
                      className="input-field"
                      required
                      disabled={isSubmitted && isLoading}
                    />
                  </div>
                )}
              </React.Fragment>
            ))}
            <div className="wallet-info">
              {/* Display calculated total amount if there's an amount field */}
              {inputConfigs.some((config) => config.name === "amount") && (
                <p className="total-calculation">Total Amount: {totalAmount}</p>
              )}
            </div>
          </div>
          <button
            type="submit"
            className={`funds-button ${isLoading ? "loading" : ""}`}
            disabled={!isFormComplete || (isSubmitted && isLoading)}
          >
            {isSubmitted && isLoading ? "Processing..." : buttonText}
          </button>
        </form>
      </div>
    </div>
  );
};

GroupInputsModal.propTypes = {
  title: PropTypes.string.isRequired,
  inputConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["text", "textarea", "number"]).isRequired,
      placeholder: PropTypes.string,
      initialValue: PropTypes.string,
    })
  ).isRequired,
  walletUsers: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  allowCloseModal: PropTypes.bool,
};

export default GroupInputsModal;
