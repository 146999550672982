export const CREDIT_WALLET_REQUEST = "CREDIT_WALLET_REQUEST";
export const CREDIT_WALLET_SUCCESS = "CREDIT_WALLET_SUCCESS";
export const CREDIT_WALLET_FAILURE = "CREDIT_WALLET_FAILURE";
export const FETCH_ADMIN_WALLET_REQUEST = "FETCH_ADMIN_WALLET_REQUEST";
export const FETCH_ADMIN_WALLET_SUCCESS = "FETCH_ADMIN_WALLET_SUCCESS";
export const FETCH_ADMIN_WALLET_FAILURE = "FETCH_ADMIN_WALLET_FAILURE";
export const FETCH_SINGLE_ADMIN_WALLET_TRANSACTION_REQUEST =
  "FETCH_SINGLE_ADMIN_WALLET_TRANSACTION_REQUEST";
export const FETCH_SINGLE_ADMIN_WALLET_TRANSACTION_SUCCESS =
  "FETCH_SINGLE_ADMIN_WALLET_TRANSACTION_SUCCESS";
export const FETCH_SINGLE_ADMIN_WALLET_TRANSACTION_FAILURE =
  "FETCH_SINGLE_ADMIN_WALLET_TRANSACTION_FAILURE";
export const FETCH_ADMIN_WALLET_TRANSACTIONS_REQUEST =
  "FETCH_ADMIN_WALLET_TRANSACTIONS_REQUEST";
export const FETCH_ADMIN_WALLET_TRANSACTIONS_SUCCESS =
  "FETCH_ADMIN_WALLET_TRANSACTIONS_SUCCESS";
export const FETCH_ADMIN_WALLET_TRANSACTIONS_FAILURE =
  "FETCH_ADMIN_WALLET_TRANSACTIONS_FAILURE";
