import axios from "axios";
import { toast } from "react-toastify";
import {
  FETCH_CUSTOMER_WALLET_FAILURE,
  FETCH_CUSTOMER_WALLET_REQUEST,
  FETCH_CUSTOMER_WALLET_SUCCESS,
  FETCH_CUSTOMER_SINGLE_WALLET_FAILURE,
  FETCH_CUSTOMER_SINGLE_WALLET_REQUEST,
  FETCH_CUSTOMER_SINGLE_WALLET_SUCCESS,
} from "../constants/userWalletConstants";

export const fetchAllWallets =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: FETCH_CUSTOMER_WALLET_REQUEST });

    try {
      const { token } = getState();
      const { data } = await axios.get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_BACKEND_URL}/wallet/admin`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: FETCH_CUSTOMER_WALLET_SUCCESS,
        payload: {
          data: data.data.data,
          meta: data.data.meta,
        },
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while fetching wallets";

      dispatch({
        type: FETCH_CUSTOMER_WALLET_FAILURE,
        payload: errorMessage,
      });

      toast.error(errorMessage);
    }
  };

export const singleWalletActions =
  (customerId) => async (dispatch, getState) => {
    dispatch({ type: FETCH_CUSTOMER_SINGLE_WALLET_REQUEST });

    try {
      const { token } = getState();
      const data = await axios.get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_BACKEND_URL}/wallet/admin/${customerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: FETCH_CUSTOMER_SINGLE_WALLET_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_CUSTOMER_SINGLE_WALLET_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      toast.error("An error occurred");
    }
  };
