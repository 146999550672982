import React from "react";

import { useModal } from "../context/ModalContext";
import FundsModal from "../pages/dashboard/admin/wallet/users/components/FundsModal";
import WalletStatusModal from "../pages/dashboard/admin/wallet/users/components/WalletStatusModal";
import SuccessModal from "../pages/dashboard/admin/wallet/users/components/SuccessModal";
import GroupInputsModal from "../pages/dashboard/admin/wallet/groups/components/GroupInputModal";
import ActionModal from "../pages/dashboard/admin/wallet/components/ActionModal";
import HighDemandModal from "../pages/dashboard/admin/order/components/HighDemandModal";
import AddBiller from "../pages/dashboard/admin/pay/components/biller/AddBillerModal";
import AddBillerCategory from "../pages/dashboard/admin/pay/components/biller/AddBillerCategoryModal";
import FundAdminWalletModal from "../pages/dashboard/admin/wallet/admin-wallet/components/FundAdminWalletModal";

const GlobalModalHandler = () => {
  const { modalType, modalData, closeModal, openModal } = useModal();

  // Conditional rendering based on modalType
  return (
    <>
      {modalType === "Funds" && (
        <FundsModal
          type={modalData.type}
          initialAmount={modalData.type === "Debit" ? "" : ""}
          initialReason={
            modalData.type === "Debit"
              ? "user violated platform terms and conditions"
              : ""
          }
          onClose={closeModal}
          onSubmit={(data) => {
            // Add success or actual transaction handling here
            closeModal();
            openModal("Success", { amount: data.amount });
          }}
        />
      )}
      {modalType === "FundAdminWallet" && (
        <FundAdminWalletModal
          onClose={closeModal}
          onSubmit={(data) => {
            // Handle fund admin wallet submission
            console.log("Admin wallet funded:", data);
            closeModal();
            openModal("Success", {
              amount: data.amount,
              message: "Admin wallet funded successfully!",
            });
          }}
        />
      )}
      {modalType === "BlockWallet" && (
        <WalletStatusModal
          onClose={closeModal}
          isBlocked={modalData?.isBlocked || false}
        />
      )}
      {modalType === "Success" && (
        <SuccessModal
          amount={modalData.amount}
          message={modalData.message || `Transaction successful!`}
          onClose={closeModal}
        />
      )}
      {modalType === "GroupInput" && (
        <GroupInputsModal
          onClose={closeModal}
          inputConfigs={modalData.inputConfigs}
          title={modalData.title}
          buttonText={modalData.buttonText}
          onSubmit={modalData.onSubmit}
          walletUsers={modalData.walletUsers}
          allowCloseModal={modalData.allowCloseModal}
        />
      )}
      {modalType === "Action" && (
        <ActionModal
          title={modalData?.title}
          img={modalData?.img}
          onClose={closeModal}
          onSubmit={modalData?.onSubmit}
          buttonText={modalData?.buttonText}
          subTitle={modalData.subTitle}
          allowCloseModal={modalData.allowCloseModal}
        />
      )}
      {modalType === "HighDemand" && (
        <HighDemandModal
          onClose={closeModal}
          onSubmit={modalData?.onSubmit}
          isHighDemand={modalData.isHighDemand}
        />
      )}
      {modalType === "AddBiller" && (
        <AddBiller
          onClose={closeModal}
          modalData={modalData}
          refetchVariables={modalData?.refetchVariables}
        />
      )}
      {modalType === "AddBillerCategories" && (
        <AddBillerCategory onClose={closeModal} modalData={modalData} />
      )}
    </>
  );
};

export default GlobalModalHandler;
