import {
  ADD_ADDRESS_FAILURE,
  ADD_ADDRESS_REQUEST,
  ADD_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAILURE,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
  FETCH_ADDRESSES_REQUEST,
  FETCH_ADDRESSES_SUCCESS,
  FETCH_ADDRESSES_FAILURE,
} from "../constants/addressConstant";

const initialState = {
  loading: false,
  data: null,
  error: null,
  addresses: [],
};

export const addAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    case ADD_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const updateAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    case UPDATE_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const fetchAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADDRESSES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case FETCH_ADDRESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
        success: true,
      };
    case FETCH_ADDRESSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    default:
      return state;
  }
};
