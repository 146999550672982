import {
  FETCH_WALLET_REPORT_FAILURE,
  FETCH_WALLET_REPORT_REQUEST,
  FETCH_WALLET_REPORT_SUCCESS,
} from "../constants/reportConstants";

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: null,
};

export const fetchWalletReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WALLET_REPORT_REQUEST:
      return { ...state, loading: true, error: null, success: false };
    case FETCH_WALLET_REPORT_SUCCESS:
      return { ...state, loading: false, data: action.payload, success: true };
    case FETCH_WALLET_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    default:
      return state;
  }
};
