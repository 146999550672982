import {
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_FAILURE,
  FETCH_CUSTOMER_PROFILE_REQUEST,
  FETCH_CUSTOMER_PROFILE_SUCCESS,
  FETCH_CUSTOMER_PROFILE_FAILURE,
} from "../constants/customerConstants";

const initialState = {
  loading: false,
  customers: [],
  error: null,
  profile: {
    user: null,
    customer: null,
    wallet: null,
  },
};

export const fetchCustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: action.payload, // Storing the customer list
        error: null,
      };

    case FETCH_CUSTOMERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const singleCustomerProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_CUSTOMER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        loading: false,
        error: null,
      };

    case FETCH_CUSTOMER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};
