export const BANNER_UPLOAD_REQUEST = "BANNER_UPLOAD_REQUEST";
export const BANNER_UPLOAD_SUCCESS = "BANNER_UPLOAD_SUCCESS";
export const BANNER_UPLOAD_FAILURE = "BANNER_UPLOAD_FAILURE";

export const FETCH_BANNERS_REQUEST = "FETCH_BANNERS_REQUEST";
export const FETCH_BANNERS_SUCCESS = "FETCH_BANNERS_SUCCESS";
export const FETCH_BANNERS_FAILURE = "FETCH_BANNERS_FAILURE";

export const FETCH_BANNER_REQUEST = "FETCH_BANNER_REQUEST";
export const FETCH_BANNER_SUCCESS = "FETCH_BANNER_SUCCESS";
export const FETCH_BANNER_FAILURE = "FETCH_BANNER_FAILURE";
