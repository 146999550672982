export const GET_ALL_CATEGORIES_REQUEST = "GET_ALL_CATEGORIES_REQUEST";
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_FAILURE = "GET_ALL_CATEGORIES_FAILURE";
export const CATEGORY_ADD_REQUEST = "CATEGORY_ADD_REQUEST";
export const CATEGORY_ADD_SUCCESS = "CATEGORY_ADD_SUCCESS";
export const CATEGORY_ADD_FAIL = "CATEGORY_ADD_FAIL";
export const FETCH_UNPAGINATED_CATEGORY_REQUEST =
  "FETCH_UNPAGINATED_CATEGORY_REQUEST";
export const FETCH_UNPAGINATED_CATEGORY_SUCCESS =
  "FETCH_UNPAGINATED_CATEGORY_SUCCESS";
export const FETCH_UNPAGINATED_CATEGORY_FAILURE =
  "FETCH_UNPAGINATED_CATEGORY_FAILURE";
export const FETCH_CATEGORY_CHILDREN_REQUEST =
  "FETCH_CATEGORY_CHILDREN_REQUEST";
export const FETCH_CATEGORY_CHILDREN_SUCCESS =
  "FETCH_CATEGORY_CHILDREN_SUCCESS";
export const FETCH_CATEGORY_CHILDREN_FAILURE =
  "FETCH_CATEGORY_CHILDREN_FAILURE";
export const REARRANGE_CATEGORIES_REQUEST = "REARRANGE_CATEGORIES_REQUEST";
export const REARRANGE_CATEGORIES_SUCCESS = "REARRANGE_CATEGORIES_SUCCESS";
export const REARRANGE_CATEGORIES_FAILURE = "REARRANGE_CATEGORIES_FAILURE";
export const EDIT_CATEGORY_REQUEST = "EDIT_CATEGORY_REQUEST";
export const EDIT_CATEGORY_SUCCESS = "EDIT_CATEGORY_SUCCESS";
export const EDIT_CATEGORY_FAILURE = "EDIT_CATEGORY_FAILURE";
