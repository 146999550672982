export const CREATE_GROUP_REQUEST = "CREATE_GROUP_REQUEST";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAILURE = "CREATE_GROUP_FAILURE";
export const FETCH_WALLET_GROUPS_REQUEST = "FETCH_WALLET_GROUPS_REQUEST";
export const FETCH_WALLET_GROUPS_SUCCESS = "FETCH_WALLET_GROUPS_SUCCESS";
export const FETCH_WALLET_GROUPS_FAILURE = "FETCH_WALLET_GROUPS_FAILURE";
export const FETCH_WALLETS_IN_GROUP_REQUEST = "FETCH_WALLETS_IN_GROUP_REQUEST";
export const FETCH_WALLETS_IN_GROUP_SUCCESS = "FETCH_WALLETS_IN_GROUP_SUCCESS";
export const FETCH_WALLETS_IN_GROUP_FAILURE = "FETCH_WALLETS_IN_GROUP_FAILURE";
export const ADD_MEMBERS_TO_GROUP_REQUEST = "ADD_MEMBERS_TO_GROUP_REQUEST";
export const ADD_MEMBERS_TO_GROUP_SUCCESS = "ADD_MEMBERS_TO_GROUP_SUCCESS";
export const ADD_MEMBERS_TO_GROUP_FAILURE = "ADD_MEMBERS_TO_GROUP_FAILURE";
export const RESET_ADD_MEMBERS_TO_GROUP_STATUS =
  "RESET_ADD_MEMBERS_TO_GROUP_STATUS,";
export const FETCH_WALLETS_NOT_IN_GROUP_REQUEST =
  "FETCH_WALLETS_NOT_IN_GROUP_REQUEST";
export const FETCH_WALLETS_NOT_IN_GROUP_SUCCESS =
  "FETCH_WALLETS_NOT_IN_GROUP_SUCCESS";
export const FETCH_WALLETS_NOT_IN_GROUP_FAILURE =
  "FETCH_WALLETS_NOT_IN_GROUP_FAILURE";
export const REMOVE_WALLET_FROM_GROUP_REQUEST =
  "REMOVE_WALLET_FROM_GROUP_REQUEST";
export const REMOVE_WALLET_FROM_GROUP_SUCCESS =
  "REMOVE_WALLET_FROM_GROUP_SUCCESS";
export const REMOVE_WALLET_FROM_GROUP_FAILURE =
  "REMOVE_WALLET_FROM_GROUP_FAILURE";
