import {
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  FETCH_AVAILABLE_DRIVERS_REQUEST,
  FETCH_AVAILABLE_DRIVERS_SUCCESS,
  FETCH_AVAILABLE_DRIVERS_FAILURE,
  ASSIGN_DRIVER_TO_ORDER_REQUEST,
  ASSIGN_DRIVER_TO_ORDER_SUCCESS,
  ASSIGN_DRIVER_TO_ORDER_FAILURE,
  CANCEL_ORDER_FAILURE,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
} from "../constants/orderConstants";

const initialState = {
  loading: false,
  product: null,
  error: null,
  orders: [],
  totalItems: 0,
  order: null,
  drivers: [],
  canceledOrders: [],
  lastCanceledOrder: null,
};

export const orderListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return { ...state, loading: true };
    case ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload.data,
        totalItems: action.payload.meta.totalItems,
      };
    case ORDER_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const orderDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ORDER_DETAILS_SUCCESS:
      return { loading: false, order: action.payload, error: null };
    case ORDER_DETAILS_FAIL:
      return { loading: false, order: null, error: action.payload };
    default:
      return state;
  }
};

export const fetchAvailableDriverReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AVAILABLE_DRIVERS_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_AVAILABLE_DRIVERS_SUCCESS:
      return { ...state, loading: false, drivers: action.payload };

    case FETCH_AVAILABLE_DRIVERS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const assignDriverToOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASSIGN_DRIVER_TO_ORDER_REQUEST:
      return { loading: true };

    case ASSIGN_DRIVER_TO_ORDER_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload,
        error: null,
      };

    case ASSIGN_DRIVER_TO_ORDER_FAILURE:
      return { loading: false, success: false, error: action.payload };

    default:
      return state;
  }
};

export const cancelOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case CANCEL_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        lastCanceledOrder: action.payload,
      };

    case CANCEL_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
