import {
  FETCH_TRANSACTION_COMMISSION_FAILURE,
  FETCH_TRANSACTION_COMMISSION_REQUEST,
  FETCH_TRANSACTION_COMMISSION_SUCCESS,
  FETCH_BILLER_CATEGORY_COMMISSIONS_FAILURE,
  FETCH_BILLER_CATEGORY_COMMISSIONS_REQUEST,
  FETCH_BILLER_CATEGORY_COMMISSIONS_SUCCESS,
  FETCH_SINGLE_BILLER_COMMISSION_REQUEST,
  FETCH_SINGLE_BILLER_COMMISSION_SUCCESS,
  FETCH_SINGLE_BILLER_COMMISSION_FAILURE,
  UPDATE_SINGLE_BILLER_COMMISSION_REQUEST,
  UPDATE_SINGLE_BILLER_COMMISSION_SUCCESS,
  UPDATE_SINGLE_BILLER_COMMISSION_FAILURE,
  CREATE_BILLER_CATEGORY_COMMISSION_SUCCESS,
  CREATE_BILLER_CATEGORY_COMMISSION_REQUEST,
  CREATE_BILLER_CATEGORY_COMMISSION_FAILURE,
  DELETE_BILLER_CATEGORY_COMMISSION_FAILURE,
  DELETE_BILLER_CATEGORY_COMMISSION_REQUEST,
  DELETE_BILLER_CATEGORY_COMMISSION_SUCCESS,
  CALCULATE_TRANSACTION_COMMISSION_FAILURE,
  CALCULATE_TRANSACTION_COMMISSION_REQUEST,
  CALCULATE_TRANSACTION_COMMISSION_SUCCESS,
  GET_BILLER_CATEGORY_COMMISSION_FAILURE,
  GET_BILLER_CATEGORY_COMMISSION_REQUEST,
  GET_BILLER_CATEGORY_COMMISSION_SUCCESS,
} from "../constants/commissionConstant";

const initialState = {
  loading: false,
  transactions: [],
  error: null,
  commissions: [],
  commission: null,
};

export const fetchTransactionCommissionReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_TRANSACTION_COMMISSION_REQUEST:
      return { ...state, loading: true };

    case FETCH_TRANSACTION_COMMISSION_SUCCESS:
      return { loading: false, transactions: action.payload, error: null };

    case FETCH_TRANSACTION_COMMISSION_FAILURE:
      return { loading: false, transactions: [], error: action.payload };

    default:
      return state;
  }
};

export const fetchBillerCategoryCommissionReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_BILLER_CATEGORY_COMMISSIONS_REQUEST:
      return { ...state, loading: true };
    case FETCH_BILLER_CATEGORY_COMMISSIONS_SUCCESS:
      return { loading: false, commissions: action.payload, error: null };
    case FETCH_BILLER_CATEGORY_COMMISSIONS_FAILURE:
      return { loading: false, commissions: [], error: action.payload };
    default:
      return state;
  }
};

export const fetchSingleBillerCommissionReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_SINGLE_BILLER_COMMISSION_REQUEST:
      return { ...state, loading: true };
    case FETCH_SINGLE_BILLER_COMMISSION_SUCCESS:
      return { loading: false, commissions: action.payload, error: null };
    case FETCH_SINGLE_BILLER_COMMISSION_FAILURE:
      return { loading: false, commissions: [], error: action.payload };
    default:
      return state;
  }
};

export const updateSingleBillerCommissionReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UPDATE_SINGLE_BILLER_COMMISSION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case UPDATE_SINGLE_BILLER_COMMISSION_SUCCESS:
      return {
        ...state,
        commission: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_SINGLE_BILLER_COMMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const createBillerCategoryCommissionReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CREATE_BILLER_CATEGORY_COMMISSION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case CREATE_BILLER_CATEGORY_COMMISSION_SUCCESS:
      return {
        ...state,
        commission: action.payload,
        loading: false,
        error: null,
      };
    case CREATE_BILLER_CATEGORY_COMMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const deleteBillerCategoryCommissionReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case DELETE_BILLER_CATEGORY_COMMISSION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        message: null,
      };

    case DELETE_BILLER_CATEGORY_COMMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };

    case DELETE_BILLER_CATEGORY_COMMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export const calculatedCommissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case CALCULATE_TRANSACTION_COMMISSION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        lastRequestData: action.payload,
      };

    case CALCULATE_TRANSACTION_COMMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        commissionAmount: action.payload,
        error: null,
      };

    case CALCULATE_TRANSACTION_COMMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export const getBillerCategoryCommissionReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_BILLER_CATEGORY_COMMISSION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_BILLER_CATEGORY_COMMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        commissionData: action.payload,
        error: null,
      };

    case GET_BILLER_CATEGORY_COMMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        commissions: [],
        error: action.payload,
      };

    default:
      return state;
  }
};
