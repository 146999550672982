import React from "react";
import PropTypes from "prop-types";

const SuccessModal = ({ amount, message, onClose }) => {
  return (
    <div className="cancel-modal-container">
      <div className="success-modal">
        <div className="success-header">
          <h2>Success</h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="success-body">
          <img
            src="path_to_placeholder_image" // Replace with an actual placeholder image path
            alt="Success"
            className="success-icon"
          />
          <div className="success-amount">{amount}</div>
          <p className="success-message">{message}</p>
        </div>
      </div>
    </div>
  );
};

SuccessModal.propTypes = {
  amount: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SuccessModal;
