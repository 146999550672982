import {
  FETCH_CUSTOMER_WALLET_FAILURE,
  FETCH_CUSTOMER_WALLET_REQUEST,
  FETCH_CUSTOMER_WALLET_SUCCESS,
  FETCH_CUSTOMER_SINGLE_WALLET_FAILURE,
  FETCH_CUSTOMER_SINGLE_WALLET_REQUEST,
  FETCH_CUSTOMER_SINGLE_WALLET_SUCCESS,
} from "../constants/userWalletConstants";

const initialState = {
  walletData: {
    data: [],
    totalItems: 0,
  },
  loading: false,
  error: null,
  singleWalletData: null,
};

// Reducer
export const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_WALLET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_CUSTOMER_WALLET_SUCCESS:
      return {
        ...state,
        walletData: action.payload,
        loading: false,
        error: null,
      };

    case FETCH_CUSTOMER_WALLET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const userSingleWalletReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_SINGLE_WALLET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_CUSTOMER_SINGLE_WALLET_SUCCESS:
      return {
        ...state,
        singleWalletData: action.payload,
        loading: false,
        error: null,
      };

    case FETCH_CUSTOMER_SINGLE_WALLET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
