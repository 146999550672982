import React from "react";
import { useQuery } from "@apollo/client";

import PropTypes from "prop-types";
import { GET_TRANSACTION_BILLER_CATEGORY_REVENUE_ANALYTICS } from "../../../../../../graphql/queries/payQueries";

const RevenueCategory = ({ startDate, endDate, transactionId }) => {
  const { data, loading, error } = useQuery(
    GET_TRANSACTION_BILLER_CATEGORY_REVENUE_ANALYTICS,
    {
      variables: {
        input: { startDate, endDate },
        transactionId,
      },
    }
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const revenueData = data.getTransactionBillerCategoryRevenueAnalytics.data;
  if (!revenueData || revenueData.length === 0) {
    return <div className>No data available for the selected period.</div>;
  }
  return (
    <div className="revenue-category">
      <table className="revenue-table">
        <thead>
          <tr>
            <th>Category</th>
            <th>Revenue</th>
            <th>No. Of Transactions</th>
          </tr>
        </thead>
        <tbody>
          {revenueData.map((item, index) => (
            <tr key={index}>
              <td>{item.billerName}</td>
              <td>{`₦${item.totalRevenue.toLocaleString()}`}</td>
              <td>{item.totalTransactions}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
RevenueCategory.propTypes = {
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  transactionId: PropTypes.any,
};
export default RevenueCategory;
