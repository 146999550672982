import React from "react";
import { Link } from "react-router-dom";
import { GET_ALL_BILLERS_CATEGORIES } from "../../../../../graphql/queries/payQueries";
import { useQuery } from "@apollo/client";

const SalesCards = () => {
  const { data, loading, error } = useQuery(GET_ALL_BILLERS_CATEGORIES);
  // const categories = [
  //   "Airtime",
  //   "Mobile Data",
  //   "International Airtime",
  //   "Electricity",
  //   "Cable TV",
  //   "Internet",
  //   "Gift Cards",
  //   "Education",
  //   "Betting",
  //   "E-Sims",
  // ];
  console.log(data);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // Extract categories from the query result
  const categories = data?.getAllBillerCategories?.data || [];

  return (
    <div className="sales-cards-container">
      {categories.length > 0 ? (
        categories.map((category) => (
          <div className="sales-card" key={category.id}>
            <div className="sales-card-top">
              <Link to={`/dashboard/reports/${category.id}`}>
                <span className="sales-card-title">{category.name}</span>
              </Link>
            </div>
            <img
              src={category.logoUrl}
              alt=""
              style={{ width: "10%", marginTop: "auto" }}
            />
          </div>
        ))
      ) : (
        <p>No categories available.</p>
      )}
    </div>
  );
};

export default SalesCards;
