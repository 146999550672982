import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

const RequestsHeader = ({ title }) => {
  const navigate = useNavigate();
  return (
    <div className="">
      <div className="order-details-action-btn request-nav-btn">
        <button onClick={() => navigate(-1)}>
          <img src="../../../../svgs/chevron-left.svg" alt="" />
        </button>
        <button disabled>
          <img src="../../../../svgs/chevron-right.svg" alt="" />
        </button>
      </div>
      <h2 className="requests-header">{title}</h2>
    </div>
  );
};

RequestsHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default RequestsHeader;
