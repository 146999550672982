import {
  FETCH_WALLET_TRANSACTIONS_FAILURE,
  FETCH_WALLET_TRANSACTIONS_REQUEST,
  FETCH_WALLET_TRANSACTIONS_SUCCESS,
  WALLET_REQUEST_APPROVE_DECLINE_REQUEST,
  WALLET_REQUEST_APPROVE_DECLINE_SUCCESS,
  WALLET_REQUEST_APPROVE_DECLINE_FAILURE,
  WALLET_OPERATION_FAILURE,
  WALLET_OPERATION_REQUEST,
  WALLET_OPERATION_SUCCESS,
  FETCH_PENDING_USER_REQUESTS_FAILURE,
  FETCH_PENDING_USER_REQUESTS_REQUEST,
  FETCH_PENDING_USER_REQUESTS_SUCCESS,
  FETCH_PENDING_GROUP_WALLET_REQUESTS_FAILURE,
  FETCH_PENDING_GROUP_WALLET_REQUESTS_REQUEST,
  FETCH_PENDING_GROUP_WALLET_REQUESTS_SUCCESS,
  FETCH_WALLET_REQUEST_COMPLETED_FAILURE,
  FETCH_WALLET_REQUEST_COMPLETED_REQUEST,
  FETCH_WALLET_REQUEST_COMPLETED_SUCCESS,
  FETCH_SINGLE_WALLET_REQUEST_REQUEST,
  CREATE_WALLET_REQUEST_FAILURE,
  CREATE_WALLET_REQUEST_REQUEST,
  CREATE_WALLET_REQUEST_SUCCESS,
  FETCH_SINGLE_WALLET_REQUEST_SUCCESS,
  FETCH_SINGLE_WALLET_REQUEST_FAILURE,
} from "../constants/walletRequestConstant";

const initialState = {
  transactions: [],
  loading: false,
  error: null,
  pendingActions: {},
  completedActions: {},
  message: null,
  data: [],
};

// Reducer
export const fetchWalletTransactionsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_WALLET_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_WALLET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: action.payload,
        error: null,
      };

    case FETCH_WALLET_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const walletApproveOrDeclineRequestReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case WALLET_REQUEST_APPROVE_DECLINE_REQUEST:
      return { ...state, loading: true, success: false, error: null };

    case WALLET_REQUEST_APPROVE_DECLINE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        data: action.payload,
      };

    case WALLET_REQUEST_APPROVE_DECLINE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const createWalletOperationReducer = (state = initialState, action) => {
  switch (action.type) {
    case WALLET_OPERATION_REQUEST:
      return { ...state, loading: true, success: false };

    case WALLET_OPERATION_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload,
        error: null,
      };

    case WALLET_OPERATION_FAILURE:
      return {
        loading: false,
        success: false,
        message: "",
        error: action.payload,
      };

    default:
      return state;
  }
};

export const pendingUserRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PENDING_USER_REQUESTS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };

    case FETCH_PENDING_USER_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload, // Store response data directly
        error: null,
      };

    case FETCH_PENDING_USER_REQUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        data: [],
      };

    default:
      return state;
  }
};

export const fetchPendingGroupWalletRequestsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_PENDING_GROUP_WALLET_REQUESTS_REQUEST:
      return { ...state, loading: true, error: null, success: false };
    case FETCH_PENDING_GROUP_WALLET_REQUESTS_SUCCESS:
      return { ...state, loading: false, data: action.payload, success: true };
    case FETCH_PENDING_GROUP_WALLET_REQUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    default:
      return state;
  }
};

export const fetchCompletedWalletRequestReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_WALLET_REQUEST_COMPLETED_REQUEST:
      return { ...state, loading: true, error: null, success: false };

    case FETCH_WALLET_REQUEST_COMPLETED_SUCCESS:
      return { ...state, loading: false, data: action.payload, success: true };

    case FETCH_WALLET_REQUEST_COMPLETED_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payloads,
        success: false,
      };

    default:
      return state;
  }
};
export const fetchSingleWalletRequestReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_SINGLE_WALLET_REQUEST_REQUEST:
      return { ...state, loading: true, error: null, success: false };

    case FETCH_SINGLE_WALLET_REQUEST_SUCCESS: // Fixed constant name
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
        success: true,
      };

    case FETCH_SINGLE_WALLET_REQUEST_FAILURE: // Fixed constant name
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };

    default:
      return state;
  }
};

export const createWalletRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_WALLET_REQUEST_REQUEST:
      return { ...state, loading: true, success: false };
    case CREATE_WALLET_REQUEST_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case CREATE_WALLET_REQUEST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
