/* eslint-disable no-undef */
import axios from "axios";
import { toast } from "react-toastify";
import {
  GET_CUSTOMER_TRANSACTIONS_REQUEST,
  GET_CUSTOMER_TRANSACTIONS_SUCCESS,
  GET_CUSTOMER_TRANSACTIONS_FAILURE,
  GET_ALL_TRANSACTIONS_REQUEST,
  GET_ALL_TRANSACTIONS_SUCCESS,
  GET_ALL_TRANSACTIONS_FAILURE,
  FETCH_OJANOWPAY_ANALYTICS_REQUEST,
  FETCH_OJANOWPAY_ANALYTICS_SUCCESS,
  FETCH_OJANOWPAY_ANALYTICS_FAILURE,
  FETCH_BILLER_CATEGORY_ANALYTICS_REQUEST,
  FETCH_BILLER_CATEGORY_ANALYTICS_SUCCESS,
  FETCH_BILLER_CATEGORY_ANALYTICS_FAILURE,
  FETCH_TRANSACTION_FAILURE,
  FETCH_TRANSACTION_SUCCESS,
  FETCH_TRANSACTION_REQUEST,
} from "../constants/transactionConstants.js";

export const getCustomerTransactions =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: GET_CUSTOMER_TRANSACTIONS_REQUEST });

    const { token } = getState();

    // Default parameters
    const {
      page = 1,
      limit = 10,
      minPrice = "",
      maxPrice = "",
      serviceType = "",
      transactionStatus = "",
      reference = "",
      channel = "",
      biller = "",
      customerId = "",
    } = params;

    try {
      // Build query parameters
      const queryParams = new URLSearchParams();
      queryParams.append("page", page);
      queryParams.append("limit", limit);

      if (minPrice) queryParams.append("minPrice", minPrice);
      if (maxPrice) queryParams.append("maxPrice", maxPrice);
      if (serviceType) queryParams.append("serviceType", serviceType);
      if (transactionStatus)
        queryParams.append("transactionStatus", transactionStatus);
      if (reference) queryParams.append("reference", reference);
      if (channel) queryParams.append("channel", channel);
      if (biller) queryParams.append("biller", biller);
      if (customerId) queryParams.append("customerId", customerId);

      const response = await axios.get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/transaction/customer?${queryParams.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status) {
        const { data } = response.data;

        dispatch({
          type: GET_CUSTOMER_TRANSACTIONS_SUCCESS,
          payload: {
            transactions: data.data || [],
            totalItems: data.meta?.totalItems || 0,
            currentPage: data.meta?.currentPage || 1,
            totalPages: data.meta?.totalPages || 1,
          },
        });
      } else {
        dispatch({
          type: GET_CUSTOMER_TRANSACTIONS_FAILURE,
          payload: response.data.message || "Failed to fetch transactions",
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";

      dispatch({
        type: GET_CUSTOMER_TRANSACTIONS_FAILURE,
        payload: errorMessage,
      });
    }
  };

export const getAllTransactions =
  (
    page = 1,
    limit = 10,
    minPrice = null,
    maxPrice = null,
    transactionStatus = null,
    reference = null,
    channel = null,
    biller = null,
    startDate = null,
    endDate = null,
    customerId = null
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: GET_ALL_TRANSACTIONS_REQUEST });

    const { token } = getState();

    try {
      // Build params object, only including non-null values
      const params = {
        page,
        limit,
        ...(minPrice && { minPrice }),
        ...(maxPrice && { maxPrice }),
        ...(transactionStatus && { transactionStatus }),
        ...(reference && { reference }),
        ...(channel && { channel }),
        ...(biller && { biller }),
        ...(startDate && { startDate }),
        ...(endDate && { endDate }),
        ...(customerId && { customerId }),
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/transaction`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params,
        }
      );

      if (response.data.status) {
        dispatch({
          type: GET_ALL_TRANSACTIONS_SUCCESS,
          payload: {
            transactions: response.data.data.data || [],
            totalItems: response.data.data.meta.totalItems,
            totalPages: response.data.data.meta.totalPages,
            currentPage: response.data.data.meta.currentPage,
          },
        });
      } else {
        dispatch({
          type: GET_ALL_TRANSACTIONS_FAILURE,
          payload: "Failed to fetch transactions",
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";
      toast.error(errorMessage);
      dispatch({
        type: GET_ALL_TRANSACTIONS_FAILURE,
        payload: errorMessage,
      });
    }
  };

export const fetchOjanowpayAnalytics =
  (startDate, endDate) => async (dispatch, getState) => {
    dispatch({ type: FETCH_OJANOWPAY_ANALYTICS_REQUEST });

    const { token } = getState();

    try {
      const response = await axios.get(
        `https://staging-new.ojanowapp.com/transaction/analytics/oja-now-pay`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: {
            startDate,
            endDate,
          },
        }
      );

      if (response.data.success) {
        dispatch({
          type: FETCH_OJANOWPAY_ANALYTICS_SUCCESS,
          payload: {
            totalTransactions: response.data.data.totalTransactions || 0,
            totalRevenue: response.data.data.totalRevenue || 0,
          },
        });
      } else {
        dispatch({
          type: FETCH_OJANOWPAY_ANALYTICS_FAILURE,
          payload: "Failed to fetch analytics",
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";
      toast.error(errorMessage);
      dispatch({
        type: FETCH_OJANOWPAY_ANALYTICS_FAILURE,
        payload: errorMessage,
      });
    }
  };

export const fetchBillerCategoryAnalytics =
  (billerCategoryId, startDate, endDate) => async (dispatch, getState) => {
    dispatch({ type: FETCH_BILLER_CATEGORY_ANALYTICS_REQUEST });

    const { token } = getState();

    try {
      const response = await axios.get(
        `https://staging-new.ojanowapp.com/transaction/analytics/biller-category/${billerCategoryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: {
            startDate,
            endDate,
          },
        }
      );

      if (response.data.success) {
        dispatch({
          type: FETCH_BILLER_CATEGORY_ANALYTICS_SUCCESS,
          payload: {
            totalTransactions: response.data.data.totalTransactions || 0,
            totalRevenue: response.data.data.totalRevenue || 0,
            failedTransactions: response.data.data.failedTransactions || 0,
          },
        });
      } else {
        dispatch({
          type: FETCH_BILLER_CATEGORY_ANALYTICS_FAILURE,
          payload: "Failed to fetch biller category analytics",
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";
      toast.error(errorMessage);
      dispatch({
        type: FETCH_BILLER_CATEGORY_ANALYTICS_FAILURE,
        payload: errorMessage,
      });
    }
  };

export const fetchSingleTransaction =
  (transactionId) => async (dispatch, getState) => {
    dispatch({ type: FETCH_TRANSACTION_REQUEST });

    const { token } = getState();

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/transaction/${transactionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status) {
        dispatch({
          type: FETCH_TRANSACTION_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: FETCH_TRANSACTION_FAILURE,
          payload: "Failed to fetch transaction details",
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";

      dispatch({
        type: FETCH_TRANSACTION_FAILURE,
        payload: errorMessage,
      });
    }
  };
