export const GET_CUSTOMER_TRANSACTIONS_REQUEST =
  "GET_CUSTOMER_TRANSACTIONS_REQUEST";
export const GET_CUSTOMER_TRANSACTIONS_SUCCESS =
  "GET_CUSTOMER_TRANSACTIONS_SUCCESS";
export const GET_CUSTOMER_TRANSACTIONS_FAILURE =
  "GET_CUSTOMER_TRANSACTIONS_FAILURE";
export const GET_ALL_TRANSACTIONS_REQUEST = "GET_ALL_TRANSACTIONS_REQUEST";
export const GET_ALL_TRANSACTIONS_SUCCESS = "GET_ALL_TRANSACTIONS_SUCCESS";
export const GET_ALL_TRANSACTIONS_FAILURE = "GET_ALL_TRANSACTIONS_FAILURE";

export const FETCH_OJANOWPAY_ANALYTICS_REQUEST =
  "FETCH_OJANOWPAY_ANALYTICS_REQUEST";
export const FETCH_OJANOWPAY_ANALYTICS_SUCCESS =
  "FETCH_OJANOWPAY_ANALYTICS_SUCCESS";
export const FETCH_OJANOWPAY_ANALYTICS_FAILURE =
  "FETCH_OJANOWPAY_ANALYTICS_FAILURE";

export const FETCH_BILLER_CATEGORY_ANALYTICS_REQUEST =
  "FETCH_BILLER_CATEGORY_ANALYTICS_REQUEST";
export const FETCH_BILLER_CATEGORY_ANALYTICS_SUCCESS =
  "FETCH_BILLER_CATEGORY_ANALYTICS_SUCCESS";
export const FETCH_BILLER_CATEGORY_ANALYTICS_FAILURE =
  "FETCH_BILLER_CATEGORY_ANALYTICS_FAILURE";

export const FETCH_TRANSACTION_REQUEST = "FETCH_TRANSACTION_REQUEST";
export const FETCH_TRANSACTION_SUCCESS = "FETCH_TRANSACTION_SUCCESS";
export const FETCH_TRANSACTION_FAILURE = "FETCH_TRANSACTION_FAILURE";
