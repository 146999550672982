import { gql } from "apollo-boost";

export const CREATE_BILLER = gql`
  mutation createBiller(
    $name: String!
    $description: String!
    $operatorId: String!
    $billerCategoryId: String!
    $file: Upload!
  ) {
    createBiller(
      input: {
        name: $name
        description: $description
        operatorId: $operatorId
        billerCategoryId: $billerCategoryId
        file: $file
      }
    ) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_BILLER_CATEGORY = gql`
  mutation createBillerCategory(
    $name: String!
    $description: String!
    $categoryId: String!
    $file: Upload!
  ) {
    createBillerCategory(
      input: {
        name: $name
        description: $description
        categoryId: $categoryId
        file: $file
      }
    ) {
      id
      name
      description
      logoUrl
      slug
      createdAt
      updatedAt
    }
  }
`;
export const UPDATE_BILLER_CATEGORY = gql`
  mutation updateBillerCategory(
    $id: String!
    $name: String
    $description: String
    $categoryId: String
    $file: Upload
  ) {
    updateBillerCategory(
      input: {
        id: $id
        name: $name
        description: $description
        categoryId: $categoryId
        file: $file
      }
    ) {
      id
      name
      description
      logoUrl
      slug
      createdAt
      updatedAt
    }
  }
`;
export const DELETE_BILLER_CATEGORY = gql`
  mutation deleteBillerCategoryById($id: String!) {
    deleteBillerCategoryById(id: $id)
  }
`;
export const DELETE_BILLER = gql`
  mutation deleteBillerId($id: String!) {
    deleteBillerId(id: $id)
  }
`;
export const UPDATE_BILLER = gql`
  mutation updateBiller(
    $id: String!
    $name: String
    $description: String
    $operatorId: String
    $billerCategoryId: String
    $file: Upload
  ) {
    updateBiller(
      input: {
        id: $id
        name: $name
        description: $description
        operatorId: $operatorId
        billerCategoryId: $billerCategoryId
        file: $file
      }
    ) {
      id
      name
      description
      logoUrl
      operatorId
      createdAt
      updatedAt
    }
  }
`;
export const UPDATE_BILLER_CATEGORY_STATUS = gql`
  mutation updateBillerCategoryStatus($id: String!, $status: String!) {
    updateBillerCategoryStatus(input: { id: $id, status: $status }) {
      id
      name
      status
      description
      logoUrl
      categoryId
      createdAt
      updatedAt
    }
  }
`;
export const UPDATE_BILLER_STATUS = gql`
  mutation updateBillerStatus($id: String!, $status: String!) {
    updateBillerStatus(input: { id: $id, status: $status }) {
      id
      name
      description
      logoUrl
      operatorId
      status
      createdAt
      updatedAt
    }
  }
`;
export const CREATE_COMMISSION_BILLER_CATEGORY = gql`
  mutation createCommissionBillerCategory(
    $billerCategoryId: String!
    $commissionType: String!
    $value: String!
    $capValue: String!
  ) {
    createCommissionBillerCategory(
      input: {
        billerCategoryId: $billerCategoryId
        commissionType: $commissionType
        value: $value
        capValue: $capValue
      }
    ) {
      id
      billerCategoryId
      commissionType
      value
      capValue
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_BILLER_CATEGORY_COMMISSION = gql`
  mutation updateBillerCategoryCommission(
    $id: String!
    $commissionType: String
    $value: String
    $capValue: String
  ) {
    updateBillerCategoryCommission(
      id: $id
      input: {
        commissionType: $commissionType
        value: $value
        capValue: $capValue
      }
    ) {
      id
      billerCategoryId
      commissionType
      value
      capValue
    }
  }
`;
