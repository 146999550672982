import { gql } from "apollo-boost";

export const GET_ALL_BILLERS_CATEGORIES = gql`
  query {
    getAllBillerCategories(input: { page: 1, limit: 20 }) {
      data {
        id
        name
        slug
        description
        logoUrl
        status
        categoryId
      }
      total
    }
  }
`;
export const GET_SOPHITEL_OPERATORS = gql`
  query GetSochitelOperators($input: GetSochitelOperatorsInput) {
    getSochitelOperators(input: $input) {
      data {
        name
        operatorId
      }
    }
  }
`;
export const GET_ALL_BILLERS = gql`
  query GetAllBillers(
    $page: Int
    $limit: Int
    $order: String
    $searchQuery: String
    $billerCategoryId: String
  ) {
    getAllBillers(
      input: {
        page: $page
        limit: $limit
        order: $order
        searchQuery: $searchQuery
        billerCategoryId: $billerCategoryId
      }
    ) {
      data {
        id
        name
        description
        createdAt
        logoUrl
        status
        operatorId
        updatedAt
      }
      total
    }
  }
`;
export const GET_ALL_TRANSACTIONS = gql`
  query GetAllTransactions($input: FetchAllTransactionsInput) {
    getAllTransactions(input: $input) {
      data {
        id
        reference
        recipient
        channel
        description
        type
        status
        service
        amount
        createdAt
        updatedAt
        billerCategoryName
        customer {
          firstName
          lastName
        }
      }
      total
    }
  }
`;
export const FETCH_ALL_TRANSACTION_COMMISSIONS = gql`
  query fetchAllTransactionCommissions(
    $input: FetchAllTransactionCommissionInput
  ) {
    getFetchAllTransactionCommission(input: $input) {
      total
      totalAmount
      commissions {
        id
        transactionId
        billerCategoryCommissionId
        commissionAmount
        status
        billerName
        billerCategoryName
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_TRANSACTIONS_ANALYTICS = gql`
  query GetTransactionsAnalytics($startDate: String!, $endDate: String!) {
    getTransactionsOjanowPayAnalytics(
      input: { startDate: $startDate, endDate: $endDate }
    ) {
      totalTransactions
      totalRevenue
    }
  }
`;
export const GET_BILLER_CATEGORY_ANALYTICS = gql`
  query GetBillerCategoryAnalytics(
    $input: FetchTransactionAnalyticsInput!
    $id: String!
  ) {
    getTransactionBillerCategoryAnalytics(input: $input, id: $id) {
      totalTransactions
      totalRevenue
      failedTransactions
    }
  }
`;
export const GET_BILLER_CATEGORY_BY_ID = gql`
  query GetBillerCategoryById($id: String!) {
    getBillerCategoryById(id: $id) {
      id
      name
      slug
      description
      logoUrl
      status
      categoryId
      createdAt
      updatedAt
    }
  }
`;

export const GET_TRANSACTION_BILLER_CATEGORY_REVENUE_ANALYTICS = gql`
  query GetTransactionBillerCategoryRevenueAnalytics(
    $input: FetchTransactionAnalyticsInput!
    $transactionId: String!
  ) {
    getTransactionBillerCategoryRevenueAnalytics(
      input: $input
      transactionId: $transactionId
    ) {
      data {
        billerName
        totalRevenue
        totalTransactions
      }
    }
  }
`;
export const GET_TRANSACTION_DETAILS = gql`
  query GetTransactionDetails($id: String!) {
    getTransactionById(id: $id) {
      id
      reference
      recipient
      channel
      description
      type
      status
      service
      amount
      createdAt
      updatedAt
      billerCategoryName

      customer {
        id
        firstName
        lastName
        phoneNumber
        emailAddress
        addresses {
          id
          streetLine1
          streetLine2
          city
          province
          postalCode
          country {
            code
            name
            enabled
          }
          phoneNumber
          defaultShippingAddress
          defaultBillingAddress
        }
      }
    }
  }
`;

export const FETCH_BILLER_CATEGORY_COMMISSION = gql`
  query fetchBillerCategoryCommission($billerCategoryId: String!) {
    getFetchBillerCategoryCommissionByBillerCategoryId(
      billerCategoryId: $billerCategoryId
    ) {
      id
      billerCategoryId
      commissionType
      value
      capValue
    }
  }
`;
