export const FETCH_BILLER_CATEGORIES_REQUEST =
  "FETCH_BILLER_CATEGORIES_REQUEST";
export const FETCH_BILLER_CATEGORIES_SUCCESS =
  "FETCH_BILLER_CATEGORIES_SUCCESS";
export const FETCH_BILLER_CATEGORIES_FAILURE =
  "FETCH_BILLER_CATEGORIES_FAILURE";
export const FETCH_SINGLE_BILLER_CATEGORY_REQUEST =
  "FETCH_SINGLE_BILLER_CATEGORY_REQUEST";
export const FETCH_SINGLE_BILLER_CATEGORY_SUCCESS =
  "FETCH_SINGLE_BILLER_CATEGORY_SUCCESS";
export const FETCH_SINGLE_BILLER_CATEGORY_FAILURE =
  "FETCH_SINGLE_BILLER_CATEGORY_FAILURE";
export const GET_ALL_BILLERS_REQUEST = "GET_ALL_BILLERS_REQUEST";
export const GET_ALL_BILLERS_SUCCESS = "GET_ALL_BILLERS_SUCCESS";
export const GET_ALL_BILLERS_FAILURE = "GET_ALL_BILLERS_FAILURE";
export const GET_SINGLE_BILLER_REQUEST = "GET_SINGLE_BILLER_REQUEST";
export const GET_SINGLE_BILLER_SUCCESS = "GET_SINGLE_BILLER_SUCCESS";
export const GET_SINGLE_BILLER_FAILURE = "GET_SINGLE_BILLER_FAILURE";
