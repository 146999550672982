import React, { useState } from "react";
import PropTypes from "prop-types";
import CheckoutHandler from "../components/CheckoutHandler";

const FundAdminWalletModal = ({ onClose, refreshData }) => {
  const [amount, setAmount] = useState("");
  const [topUpBy, setTopUpBy] = useState("");
  const [reason, setReason] = useState("Wallet top-up");
  const [method, setMethod] = useState("Bank Transfer");
  const [loading, setLoading] = useState(false);

  // Initialize the checkout handler
  const { processCheckout, iframeComponent, isProcessing, error, closeIframe } =
    CheckoutHandler({
      onClose,
      refreshData,
    });

  // Parse amount to remove currency symbol and commas
  const parseAmount = (amountStr) => {
    return parseFloat(amountStr.replace(/[₦,]/g, ""));
  };

  const handleSubmit = () => {
    if (!amount || !topUpBy) return;

    setLoading(true);

    // Prepare data for the API
    const checkoutData = {
      amount: parseAmount(amount),
      description: reason,
      topUpBy: topUpBy,
      paymentMethod: method.toLowerCase().replace(" ", "_"),
    };

    console.log(checkoutData);
    // Process the checkout
    processCheckout(checkoutData);
  };
  const handleCloseModal = () => {
    // If iframe is showing, close it
    closeIframe();
    // Then close the main modal
    onClose();
  };

  return (
    <div className="cancel-modal-container">
      {/* Render the iframe component if checkout is in progress */}
      {iframeComponent}

      {/* Only show the fund modal if iframe is not showing */}
      {!iframeComponent && (
        <div className="funds-modal">
          <div className="funds-header">
            <h2>Fund wallet</h2>
            <button className="close-button" onClick={handleCloseModal}>
              &times;
            </button>
          </div>
          <div className="funds-body">
            {error && <div className="fund-error-message">{error}</div>}

            <div className="fund-input-group">
              <label className="fund-input-label">Amount to top-up</label>
              <input
                type="text"
                placeholder="₦100,000"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="fund-input"
              />
            </div>

            <div className="fund-input-group">
              <label className="fund-input-label">Top-up by</label>
              <div className="fund-select-container">
                <select
                  value={topUpBy}
                  onChange={(e) => setTopUpBy(e.target.value)}
                  className="fund-select"
                >
                  <option value="" disabled>
                    Name
                  </option>
                  <option value="Jamal Kasumu">Jamal Kasumu</option>
                  <option value="Demilade Haastrup">Demilade Haastrup</option>
                  <option value="Emmanuel Joda">Emmanuel Joda</option>
                  <option value="Esther Iyanu">Esther Iyanu</option>
                </select>
                <div className="fund-select-arrow">
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1.5L6 6.5L11 1.5"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div className="fund-input-group">
              <label className="fund-input-label">Reason</label>
              <input
                type="text"
                placeholder="Reason for top-up"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                className="fund-input"
              />
            </div>

            <div className="fund-input-group">
              <label className="fund-input-label">Method to fund wallet</label>
              <div className="fund-radio-option">
                <label className="fund-radio-container">
                  <input
                    type="radio"
                    name="method"
                    value="Bank Transfer"
                    checked={method === "Bank Transfer"}
                    onChange={() => setMethod("Bank Transfer")}
                    className="fund-radio-input"
                  />
                  <span className="fund-radio-custom"></span>
                  <span className="fund-radio-label-text">Bank Transfer</span>
                </label>
              </div>
            </div>

            <button
              className="fund-proceed-button"
              onClick={handleSubmit}
              disabled={
                !amount || !topUpBy || !reason || loading || isProcessing
              }
            >
              {loading || isProcessing ? "Processing..." : "Proceed"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

FundAdminWalletModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
};

export default FundAdminWalletModal;
