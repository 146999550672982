import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const SidebarItem = ({ item, userType }) => {
  const [open, setOpen] = useState(false);
  const hasAccess = (itemRole) => {
    if (!userType) return false;
    if (userType === "ADMIN") return true;
    if (!itemRole) return true;

    // Handle admin_only restriction
    if (itemRole.includes("admin_only") && userType !== "ADMIN") {
      return false;
    }

    // For staff, check if they have any of the required permissions
    if (userType === "STAFF") {
      return true; // Show all non-admin-only routes to staff
    }

    return false;
  };

  const navLinkStyles = ({ isActive }) => {
    return {
      background: isActive ? "#6E449B" : "",
      color: isActive ? "#fff" : "",
      fill: isActive ? "#fff" : "",
    };
  };

  if (item.subRoutes) {
    return (
      <div>
        <div className={open ? "sidebar-item open" : "sidebar-item"}>
          <div className="sidebar-title">
            <img src={item.icon} alt="" style={{ color: "white" }} />
            <span>{item.name}</span>
            <i className="toggle-btn" onClick={() => setOpen(!open)}>
              <img src="../../../svgs/down-arrow.svg" alt="down-arrow" />
            </i>
          </div>
        </div>
        <div className="sidebar-content">
          {item.subRoutes.map((child) =>
            hasAccess(child.role) ? (
              <SidebarItem key={child.id} item={child} userType={userType} />
            ) : null
          )}
        </div>
      </div>
    );
  } else {
    return (
      <NavLink
        style={navLinkStyles}
        to={item.path}
        className={({ isActive }) =>
          `sidebar-item plain ${isActive ? "active" : ""}`
        }
      >
        <img src={item.icon} alt="" className="img-fluid" />
        <span>{item.name}</span>
      </NavLink>
    );
  }
};

SidebarItem.propTypes = {
  item: PropTypes.any,
  userType: PropTypes.any,
};

export default SidebarItem;
