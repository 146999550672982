import {
  GET_ALL_COUPON_CODES_REQUEST,
  GET_ALL_COUPON_CODES_SUCCESS,
  GET_ALL_COUPON_CODES_FAILURE,
  GET_SINGLE_COUPON_CODE_REQUEST,
  GET_SINGLE_COUPON_CODE_SUCCESS,
  GET_SINGLE_COUPON_CODE_FAILURE,
  COUPON_ADD_REQUEST,
  COUPON_ADD_SUCCESS,
  COUPON_ADD_FAIL,
  UPDATE_COUPON_REQUEST,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAILURE,
  DELETE_COUPON_REQUEST,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAILURE,
  COUPON_ADD_RESET,
  DELETE_COUPON_RESET,
} from "../constants/couponConstants";

// Initial state for all coupon codes
const initialState = {
  loading: false,
  coupons: [],
  coupon: null,
  totalItems: 0,
  error: null,
  success: false,
  errorMessage: null,
  message: "",
};

// Reducer for getting all coupon codes
export const getAllCouponCodesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COUPON_CODES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        errorMessage: null,
      };
    case GET_ALL_COUPON_CODES_SUCCESS:
      return {
        ...state,
        loading: false,
        coupons: action.payload.coupons,
        totalItems: action.payload.totalItems,
      };
    case GET_ALL_COUPON_CODES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        coupons: [],
      };
    default:
      return state;
  }
};

export const getSingleCouponCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_COUPON_CODE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        errorMessage: null,
      };
    case GET_SINGLE_COUPON_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        coupon: action.payload,
        errorMessage: !action.payload ? "Coupon code not found" : null,
      };
    case GET_SINGLE_COUPON_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        coupon: null,
      };
    default:
      return state;
  }
};

export const addCouponReducer = (state = {}, action) => {
  switch (action.type) {
    case COUPON_ADD_REQUEST:
      return { loading: true };
    case COUPON_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
        coupon: action.payload.data,
      };
    case COUPON_ADD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case COUPON_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const updateCouponReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COUPON_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case UPDATE_COUPON_SUCCESS:
      return {
        loading: false,
        success: true,
        coupon: action.payload,
        error: null,
      };
    case UPDATE_COUPON_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const deleteCouponReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_COUPON_REQUEST:
      return { ...state, loading: true, success: false, error: null };
    case DELETE_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: action.payload,
      };
    case DELETE_COUPON_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case DELETE_COUPON_RESET:
      return { ...initialState }; // Reset to initial state
    default:
      return state;
  }
};
