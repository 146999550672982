import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleTransaction } from "../../../../redux/actions/transactionActions";
import RequestsHeader from "../wallet/requests/components/RequestsHeader";

const PayTransactionsDetails = () => {
  const { transactionId } = useParams();
  const dispatch = useDispatch();
  const { transaction, loading, error } = useSelector(
    (state) => state.getSingleTransaction
  );

  useEffect(() => {
    if (transactionId) {
      dispatch(fetchSingleTransaction(transactionId));
    }
  }, [dispatch, transactionId]);

  // Format currency
  const formatCurrency = (amount) => {
    if (amount === null || amount === undefined) return "N/A";

    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 2,
    }).format(amount);
  };

  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";

    try {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } catch (error) {
      return "Invalid Date";
    }
  };

  // Format time
  const formatTime = (dateString) => {
    if (!dateString) return "N/A";

    try {
      const date = new Date(dateString);
      return date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    } catch (error) {
      return "Invalid Time";
    }
  };

  if (loading) {
    return (
      <div className="user-request-details text-center py-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <p className="mt-3">Loading transaction details...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="user-request-details">
        <div className="user-request-header">
          <RequestsHeader />
        </div>
        <div className="alert alert-danger my-4 p-4 text-center">
          <h5>Error Loading Transaction</h5>
          <p>{error}</p>
          <button
            className="btn btn-outline-danger mt-2"
            onClick={() => dispatch(fetchSingleTransaction(transactionId))}
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  if (!transaction) {
    return (
      <div className="user-request-details">
        <div className="user-request-header">
          <RequestsHeader />
        </div>
        <div className="alert alert-warning my-4 p-4 text-center">
          <h5>Transaction Not Found</h5>
          <p>The requested transaction could not be found.</p>
        </div>
      </div>
    );
  }

  // Get transaction date and time
  const transactionDate = formatDate(transaction.createdAt);
  const transactionTime = formatTime(transaction.createdAt);

  return (
    <div className="user-request-details">
      <div className="user-request-header">
        <RequestsHeader />
      </div>
      <div className="user-details-container pay-transaction-details-container">
        <div className="user-details-card">
          <div className="user-request-details-container">
            <h2 className="user-details-section-title">Transaction Details</h2>
            <div className="user-details-info-grid">
              <div className="user-details-info-item">
                <span className="user-details-label">Transaction ID</span>
                <span className="user-details-value">
                  {transaction.reference || transaction.id}
                </span>
              </div>
              <div className="user-details-info-item">
                <span className="user-details-label">Date</span>
                <span className="user-details-value">{transactionDate}</span>
              </div>
              <div className="user-details-info-item">
                <span className="user-details-label">Time</span>
                <span className="user-details-value">{transactionTime}</span>
              </div>
              <div className="user-details-info-item">
                <span className="user-details-label">Customer ID</span>
                <span className="user-details-value">
                  {transaction.customerId || "N/A"}
                </span>
              </div>
            </div>

            <h2 className="user-details-section-title">
              Transaction Information
            </h2>
            <div className="user-details-info-grid">
              <div className="user-details-info-item">
                <span className="user-details-label">Recipient</span>
                <span className="user-details-value">
                  {transaction.recipient || "N/A"}
                </span>
              </div>
              <div className="user-details-info-item">
                <span className="user-details-label">Service</span>
                <span className="user-details-value">
                  {transaction.service || "N/A"}
                </span>
              </div>
              <div className="user-details-info-item">
                <span className="user-details-label">Description</span>
                <span className="user-details-value">
                  {transaction.description || "N/A"}
                </span>
              </div>
              {transaction.msisdn && (
                <div className="user-details-info-item">
                  <span className="user-details-label">MSISDN</span>
                  <span className="user-details-value">
                    {transaction.msisdn}
                  </span>
                </div>
              )}
              {transaction.productId && (
                <div className="user-details-info-item">
                  <span className="user-details-label">Product ID</span>
                  <span className="user-details-value">
                    {transaction.productId}
                  </span>
                </div>
              )}
            </div>

            {transaction.billerCategory && (
              <>
                <h2 className="user-details-section-title">
                  Biller Information
                </h2>
                <div className="user-details-info-grid">
                  <div className="user-details-info-item">
                    <span className="user-details-label">Biller Category</span>
                    <span className="user-details-value">
                      {transaction.billerCategory.name || "N/A"}
                    </span>
                  </div>
                  <div className="user-details-info-item">
                    <span className="user-details-label">Description</span>
                    <span className="user-details-value">
                      {transaction.billerCategory.description || "N/A"}
                    </span>
                  </div>
                  <div className="user-details-info-item">
                    <span className="user-details-label">Category ID</span>
                    <span className="user-details-value">
                      {transaction.billerCategoryId || "N/A"}
                    </span>
                  </div>
                  <div className="user-details-info-item">
                    <span className="user-details-label">Biller ID</span>
                    <span className="user-details-value">
                      {transaction.billerId || "N/A"}
                    </span>
                  </div>
                </div>
              </>
            )}

            <h2 className="user-details-section-title user-pay-details-section-title">
              Payment Details
            </h2>
            <div className="user-details-info-grid">
              <div className="user-details-info-item">
                <span className="user-details-label">Channel</span>
                <span className="user-details-value">
                  {transaction.channel || "N/A"}
                </span>
              </div>
              <div className="user-details-info-item">
                <span className="user-details-label">Amount</span>
                <span className="user-details-value">
                  {formatCurrency(transaction.amount)}
                </span>
              </div>
              <div className="user-details-info-item">
                <span className="user-details-label">Status</span>
                <span
                  className={`user-details-value status-${transaction.status?.toLowerCase()}`}
                >
                  {transaction.status || "N/A"}
                </span>
              </div>
              <div className="user-details-info-item">
                <span className="user-details-label">Created At</span>
                <span className="user-details-value">
                  {formatDate(transaction.createdAt)}{" "}
                  {formatTime(transaction.createdAt)}
                </span>
              </div>
              <div className="user-details-info-item">
                <span className="user-details-label">Updated At</span>
                <span className="user-details-value">
                  {formatDate(transaction.updatedAt)}{" "}
                  {formatTime(transaction.updatedAt)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayTransactionsDetails;
