import React, { useEffect, useState } from "react";
// import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import * as Yup from "yup";
// import { useNavigate } from "react-router-dom";
// import { useMutation } from "@apollo/react-hooks";

import { AuthLayout } from "../../layouts";
import { TextField, Button, ButtonSpinner } from "../../components";

import { useDispatch, useSelector } from "react-redux";
import { loginAdmin } from "../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import { LOGIN_ADMIN } from "../../graphql/queries/authQueries";

const Login = () => {
  // React hooks
  // const navigate = useNavigate();
  const navigate = useNavigate();
  // const [errors, setErrors] = useState({});
  const userInform = useSelector((state) => state.userInformation);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { loading } = userInform;
  // Function to toggle show password
  const togglePassword = () => setShow(!show);
  const Authed = useSelector((state) => state.token);
  const getUser = () => {
    if (Authed != null) {
      navigate("/dashboard/home");
    }
  };
  useEffect(() => {
    getUser();
  }, []);
  // Formik initial values
  const initialValues = {
    email: "",
    password: "",
  };

  // Formik validation schema
  const validate = Yup.object({
    email: Yup.string().required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  // useEffect(() => {}, []);

  // Graphql queries and fuction to login admin
  // const [loginAdmin, { loading }] = useMutation(LOGIN_ADMIN, {
  //   update(_, result) {
  //     // Security check and navigation
  //     if (result.data.login.__typename === “InvalidCredentialsError”) {
  //       toast.error(“The provided credentials are invalid”);
  //     }
  //     if (result.data.login.__typename === “CurrentUser”) {
  //       dispatch({ type: “LOGIN”, payload: result.data.login.identifier });

  //       navigate(“/dashboard/home”);
  //       toast.success(“Login successful”);
  //     }
  //   },
  //   onError({ graphQLErrors }) {
  //     setErrors(graphQLErrors);

  //     if (errors) {
  //       toast.error(“An error occured!“);
  //     }
  //   },
  //   // Set values for backend
  //   variables: initialValues,
  // });
  // const loginAdmin = async (values) => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.post(
  //       "https://staging-new.ojanowapp.com/v1/auth/login/native",
  //       {
  //         email: values.email,
  //         password: values.password,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (response.data.status && response.data.code === 201) {
  //       // Store the access token in localStorage
  //       localStorage.setItem("token", response.data.data.token.access.token);

  //       // Dispatch the userType to context
  //       dispatch({
  //         type: "LOGIN",
  //         payload: response.data.data.user.userType,
  //       });

  //       // You can add navigation here
  //       window.location = "/dashboard/home";

  //       // You can add success toast here
  //       toast.success("Login successful");
  //     } else {
  //       // Handle error case
  //       // toast.error("The provided credentials are invalid");
  //     }
  //   } catch (error) {
  //     console.error("Login error:", error);
  //     // Handle specific error cases
  //     if (error.response) {
  //       // The request was made and the server responded with a status code
  //       // that falls out of the range of 2xx
  //       toast.error(error.response.data.message || "Authentication failed");
  //     } else if (error.request) {
  //       // The request was made but no response was received
  //       toast.error("No response from server");
  //     } else {
  //       // Something happened in setting up the request that triggered an Error
  //       toast.error("An error occurred!");
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // Fucntion to handle submit button
  const handleSubmit = (values) => {
    dispatch(loginAdmin(values, navigate));
  };
  return (
    <>
      <AuthLayout src="../../../images/auth1.png" alt="auth_image">
        <h1 className="mb-4 title">Welcome back! Please login</h1>
        <div className="login-form">
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={(values) => handleSubmit(values)}
          >
            {() => (
              <Form>
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                />
                <TextField
                  label="Password"
                  name="password"
                  type={show ? "text" : "password"}
                  placeholder="Enter your password"
                  passwordEye={true}
                  togglePassword={togglePassword}
                />

                <div className="d-flex justify-content-between">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customControlInline"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customControlInline"
                    >
                      Stay logged in
                    </label>
                  </div>

                  {/* <Link to="/password-reset">
                    <p className="oja-forgot-pwd-a">Reset password</p>
                  </Link> */}
                </div>

                <div>
                  <Button
                    disabled={loading}
                    title={loading ? <ButtonSpinner /> : "Login"}
                    className={"login-btn"}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </AuthLayout>
    </>
  );
};

export default Login;
