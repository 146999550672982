import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllTransactions } from "../../../../redux/actions/transactionActions";
import { Header, Pagination, ButtonSpinner } from "../../../../components";
import TransactionList from "./components/transaction/TransactionList";

const headersData = [
  { name: "TRANSACTION ID" },
  { name: "DATE" },
  { name: "TIME" },
  { name: "RECIPIENT" },
  { name: "TRANSACTION TYPE" },
  { name: "AMOUNT" },
  { name: "STATUS" },
  { name: "PAYMENT TYPE" },
];

const PayTransactions = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const ITEM_PER_PAGE = 7;

  const dispatch = useDispatch();
  const { loading, error, transactions, totalItems, errorMessage } =
    useSelector((state) => state.transactions);

  // Fetch transactions when component mounts or when page changes
  const fetchTransactions = useCallback(() => {
    dispatch(
      getAllTransactions(
        currentPage,
        ITEM_PER_PAGE,
        null, // minPrice
        null, // maxPrice
        null, // transactionStatus
        null, // reference
        null, // channel
        null, // biller
        null, // startDate
        null, // endDate
        null // customerId
      )
    );
  }, [dispatch, currentPage, ITEM_PER_PAGE]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  console.log(transactions);
  return (
    <div className="pay-transactions-container">
      <h3 className="report-title">OjaNow Pay</h3>

      <div className="re-ordered-search-container">
        {/* Search functionality can be added here if needed */}
      </div>

      <div
        className="table-responsive-sm"
        style={{ height: "600px", overflow: "auto" }}
      >
        <table className="custom-table">
          <Header headers={headersData} />
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={8} className="text-center py-4">
                  <ButtonSpinner />
                  <p className="mt-2">Loading transactions...</p>
                </td>
              </tr>
            ) : error ? (
              <tr>
                <td colSpan={8} className="text-center py-4">
                  <div className="alert alert-danger">
                    <p>Error: {error}</p>
                    <button
                      className="btn btn-sm btn-outline-danger mt-2"
                      onClick={fetchTransactions}
                    >
                      Try Again
                    </button>
                  </div>
                </td>
              </tr>
            ) : transactions?.length === 0 ? (
              <tr>
                <td colSpan={8} className="text-center py-4">
                  <div className="text-muted">
                    <i className="fas fa-receipt fa-3x mb-3"></i>
                    <p>{errorMessage || "There are no transaction(s)"}</p>
                  </div>
                </td>
              </tr>
            ) : (
              transactions?.map((value, index) => (
                <TransactionList key={`${value.id}-${index}`} value={value} />
              ))
            )}
          </tbody>
        </table>

        {/* Only show pagination when we have data and we're not loading */}
        {!loading && !error && transactions?.length > 0 && (
          <div className="mt-3 pagination-right">
            <Pagination
              itemsCount={totalItems}
              itemsPerPage={ITEM_PER_PAGE}
              currentPage={currentPage}
              setCurrentPage={handlePageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PayTransactions;
