import { useQuery } from "@apollo/client";
import React, { useMemo, useState } from "react";
import { GET_ALL_TRANSACTIONS } from "../../../../../../graphql/queries/payQueries";

const Orders = () => {
  const [search] = useState("");
  const [sorting] = useState({ field: "", order: "" });
  const [currentPage] = useState(1);
  const ITEM_PER_PAGE = 6;
  const { data, loading, error } = useQuery(GET_ALL_TRANSACTIONS, {
    variables: {
      input: {
        page: currentPage,
        limit: ITEM_PER_PAGE,
        serviceType: "oja_now_pay",
      },
    },
  });
  const transactions = data?.getAllTransactions?.data || [];
  const totalItems = data?.getAllTransactions?.total || 0;
  console.log(totalItems);
  const reOrderedData = useMemo(() => {
    let filteredData = transactions;

    if (search) {
      filteredData = filteredData.filter(
        (transaction) =>
          transaction.amount?.toString().includes(search) ||
          `${transaction.customer?.firstName} ${transaction.customer?.lastName}`
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          transaction.description
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          transaction.status?.toLowerCase().includes(search.toLowerCase()) ||
          transaction.type?.toLowerCase().includes(search.toLowerCase()) ||
          transaction.id?.toLowerCase().includes(search.toLowerCase())
      );
    }

    // Sorting logic
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      filteredData = filteredData
        .slice()
        .sort(
          (a, b) => reversed * a[sorting.field]?.localeCompare(b[sorting.field])
        );
    }

    return filteredData.slice(
      (currentPage - 1) * ITEM_PER_PAGE,
      (currentPage - 1) * ITEM_PER_PAGE + ITEM_PER_PAGE
    );
  }, [transactions, search, sorting, currentPage]);
  if (loading) return <p>Loading transactions...</p>;
  if (error) return <p>Error fetching transactions: {error.message}</p>;
  const formatDateTime = (createdAt) => {
    const dateObj = new Date(createdAt);
    const date = dateObj.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const time = dateObj.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return { date, time };
  };

  return (
    <div className="orders-section">
      <h2 className="orders-title">Orders</h2>
      <table className="orders-table">
        <thead>
          <tr>
            <th>TRANSACTION ID</th>
            <th>DATE</th>
            <th>TIME</th>
            <th>CUSTOMER NAME</th>
            <th>TRANSACTION TYPE</th>
            <th>AMOUNT</th>
            <th>STATUS</th>
            <th>PAYMENT TYPE</th>
          </tr>
        </thead>
        <tbody>
          {reOrderedData.map((order) => {
            const { date, time } = formatDateTime(order.createdAt);
            const customerName = order.customer
              ? `${order.customer.firstName || ""} ${
                  order.customer.lastName || ""
                }`.trim()
              : "N/A";
            return (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td>{date}</td>
                <td>{time}</td>
                <td>{customerName}</td>
                <td>{order.type}</td>
                <td>{order.amount}</td>
                <td
                  className={
                    order.status === "Successful"
                      ? "status-success"
                      : "status-failed"
                  }
                >
                  {order.status}
                </td>
                <td>{order.paymentType}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {reOrderedData?.length === 0 && (
        <div className="mt-5 text-center">
          <p>There are no transaction(s)</p>
        </div>
      )}
    </div>
  );
};

export default Orders;
