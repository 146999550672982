import PropTypes from "prop-types";
import React from "react";
import EllipsisSelector from "./EllipsisSelector";

const userActions = [
  {
    label: "View",
    onClick: (id) => `${id}`, // Uses the unique `id` to generate the URL
  },
];

const formatDateTime = (createdAt) => {
  if (!createdAt) return { date: "N/A", time: "N/A" };

  try {
    const dateObj = new Date(createdAt);
    const date = dateObj.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const time = dateObj.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return { date, time };
  } catch (error) {
    console.error("Date formatting error:", error);
    return { date: "Invalid Date", time: "Invalid Time" };
  }
};

// Format amount to currency
const formatAmount = (amount) => {
  if (amount === null || amount === undefined) return "N/A";

  return new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: 2,
  }).format(amount);
};

// Get status class based on transaction status
const getStatusClass = (status) => {
  switch (status?.toUpperCase()) {
    case "COMPLETED":
      return "success-status";
    case "PENDING":
      return "pending-status";
    case "FAILED":
      return "failed-status";
    default:
      return "";
  }
};

const TransactionList = ({ value }) => {
  // Extract date and time from createdAt
  const { date, time } = formatDateTime(value.createdAt);

  // Handle customer name - using customerId or recipient as fallback
  const customerName = (() => {
    if (value.customer) {
      return `${value.customer.firstName || ""} ${
        value.customer.lastName || ""
      }`.trim();
    }

    // If no customer object, try other properties based on API response
    if (value.recipient) return value.recipient;
    if (value.customerId) return `Customer #${value.customerId}`;

    return "N/A";
  })();

  // Get transaction type from service or type property
  const transactionType = value.service || value.type || "N/A";

  // Get payment type from channel or paymentType property
  const paymentType = value.channel || value.paymentType || "N/A";

  // Get transaction ID from reference or id
  const transactionId = value.reference || value.id;

  return (
    <tr key={value.id}>
      <td>{transactionId}</td>
      <td>{date}</td>
      <td>{time}</td>
      <td>{customerName}</td>
      <td>{transactionType}</td>
      <td>{formatAmount(value.amount)}</td>
      <td>
        <span className={`status-badge ${getStatusClass(value.status)}`}>
          {value.status}
        </span>
      </td>
      <td>{paymentType}</td>
      <td>
        <EllipsisSelector name={value.id} actions={userActions} />
      </td>
    </tr>
  );
};

TransactionList.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    reference: PropTypes.string,
    createdAt: PropTypes.string,
    customer: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    recipient: PropTypes.string,
    customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    service: PropTypes.string,
    type: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    status: PropTypes.string,
    channel: PropTypes.string,
    paymentType: PropTypes.string,
    description: PropTypes.string,
    billerCategory: PropTypes.object,
  }).isRequired,
};

export default TransactionList;
