// Action Types for getting all coupon codes
export const GET_ALL_COUPON_CODES_REQUEST = "GET_ALL_COUPON_CODES_REQUEST";
export const GET_ALL_COUPON_CODES_SUCCESS = "GET_ALL_COUPON_CODES_SUCCESS";
export const GET_ALL_COUPON_CODES_FAILURE = "GET_ALL_COUPON_CODES_FAILURE";

// Action Types for getting a single coupon code
export const GET_SINGLE_COUPON_CODE_REQUEST = "GET_SINGLE_COUPON_CODE_REQUEST";
export const GET_SINGLE_COUPON_CODE_SUCCESS = "GET_SINGLE_COUPON_CODE_SUCCESS";
export const GET_SINGLE_COUPON_CODE_FAILURE = "GET_SINGLE_COUPON_CODE_FAILURE";

export const COUPON_ADD_REQUEST = "COUPON_ADD_REQUEST";
export const COUPON_ADD_SUCCESS = "COUPON_ADD_SUCCESS";
export const COUPON_ADD_FAIL = "COUPON_ADD_FAIL";
export const COUPON_ADD_RESET = "COUPON_ADD_RESET";

export const UPDATE_COUPON_REQUEST = "UPDATE_COUPON_REQUEST";
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS";
export const UPDATE_COUPON_FAILURE = "UPDATE_COUPON_FAILURE";

export const DELETE_COUPON_REQUEST = "DELETE_COUPON_REQUEST";
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS";
export const DELETE_COUPON_FAILURE = "DELETE_COUPON_FAILURE";
export const DELETE_COUPON_RESET = "DELETE_COUPON_RESET";
