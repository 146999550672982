export const FETCH_WALLET_TRANSACTIONS_REQUEST =
  "FETCH_WALLET_TRANSACTIONS_REQUEST";
export const FETCH_WALLET_TRANSACTIONS_SUCCESS =
  "FETCH_WALLET_TRANSACTIONS_SUCCESS";
export const FETCH_WALLET_TRANSACTIONS_FAILURE =
  "FETCH_WALLET_TRANSACTIONS_FAILURE";
export const WALLET_REQUEST_APPROVE_DECLINE_REQUEST =
  "WALLET_REQUEST_APPROVE_DECLINE_REQUEST";
export const WALLET_REQUEST_APPROVE_DECLINE_SUCCESS =
  "WALLET_REQUEST_APPROVE_DECLINE_SUCCESS";
export const WALLET_REQUEST_APPROVE_DECLINE_FAILURE =
  "WALLET_REQUEST_APPROVE_DECLINE_FAILURE";
export const WALLET_OPERATION_REQUEST = "WALLET_OPERATION_REQUEST";
export const WALLET_OPERATION_SUCCESS = "WALLET_OPERATION_SUCCESS";
export const WALLET_OPERATION_FAILURE = "WALLET_OPERATION_FAILURE";
export const FETCH_PENDING_USER_REQUESTS_REQUEST =
  "FETCH_PENDING_USER_REQUESTS_REQUEST";
export const FETCH_PENDING_USER_REQUESTS_SUCCESS =
  "FETCH_PENDING_USER_REQUESTS_SUCCESS";
export const FETCH_PENDING_USER_REQUESTS_FAILURE =
  "FETCH_PENDING_USER_REQUESTS_FAILURE";
export const FETCH_PENDING_GROUP_WALLET_REQUESTS_REQUEST =
  "FETCH_PENDING_GROUP_WALLET_REQUESTS_REQUEST";
export const FETCH_PENDING_GROUP_WALLET_REQUESTS_SUCCESS =
  "FETCH_PENDING_GROUP_WALLET_REQUESTS_SUCCESS";
export const FETCH_PENDING_GROUP_WALLET_REQUESTS_FAILURE =
  "FETCH_PENDING_GROUP_WALLET_REQUESTS_FAILURE";
export const FETCH_WALLET_REQUEST_COMPLETED_REQUEST =
  "FETCH_WALLET_REQUEST_COMPLETED_REQUEST";
export const FETCH_WALLET_REQUEST_COMPLETED_SUCCESS =
  "FETCH_WALLET_REQUEST_COMPLETED_SUCCESS";
export const FETCH_WALLET_REQUEST_COMPLETED_FAILURE =
  "FETCH_WALLET_REQUEST_COMPLETED_FAILURE";
export const FETCH_SINGLE_WALLET_REQUEST_REQUEST =
  "FETCH_SINGLE_WALLET_REQUEST_REQUEST";
export const FETCH_SINGLE_WALLET_REQUEST_SUCCESS =
  "FETCH_SINGLE_WALLET_REQUEST_SUCCESS";
export const FETCH_SINGLE_WALLET_REQUEST_FAILURE =
  "FETCH_SINGLE_WALLET_REQUEST_FAILURE";
export const CREATE_WALLET_REQUEST_REQUEST = "CREATE_WALLET_REQUEST_REQUEST";
export const CREATE_WALLET_REQUEST_SUCCESS = "CREATE_WALLET_REQUEST_SUCCESS";
export const CREATE_WALLET_REQUEST_FAILURE = "CREATE_WALLET_REQUEST_FAILURE";
