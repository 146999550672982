import {
  CREDIT_WALLET_REQUEST,
  CREDIT_WALLET_SUCCESS,
  CREDIT_WALLET_FAILURE,
  FETCH_ADMIN_WALLET_FAILURE,
  FETCH_ADMIN_WALLET_REQUEST,
  FETCH_ADMIN_WALLET_SUCCESS,
  FETCH_SINGLE_ADMIN_WALLET_TRANSACTION_FAILURE,
  FETCH_SINGLE_ADMIN_WALLET_TRANSACTION_REQUEST,
  FETCH_SINGLE_ADMIN_WALLET_TRANSACTION_SUCCESS,
  FETCH_ADMIN_WALLET_TRANSACTIONS_FAILURE,
  FETCH_ADMIN_WALLET_TRANSACTIONS_REQUEST,
  FETCH_ADMIN_WALLET_TRANSACTIONS_SUCCESS,
} from "../constants/adminWalletConstant";

const initialState = {
  loading: false,
  success: false,
  message: null,
  error: null,
  wallet: null,
  transaction: null,
  transactions: [],
};

export const creditWalletReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREDIT_WALLET_REQUEST:
      return { ...state, loading: true };

    case CREDIT_WALLET_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
        error: null,
      };

    case CREDIT_WALLET_FAILURE:
      return {
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const fetchAdminWalletReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMIN_WALLET_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_ADMIN_WALLET_SUCCESS:
      return { ...state, loading: false, wallet: action.payload };

    case FETCH_ADMIN_WALLET_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const fetchAdminWalletTransactionReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_SINGLE_ADMIN_WALLET_TRANSACTION_REQUEST:
      return { ...state, loading: true };
    case FETCH_SINGLE_ADMIN_WALLET_TRANSACTION_SUCCESS:
      return {
        loading: false,
        transaction: action.payload,
        error: null,
        success: true,
      };
    case FETCH_SINGLE_ADMIN_WALLET_TRANSACTION_FAILURE:
      return {
        loading: false,
        error: action.payload,
        transaction: null,
        success: false,
      };
    default:
      return state;
  }
};

export const fetchAdminWalletTransactionsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_ADMIN_WALLET_TRANSACTIONS_REQUEST:
      return { ...state, loading: true, error: null, success: false };
    case FETCH_ADMIN_WALLET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: action.payload,
        success: true,
      };
    case FETCH_ADMIN_WALLET_TRANSACTIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
