import React from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";

// import { reportActions } from "../../../../../../utils/static";
// import EmailReportSelector from "../../../pass/reports/components/EmailReportSelector";
import { GET_BILLER_CATEGORY_BY_ID } from "../../../../../../graphql/queries/payQueries";

const Topbar = () => {
  const location = useLocation();
  const reportType = location.pathname.split("/").pop(); // Get the report type from the URL

  const { data, loading, error } = useQuery(GET_BILLER_CATEGORY_BY_ID, {
    variables: { id: reportType },
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const categoryName = data.getBillerCategoryById.name;

  return (
    <div className="report-actions-container">
      <h3 className="report-title">{categoryName} Sales Report</h3>
      {/* <div className="report-actions-buttons">
        {reportActions.map((action, index) => (
          <button
            key={index}
            className="report-action-button"
            onClick={action.action}
          >
            <img src={action.icon} alt={action.title} className="report-icon" />
            {action.title}
          </button>
        ))}
        <EmailReportSelector />
      </div> */}
    </div>
  );
};

export default Topbar;
