import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, useField } from "formik";

const TextField = ({ label, passwordEye, togglePassword, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="form">
      <label className="login-label" htmlFor={field.name}>
        {label}
      </label>
      <div className="con">
        <input
          className={`${
            meta.touched && meta.error && "is-invalid"
          } formik-input`}
          {...field}
          {...props}
          autoComplete="off"
        />
        {passwordEye && (
          <span className="show" onClick={togglePassword}>
            <img src="/svgs/show.svg" alt="show" />
          </span>
        )}
      </div>
      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  );
};

TextField.propTypes = {
  label: PropTypes.any,
  passwordEye: PropTypes.bool,
  togglePassword: PropTypes.func,
};

export default TextField;
