export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";
export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";
export const FETCH_AVAILABLE_DRIVERS_REQUEST =
  "FETCH_AVAILABLE_DRIVERS_REQUEST";
export const FETCH_AVAILABLE_DRIVERS_SUCCESS =
  "FETCH_AVAILABLE_DRIVERS_SUCCESS";
export const FETCH_AVAILABLE_DRIVERS_FAILURE =
  "FETCH_AVAILABLE_DRIVERS_FAILURE";
export const ASSIGN_DRIVER_TO_ORDER_REQUEST = "ASSIGN_DRIVER_TO_ORDER_REQUEST";
export const ASSIGN_DRIVER_TO_ORDER_SUCCESS = "ASSIGN_DRIVER_TO_ORDER_SUCCESS";
export const ASSIGN_DRIVER_TO_ORDER_FAILURE = "ASSIGN_DRIVER_TO_ORDER_FAILURE";
export const CANCEL_ORDER_REQUEST = "CANCEL_ORDER_REQUEST";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAILURE = "CANCEL_ORDER_FAILURE";
