export const dashboardLinksArray = [
  {
    id: 1,
    icon: "../../../svgs/dashboard.svg",
    name: "Dashboard",
    path: "/dashboard/home", // Absolute path
    role: ["admin_only"],
  },
  {
    id: 2,
    icon: "../../../svgs/wallet.svg",
    name: "Wallets",
    path: "/dashboard/wallets", // Absolute path
    role: ["__wallet_read__"],
    subRoutes: [
      {
        id: 1,
        icon: "../../../svgs/transaction.svg",
        name: "Transactions",
        path: "/dashboard/wallet/transactions", // Absolute path
        role: ["__transaction_read__"],
      },
      {
        id: 2,
        icon: "../../../svgs/users.svg",
        name: "Users",
        path: "/dashboard/wallet/users", // Absolute path
        role: ["__users_read__"],
      },
      {
        id: 3,
        icon: "../../../svgs/groups.svg",
        name: "Groups",
        path: "/dashboard/wallet/groups", // Absolute path
        role: ["__groups_read__"],
      },
      {
        id: 4,
        icon: "../../../svgs/requests.svg",
        name: "Admin wallet",
        path: "/dashboard/wallet/admin-wallet", // Absolute path
        role: ["admin_only"],
      },
      {
        id: 5,
        icon: "../../../svgs/requests.svg",
        name: "Requests",
        path: "/dashboard/wallet/requests", // Absolute path
        role: ["__requests_read__"],
      },
      {
        id: 6,
        icon: "../../../svgs/reports.svg",
        name: "Reports",
        path: "/dashboard/wallet/reports", // Absolute path
        role: ["__reports_read__"],
      },
      {
        id: 7,
        icon: "../../../svgs/settings.svg",
        name: "Settings",
        path: "/dashboard/wallet/settings", // Absolute path
        role: ["__settings_read__"],
      },
    ],
  },

  {
    id: 3,
    icon: "../../../svgs/customer.svg",
    name: "Customers",
    path: "/dashboard/customers", // Absolute path
    role: ["__customer_read__"],
  },
  {
    id: 4,
    icon: "../../../svgs/dashboard.svg",
    name: "Banners",
    path: "/dashboard/banners", // Absolute path
    role: ["__banner_read__"],
  },
  {
    id: 5,
    icon: "../../../svgs/staff.svg",
    name: "Users/Permission",
    path: "/dashboard/users-permissions", // Absolute path
    role: ["__users_permission_read__"],
    subRoutes: [
      {
        id: 1,
        icon: "../../../svgs/user-list.svg",
        name: "Staff",
        path: "/dashboard/users-permissions", // Absolute path
        role: ["__staff_read__"],
      },
      {
        id: 2,
        icon: "../../../svgs/rider.svg",
        name: "Riders",
        path: "/dashboard/riders", // Absolute path
        role: ["__rider_read__"],
      },
    ],
  },
  {
    id: 6,
    icon: "../../../svgs/cash.svg",
    name: "Essentials",
    path: "/dashboard/essentials", // Absolute path
    role: ["__essentials_read__"],
    subRoutes: [
      {
        id: 1,
        icon: "../../../svgs/order.svg",
        name: "Orders",
        path: "/dashboard/orders", // Absolute path
        role: ["__order_read__"],
      },
      {
        id: 2,
        icon: "../../../svgs/category.svg",
        name: "Categories",
        path: "categories",
        role: [
          "__collection_create__",
          "__collection_read__",
          "__collection_update__",
          "__collection_delete__",
        ],
      },
      {
        id: 3,
        icon: "../../../svgs/products.svg",
        name: "Products",
        path: "products",
        role: [
          "__product_create__",
          "__product_read__",
          "__product_update__",
          "__product_delete__",
        ],
      },
      {
        id: 4,
        icon: "../../../svgs/location.svg",
        name: "Locations / Fees",
        path: "shipping", // Absolute path
        role: ["__location_fee_read__"],
      },
      {
        id: 5,
        icon: "../../../svgs/coupon.svg",
        name: "Coupons",
        path: "/dashboard/coupons", // Absolute path
        role: ["__coupon_read__"],
      },
      {
        id: 6,
        icon: "../../../svgs/reorder-list.svg",
        name: "Re-order Lists",
        path: "/dashboard/reorder-lists", // Absolute path
        role: ["__reorder_list_read__"],
      },
    ],
  },
  {
    id: 7,
    icon: "../../../svgs/cash.svg",
    name: "Pay",
    path: "/dashboard/pay", // Absolute path
    role: ["__pay_read__"],
    subRoutes: [
      {
        id: 1,
        icon: "../../../svgs/transaction.svg",
        name: "Transactions",
        path: "/dashboard/pay/transactions", // Absolute path
        role: ["__transaction_read__"],
      },
      {
        id: 2,
        icon: "../../../svgs/transaction.svg",
        name: "Reports",
        path: "/dashboard/pay/reports", // Absolute path
        role: ["__reports_read__"],
      },
      {
        id: 3,
        icon: "../../../svgs/transaction.svg",
        name: "Categories",
        path: "/dashboard/pay/categories", // Absolute path
        role: ["__reports_read__"],
      },
      {
        id: 4,
        icon: "../../../svgs/transaction.svg",
        name: "Commissions",
        path: "/dashboard/pay/commissions", // Absolute path
        role: ["__reports_read__"],
      },
      {
        id: 5,
        icon: "../../../svgs/transaction.svg",
        name: "Settings",
        path: "/dashboard/pay/settings", // Absolute path
        role: ["__reports_read__"],
      },
    ],
  },

  {
    id: 8,
    icon: "../../../svgs/cash.svg",
    name: "Pass",
    path: "/dashboard/pass", // Absolute path
    role: ["__pass_read__"],
    subRoutes: [
      {
        id: 1,
        icon: "../../../svgs/event.svg",
        name: "Events",
        path: "/dashboard/pass/events", // Absolute path
        role: ["__event_read__"],
      },
      {
        id: 2,
        icon: "../../../svgs/event.svg",
        name: "Events Categories",
        path: "/dashboard/pass/event-categories", // Absolute path
        role: ["__event_category_read__"],
      },
      {
        id: 3,
        icon: "../../../svgs/order.svg",
        name: "Orders",
        path: "/dashboard/pass/orders", // Absolute path
        role: ["__order_read__"],
      },
      {
        id: 4,
        icon: "../../../svgs/reports.svg",
        name: "Reports",
        path: "/dashboard/pass/reports", // Absolute path
        role: ["__reports_read__"],
      },
    ],
  },
  {
    id: 9,
    icon: "../../../svgs/collectibles.svg",
    name: "Collectibles",
    path: "/dashboard/collectibles", // Absolute path
    role: ["__collectibles_read__"],
  },
  {
    id: 10,
    icon: "../../../svgs/eye.svg",
    name: "Data Analytics",
    path: "/dashboard/data-analytics", // Absolute path
    role: ["admin_only"],
  },
  {
    id: 11,
    icon: "../../../svgs/setting.svg",
    name: "Settings",
    path: "/dashboard/settings", // Absolute path
    role: ["admin_only"],
  },
];

export const createEventSideBarArray = [
  {
    id: 1,
    name: "Create event page",
    path: "/dashboard/pass/events/create-event",
    completed: false,
  },
  {
    id: 2,
    name: "Add Tickets",
    path: "/dashboard/pass/events/create-event/:eventType/add-ticket", // Absolute path
    completed: false,
  },
  {
    id: 3,
    name: "Add Products [Optional]",
    path: "/dashboard/pass/events/create-event/:eventType/add-products", // Absolute path
    completed: false,
  },
  {
    id: 3,
    name: "Publish",
    path: "/dashboard/pass/events/create-event/:eventType/publish", // Absolute path
    completed: false,
  },
];

export const startTime = [
  "09:00 AM",
  "09:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "01:00 PM",
  "01:30 PM",
  "02:00 PM",
  "02:30 PM",
  "03:00 PM",
  "03:30 PM",
  "04:00 PM",
  "04:30 PM",
  "05:00 PM",
  "05:30 PM",
  "06:00 PM",
  "06:30 PM",
  "07:00 PM",
];
export const reportActions = [
  {
    title: "Export as Excel",
    icon: "../../../../../svgs/excel.svg",
    action: () => alert("Exporting as Excel"),
  },
  // {
  //   title: "Export as PDF",
  //   icon: "../../../../../svgs/pdf.svg",
  //   action: () => alert("Exporting as PDF"),
  // },
  // {
  //   title: "Export as CSV",
  //   icon: "../../../../../svgs/csv.svg",
  //   action: () => alert("Exporting as CSV"),
  // },
];
