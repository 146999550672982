export const FETCH_TRANSACTION_COMMISSION_REQUEST =
  "FETCH_TRANSACTION_COMMISSION_REQUEST";
export const FETCH_TRANSACTION_COMMISSION_SUCCESS =
  "FETCH_TRANSACTION_COMMISSION_SUCCESS";
export const FETCH_TRANSACTION_COMMISSION_FAILURE =
  "FETCH_TRANSACTION_COMMISSION_FAILURE";
export const FETCH_BILLER_CATEGORY_COMMISSIONS_REQUEST =
  "FETCH_BILLER_CATEGORY_COMMISSIONS_REQUEST";
export const FETCH_BILLER_CATEGORY_COMMISSIONS_SUCCESS =
  "FETCH_BILLER_CATEGORY_COMMISSIONS_SUCCESS";
export const FETCH_BILLER_CATEGORY_COMMISSIONS_FAILURE =
  "FETCH_BILLER_CATEGORY_COMMISSIONS_FAILURE";
export const FETCH_SINGLE_BILLER_COMMISSION_REQUEST =
  "FETCH_SINGLE_BILLER_COMMISSION_REQUEST";
export const FETCH_SINGLE_BILLER_COMMISSION_SUCCESS =
  "FETCH_SINGLE_BILLER_COMMISSION_SUCCESS";
export const FETCH_SINGLE_BILLER_COMMISSION_FAILURE =
  "FETCH_SINGLE_BILLER_COMMISSION_FAILURE";
export const UPDATE_SINGLE_BILLER_COMMISSION_REQUEST =
  "UPDATE_SINGLE_BILLER_COMMISSION_REQUEST";
export const UPDATE_SINGLE_BILLER_COMMISSION_SUCCESS =
  "UPDATE_SINGLE_BILLER_COMMISSION_SUCCESS";
export const UPDATE_SINGLE_BILLER_COMMISSION_FAILURE =
  "UPDATE_SINGLE_BILLER_COMMISSION_FAILURE";
export const CREATE_BILLER_CATEGORY_COMMISSION_REQUEST =
  "CREATE_BILLER_CATEGORY_COMMISSION_REQUEST";
export const CREATE_BILLER_CATEGORY_COMMISSION_SUCCESS =
  "CREATE_BILLER_CATEGORY_COMMISSION_SUCCESS";
export const CREATE_BILLER_CATEGORY_COMMISSION_FAILURE =
  "CREATE_BILLER_CATEGORY_COMMISSION_FAILURE";
export const DELETE_BILLER_CATEGORY_COMMISSION_REQUEST =
  "DELETE_COMMISSION_REQUEST";
export const DELETE_BILLER_CATEGORY_COMMISSION_SUCCESS =
  "DELETE_COMMISSION_SUCCESS";
export const DELETE_BILLER_CATEGORY_COMMISSION_FAILURE =
  "DELETE_COMMISSION_FAILURE";
export const CALCULATE_TRANSACTION_COMMISSION_REQUEST =
  "CALCULATE_TRANSACTION_COMMISSION_REQUEST";
export const CALCULATE_TRANSACTION_COMMISSION_SUCCESS =
  "CALCULATE_TRANSACTION_COMMISSION_SUCCESS";
export const CALCULATE_TRANSACTION_COMMISSION_FAILURE =
  "CALCULATE_TRANSACTION_COMMISSION_FAILURE";
export const GET_BILLER_CATEGORY_COMMISSION_REQUEST =
  "GET_BILLER_CATEGORY_COMMISSION_REQUEST";
export const GET_BILLER_CATEGORY_COMMISSION_SUCCESS =
  "GET_BILLER_CATEGORY_COMMISSION_SUCCESS";
export const GET_BILLER_CATEGORY_COMMISSION_FAILURE =
  "GET_BILLER_CATEGORY_COMMISSION_FAILURE";
